import { Dispatch } from 'redux';
import { usersAPI } from '@/api/users/usersApi';
import { UsersActionTypes } from '../constants/usersConstants';
import { Users } from '@/api/users/usersTypes';
import { showSnackbar } from './snackbarActions';
import { SnakeTypes } from '@/ui/components/modals/SnakeBar';

const setUsersData = (payload: Users) => ({
    type: UsersActionTypes.GET_USERS_ACTION,
    payload,
});

export const getUsers = () => async (dispatch: Dispatch<any>) => {
    try {
        const response = await usersAPI.getUsers();
        dispatch(setUsersData(response));
    } catch (_e) {
        const e = _e as Error;
    }
};

export const banUser = (uid: string) => async (dispatch: Dispatch<any>) => {
    try {
        await usersAPI.banUser(uid);
        dispatch(showSnackbar({ type: SnakeTypes.success, content: 'Users were banned', ms: 4000 }));

        dispatch(getUsers());
    } catch (_e) {
        const e = _e as Error;
        dispatch(showSnackbar({ type: SnakeTypes.error, content: e.message, ms: 4000 }));
    }
};

export const unbanUser = (uid: string) => async (dispatch: Dispatch<any>) => {
    try {
        await usersAPI.unbanUser(uid);
        dispatch(showSnackbar({ type: SnakeTypes.success, content: 'Users were unbanned', ms: 4000 }));
        dispatch(getUsers());
    } catch (_e) {
        const e = _e as Error;
        dispatch(showSnackbar({ type: SnakeTypes.error, content: e.message, ms: 4000 }));
    }
};

export const changeRole = (uid: string, role: string) => async (dispatch: Dispatch<any>) => {
    try {
        await usersAPI.changeRole(uid, role);
        dispatch(showSnackbar({ type: SnakeTypes.success, content: 'Role changed', ms: 4000 }));
        dispatch(getUsers());
    } catch (_e) {
        const e = _e as Error;
        dispatch(showSnackbar({ type: SnakeTypes.error, content: e.message, ms: 4000 }));
    }
};
