import React, { useState } from 'react';
import NotStonksIMG from '@assets/svgs/product_notStonks_icon.svg';
import StonksIMG from '@assets/svgs/product_stonks_icon.svg';
import styled from 'styled-components';
import { useTypedSelector } from '@hooks/useSelector';
import { roundToTwoDecimals } from '@/helpers/round';

const MetalPriceBlock = () => {
    const { metalPrices } = useTypedSelector((state) => state.productReducer);

    const [currentMoneyType, setCurrentMoneyType] = useState('$');

    return (
        <MetalPriceContent>
            <MetalBlock>
                <MetalTitle>Silver</MetalTitle>
                <PriceBlock>
                    {metalPrices.find((item) => item.name.toLocaleLowerCase() === 'silver')?.priceChange === 'up' ? (
                        <img src={StonksIMG} alt={'Stonks'} />
                    ) : (
                        <img src={NotStonksIMG} alt={'Not stonks'} />
                    )}
                    <Price>
                        {currentMoneyType + roundToTwoDecimals(metalPrices.find((item) => item.name.toLocaleLowerCase() === 'silver')?.price)}
                    </Price>
                </PriceBlock>
            </MetalBlock>
            <MetalBlock>
                <MetalTitle>Gold</MetalTitle>
                <PriceBlock>
                    {metalPrices.find((item) => item.name.toLocaleLowerCase() === 'gold')?.priceChange === 'up' ? (
                        <img src={StonksIMG} alt={'Stonks'} />
                    ) : (
                        <img src={NotStonksIMG} alt={'Not stonks'} />
                    )}
                    <Price>
                        {currentMoneyType + roundToTwoDecimals(metalPrices.find((item) => item.name.toLocaleLowerCase() === 'gold')?.price)}
                    </Price>
                </PriceBlock>
            </MetalBlock>
            <MetalBlock>
                <MetalTitle>Platinum</MetalTitle>
                <PriceBlock>
                    {metalPrices.find((item) => item.name.toLocaleLowerCase() === 'platinum')?.priceChange === 'up' ? (
                        <img src={StonksIMG} alt={'Stonks'} />
                    ) : (
                        <img src={NotStonksIMG} alt={'Not stonks'} />
                    )}
                    <Price>
                        {currentMoneyType + roundToTwoDecimals(metalPrices.find((item) => item.name.toLocaleLowerCase() === 'platinum')?.price)}
                    </Price>
                </PriceBlock>
            </MetalBlock>
            <MetalBlock>
                <MetalTitle>Palladium</MetalTitle>
                <PriceBlock>
                    {metalPrices.find((item) => item.name.toLocaleLowerCase() === 'palladium')?.priceChange === 'up' ? (
                        <img src={StonksIMG} alt={'Stonks'} />
                    ) : (
                        <img src={NotStonksIMG} alt={'Not stonks'} />
                    )}
                    <Price>
                        {currentMoneyType + roundToTwoDecimals(metalPrices.find((item) => item.name.toLocaleLowerCase() === 'palladium')?.price)}
                    </Price>
                </PriceBlock>
            </MetalBlock>

            <MetalBlock>
                <MetalTitle>Copper</MetalTitle>
                <PriceBlock>
                    {metalPrices.find((item) => item.name.toLocaleLowerCase() === 'copper')?.priceChange === 'up' ? (
                        <img src={StonksIMG} alt={'Stonks'} />
                    ) : (
                        <img src={NotStonksIMG} alt={'Not stonks'} />
                    )}
                    <Price>
                        {currentMoneyType + roundToTwoDecimals(metalPrices.find((item) => item.name.toLocaleLowerCase() === 'copper')?.price)}
                    </Price>
                </PriceBlock>
            </MetalBlock>
        </MetalPriceContent>
    );
};

const Price = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

const PriceBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const MetalTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const MetalBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const MetalPriceContent = styled.div`
    background: #ffffff;
    border: 1px solid #e7e7ed;
    box-sizing: border-box;
    border-radius: 4px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 40px;
    padding-left: 40px;
    align-items: center;
`;

export default MetalPriceBlock;
