import { Dispatch } from 'redux';
import { ordersAPI } from '@/api/orders/ordersApi';
import { Orders } from '@/api/orders/ordersTypes';
import { OrdersActionTypes } from '../constants/ordersContants';

const setOrdersData = (payload: Orders) => ({
    type: OrdersActionTypes.GET_ORDERS_ACTION,
    payload,
});

export const getOrders = () => async (dispatch: Dispatch<any>) => {
    try {
        const response = await ordersAPI.getOrders();
        dispatch(setOrdersData(response));
    } catch (_e) {
        const e = _e as Error;
        // dispatch(errorUserAction(e.message));
    }
};
