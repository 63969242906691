import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import StyledButton from '@ui/StyledButton';
import PlusIMG from '@assets/svgs/Plus.svg';
import DeleteIMG from '@assets/svgs/product_delete_img.svg';
import EditIMG from '@assets/svgs/product_edit_img.svg';
import CheckboxComponent from '@ui/CheckboxComponent';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@hooks/useSelector';
import { useProductActions } from '@hooks/useActions';
import OptionsIMG from '@assets/svgs/admin_options_img.svg';
import { useClickAway } from '@hooks/useClickAway';
import { IResponseProduct } from '@api/product/productType';
import SearchInput from '@ui/SearchInput';
import { getFromStorage, saveToStorage } from '@/helpers/saveToLocaleStorage';
import { roundToTwoDecimals } from '@/helpers/round';
import { useDispatch } from 'react-redux';
import { getAllProducts, getMetalPrices } from '@/store/actions/productActions';
import useSocketDispatchUpdatePrice from '@/hooks/useSocketDispatch';

interface IProductTable {
    page: number;
    pageLimit: number;
}

interface ISaveSortData {
    label: string;
    category: string;
    sortDirection: boolean;
}

interface IField {
    label: string;
    getter: (item: any) => any;
}

const mainFields = [
    { label: 'Name', getter: (item: any) => item.name },
    { label: 'Metal', getter: (item: any) => item.metal?.name },
    {
        label: 'Catalog',
        getter: (item: any) => `${[Boolean(item.live) && 'Live Price List', Boolean(item.shop) && 'Shop'].filter((it) => it).join(',')}`,
    },
];

const lplFields = [
    { label: 'Buy', getter: (item: any) => roundToTwoDecimals(item.live?.buy_price) },
    { label: 'Sell', getter: (item: any) => roundToTwoDecimals(item.live?.sell_price) },
    { label: 'Category', getter: (item: any) => item.live?.category?.name },
    { label: 'Visible', getter: (item: any) => item.live?.is_visible },
];

const shopFields = [
    { label: 'Price', getter: (item: any) => roundToTwoDecimals(item.shop?.price) },
    { label: 'Quantity', getter: (item: any) => item.shop?.amount_all },
    { label: 'Category ', getter: (item: any) => item.shop?.category?.name },
    { label: 'Visible', getter: (item: any) => item.shop?.is_visible },
];

const ProductTable: React.FC<IProductTable> = ({ page, pageLimit }) => {
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const dispatch = useSocketDispatchUpdatePrice();

    useClickAway(wrapperRef, () => setSelectedRow(-1), triggerRef);

    const { productsData } = useTypedSelector((state) => state.productReducer);
    const [sortedProductsData, setSortedProductsData] = useState<IResponseProduct[]>([]);
    const { getMetals, deleteProduct, updateVisible } = useProductActions();

    const [selectedRow, setSelectedRow] = useState(-1);
    const [currentMoneyType, setCurrentMoneyType] = useState('$');

    const [selectedField, setSelectedField] = useState<any>(mainFields[0]);
    const [sortDir, setSortDir] = useState<any>(true);

    const onDeleteHandler = (id: string) => {
        deleteProduct(id);
    };

    const onEditHandler = (id: string) => {
        navigate(`/products/update/${id}`);
    };

    useEffect(() => {
        getMetals();
    }, []);

    const updateVisibleProduct = (data: IResponseProduct, type: 'live' | 'shop') => {
        if (data.isCollectable && type === 'live') return;
        updateVisible(data, type);
        dispatch({
            getMetalPrice: () => getMetalPrices(),
            geCurrentProducts: () => getAllProducts({ page, pageLimit }),
        });
    };

    const handleSort = (field: (typeof mainFields)[0], categoryPart: string) => {
        if (selectedField) setSortDir((prev: boolean) => !prev);
        setSelectedField(field);
        const saveData: ISaveSortData = {
            label: field.label,
            category: categoryPart,
            sortDirection: sortDir,
        };
        saveToStorage('sortField', JSON.stringify(saveData));
    };

    const sortFieldName = getFromStorage('sortField');

    useEffect(() => {
        if (sortFieldName) {
            const sortData = JSON.parse(sortFieldName) as ISaveSortData;
            let field: IField | undefined;
            switch (sortData.category) {
                case 'Main':
                    field = mainFields.find((item) => item.label === sortData.label);
                    break;
                case 'Live':
                    field = lplFields.find((item) => item.label === sortData.label);
                    break;
                case 'Shop':
                    field = shopFields.find((item) => item.label === sortData.label);
                    break;
                default:
            }
            if (field) {
                setSortDir(!sortData.sortDirection);
                setSelectedField(field);
            }
        }
    }, [sortFieldName]);



    useEffect(() => {
        const sortedData = productsData;
        if (selectedField) {
            sortedData.sort((a, b) => {
                let a1 = !sortDir ? selectedField.getter(a) : selectedField.getter(b);
                let b1 = !sortDir ? selectedField.getter(b) : selectedField.getter(a);
                if (typeof a1 === 'string') {
                    a1 = a1.toLowerCase();
                }
                if (typeof b1 === 'string') {
                    b1 = b1.toLowerCase();
                }
                if (!a1) {
                    a1 = '';
                }
                if (!b1) {
                    b1 = '';
                }
                //@ts-ignore
                if (a1 < b1) return -1;
                else if (a1 > b1) return 1;
                return 0;
            });
        }
        setSortedProductsData(sortedData);
    }, [productsData]);

    // const sortedData = productsData;
    // if (selectedField) {
    //     sortedData.sort((a, b) => {
    //         let a1 = !sortDir ? selectedField.getter(a) : selectedField.getter(b);
    //         let b1 = !sortDir ? selectedField.getter(b) : selectedField.getter(a);
    //         if (typeof a1 === 'string') {
    //             a1 = a1.toLowerCase();
    //         }
    //         if (typeof b1 === 'string') {
    //             b1 = b1.toLowerCase();
    //         }
    //         if (!a1) {
    //             a1 = '';
    //         }
    //         if (!b1) {
    //             b1 = '';
    //         }
    //         //@ts-ignore
    //         if (a1 < b1) return -1;
    //         else if (a1 > b1) return 1;
    //         return 0;
    //     });
    // }

    return (
        <>
            <TableSettings>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                    }}
                >
                    <ButtonContainer>
                        <StyledButton
                            onClick={() => {
                                navigate('/products/create-new');
                            }}
                            backgroundColor={'#5B73CF'}
                            color={'white'}
                            height={'43px'}
                        >
                            <img src={PlusIMG} alt={'plus'} /> Add new product
                        </StyledButton>
                    </ButtonContainer>
                    <SearchInput isModalElement={false} />
                </div>
                {/*                <select>
                    <option>1</option>
                    <option>2</option>
                </select>*/}
            </TableSettings>
            <Table>
                <thead>
                    <NameRow style={{ borderTop: '1px solid #E7E7ED' }}>
                        <Main>Main</Main>
                        <LivePrice>Live Price</LivePrice>
                        <Shop>Shop</Shop>
                    </NameRow>
                </thead>
                <tbody>
                    <NameRow>
                        <Main>
                            <BlockInfo>
                                {mainFields.map((f, c) =>
                                    c === 0 ? (
                                        <NamePart key={String(Math.random())} style={{ cursor: 'pointer' }} onClick={() => handleSort(f, 'Main')}>
                                            {f.label}
                                            {f.label === selectedField?.label ? (
                                                sortDir ? (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8593;</span>
                                                ) : (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8595;</span>
                                                )
                                            ) : null}
                                        </NamePart>
                                    ) : (
                                        <LabelBlock
                                            key={String(Math.random())}
                                            onClick={() => handleSort(f, 'Main')}
                                            selected={f.label === selectedField?.label}
                                        >
                                            {f.label}
                                            {f.label === selectedField?.label ? (
                                                sortDir ? (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8593;</span>
                                                ) : (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8595;</span>
                                                )
                                            ) : null}
                                        </LabelBlock>
                                    )
                                )}
                            </BlockInfo>
                        </Main>
                        <LivePrice>
                            <BlockInfoForElem>
                                {lplFields.map((f) =>
                                    f.label === 'Visible' ? (
                                        <div key={String(Math.random())}>Visible</div>
                                    ) : (
                                        <LabelBlock
                                            key={String(Math.random())}
                                            onClick={() => handleSort(f, 'Live')}
                                            selected={f.label === selectedField?.label}
                                        >
                                            {f.label}
                                            {f.label === selectedField?.label ? (
                                                sortDir ? (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8593;</span>
                                                ) : (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8595;</span>
                                                )
                                            ) : null}
                                        </LabelBlock>
                                    )
                                )}
                            </BlockInfoForElem>
                        </LivePrice>
                        <Shop>
                            <BlockInfoForElem>
                                {shopFields.map((f) =>
                                    f.label === 'Visible' ? (
                                        <div>Visible</div>
                                    ) : (
                                        <LabelBlock onClick={() => handleSort(f, 'Shop')} selected={f.label === selectedField?.label}>
                                            {f.label}
                                            {f.label === selectedField?.label ? (
                                                sortDir ? (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8593;</span>
                                                ) : (
                                                    <span style={{ fontSize: 20, margin: '0 3px' }}>&#8595;</span>
                                                )
                                            ) : null}
                                        </LabelBlock>
                                    )
                                )}
                            </BlockInfoForElem>
                        </Shop>
                    </NameRow>
                    {sortedProductsData &&
                        sortedProductsData.map((item, index) => {
                            return (
                                <NameRow
                                    key={`nameRow${index}`}
                                    isChosen={index === selectedRow}
                                    onClick={() => {
                                        if (index === selectedRow) {
                                            setSelectedRow(-1);
                                        }
                                    }}
                                >
                                    <Main>
                                        <BlockInfoField>
                                            <NamePart>{item?.name}</NamePart>
                                            <LabelBlockField>{item.metal?.name.replace(/^\w/, (c) => c.toUpperCase())}</LabelBlockField>
                                            <LabelBlockField>
                                                {item.live?.is_visible && item.shop?.is_visible
                                                    ? `Live Price List, Shop`
                                                    : item.live?.is_visible
                                                    ? `Live Price List`
                                                    : item.shop?.is_visible
                                                    ? `Shop`
                                                    : ''}
                                            </LabelBlockField>
                                        </BlockInfoField>
                                    </Main>
                                    <LivePrice>
                                        <BlockInfoForElemField>
                                            <LabelBlockField>
                                                {item.live?.buy_price ? currentMoneyType + roundToTwoDecimals(item.live.buy_price) : null}
                                            </LabelBlockField>
                                            <LabelBlockField>
                                                {item.live?.sell_price ? currentMoneyType + roundToTwoDecimals(item.live?.sell_price) : null}
                                            </LabelBlockField>
                                            <LabelBlockField>{item.live?.category?.name}</LabelBlockField>
                                            <CheckBoxContainer>
                                                <CheckboxComponent
                                                    value={item.live?.is_visible}
                                                    disabled
                                                    onClick={() => {
                                                        if (item.live) {
                                                            updateVisibleProduct(item, 'live');
                                                        }
                                                    }}
                                                    width={'16px'}
                                                    height={'16px'}
                                                />
                                            </CheckBoxContainer>
                                        </BlockInfoForElemField>
                                    </LivePrice>
                                    <Shop>
                                        <BlockInfoForElemField>
                                            <LabelBlockField>
                                                {item.shop?.price ? currentMoneyType + roundToTwoDecimals(item.shop.price) : null}
                                            </LabelBlockField>
                                            <LabelBlockField>{item.shop?.amount_all}</LabelBlockField>
                                            <LabelBlockField>{item.shop?.category?.name}</LabelBlockField>
                                            <CheckBoxContainer>
                                                <CheckboxComponent
                                                    value={item.shop?.is_visible}
                                                    onClick={() => {
                                                        if (item.shop) {
                                                            updateVisibleProduct(item, 'shop');
                                                        }
                                                    }}
                                                    disabled
                                                    width={'16px'}
                                                    height={'16px'}
                                                />
                                            </CheckBoxContainer>
                                        </BlockInfoForElemField>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                right: 30,
                                            }}
                                            onClick={(e) => {
                                                if (index === selectedRow) {
                                                    setSelectedRow(-1);
                                                } else {
                                                    setSelectedRow(index);
                                                }

                                                // @ts-ignore
                                                triggerRef.current = e.target;
                                            }}
                                        >
                                            <img style={{ cursor: 'pointer' }} src={OptionsIMG} alt={'options'} />
                                        </div>
                                        {selectedRow === index && (
                                            <div
                                                ref={wrapperRef}
                                                style={{
                                                    position: 'absolute',
                                                    right: 200,
                                                }}
                                            >
                                                <ActionsBlock>
                                                    <img
                                                        onClick={() => onEditHandler(item.id)}
                                                        style={{ cursor: 'pointer' }}
                                                        draggable={false}
                                                        src={EditIMG}
                                                        alt={'edit'}
                                                    />
                                                    <img
                                                        onClick={() => onDeleteHandler(item.id)}
                                                        style={{ cursor: 'pointer' }}
                                                        draggable={false}
                                                        src={DeleteIMG}
                                                        alt={'delete'}
                                                    />
                                                </ActionsBlock>
                                            </div>
                                        )}
                                    </Shop>
                                </NameRow>
                            );
                        })}
                </tbody>
            </Table>
        </>
    );
};

const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /*  padding-right: 25px;*/
`;

const ActionsBlock = styled.div`
    position: absolute;
    margin-top: 5px;
    left: 60px;
    width: 122px;
    height: 52px;
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
`;

const LabelBlock = styled.div<{ selected?: boolean }>`
    //max-width: 176px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    //width: 100px;
`;
const LabelBlockField = styled.div`
    //max-width: 176px;
    width: 100%;
    text-align: left;
    font-weight: 400;
    //width: 100px;
`;

const NamePart = styled.p`
    padding-left: 5px;
    //display: flex;
    flex-direction: row;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    margin-right: 10px;
    //flex-wrap: wrap;
    max-height: 80px;
    //width: 80%;
    //height: 100%;
    //max-lines: 3;
    //box-sizing: content-box;
    //max-width: 176px;
    //width: 100%;
    //gap: 10px
`;
const BlockInfo = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
`;

const BlockInfoForElem = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
`;

const BlockInfoField = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr;
`;

const BlockInfoForElemField = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
`;

const Shop = styled.th`
    //max-width: 403px;
    width: 100%;
    height: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /*  border-bottom: 1px solid #E7E7ED;*/
`;

const LivePrice = styled.th`
    max-width: 450px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    /*  border-bottom: 1px solid #E7E7ED;*/
    border-right: 1px solid #e7e7ed;
`;

const Main = styled.th`
    padding: 0;
    max-width: 500px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*  border-bottom: 1px solid #E7E7ED;*/
    border-right: 1px solid #e7e7ed;
`;

interface IChosenLine {
    isChosen?: boolean;
}

const NameRow = styled.tr<IChosenLine>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80px;
    width: 100%;
    background-color: ${(props) => (!!props.isChosen ? '#EBEFFF' : 'white')};
    /*  border-top: 1px solid #E7E7ED;*/
    border-left: 1px solid #e7e7ed;
    border-right: 1px solid #e7e7ed;
    border-bottom: 1px solid #e7e7ed;
    /*  justify-content: space-between;*/
`;

const Table = styled.table`
    /*  border: 1px solid #E7E7ED;*/
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;

const ButtonContainer = styled.div`
    width: 205px;
`;

const TableSettings = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
`;

export default ProductTable;
