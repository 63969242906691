import {snackbarAction} from "@store/types/snackbarTypes";
import {SnackbarActionTypes} from "@store/constants/snackbarConstants";
import {SnakeTypes} from "@ui/components/modals/SnakeBar";


interface ISnackbarState {
    type: SnakeTypes | undefined
    content: string | undefined
    ms: number
}

const initialState: ISnackbarState = {
    type: undefined,
    content: undefined,
    ms: 2000
}

const snackbarReducer = (state = initialState, action: snackbarAction): ISnackbarState => {
    switch (action.type) {
        case SnackbarActionTypes.SET_SNACKBAR: 
            return { ...state, ...action.payload }

        default:
            return state;
    }
}


export default snackbarReducer;
