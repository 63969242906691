import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react';
import TextFields from "@ui/TextFields";
import deleteICON from "@assets/svgs/textfield_delete_icon.svg";
import StyledButton from "@ui/StyledButton";
import styled from "styled-components";
import {useMediaActions} from "@hooks/useActions";
import {useTypedSelector} from "@hooks/useSelector";
import UploadImage from '@assets/svgs/upload_image_icon.svg'
import DeleteItemIMG from "@assets/svgs/createProduct_deleteImg_icon.svg";

interface IFieldProps {
    fieldName: string
    data: string
    url: string | null
    userRole: string | undefined
    onDeleteHandler: (value: string) => void
    onSaveHandler: (fieldName: string, text: string, url?: string | null) => void
}

interface ILinkField {
    title?: string
    url?: string
}

const Index: React.FC<IFieldProps> = ({fieldName, data, url, onSaveHandler, onDeleteHandler, userRole}) => {
    const {imageOriginLink, loading} = useTypedSelector(state => state.mediaReducer)
    const {uploadImage} = useMediaActions()

    const [isChanged, setIsChanged] = useState(false)
    const [linkField, setLinkField] = useState<ILinkField>({
        title: data ? data : '',
        url: url ? url : ''
    })

    const hiddenInput = useRef(null);

    useEffect(() => {
        if (imageOriginLink) {
            setLinkField((item) => ({...item, url: imageOriginLink}))
        }
    }, [imageOriginLink])

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChanged(true)
        const files = event.target.files
        if (files) {
            //setSelectedFile(files[0])
            uploadImage(files[0])
        }
    }

    const handleClickUploadButton = () => {
        // @ts-ignore
        hiddenInput.current?.click()
        setIsChanged(true)
    }

    const deleteImg = () => {
        setIsChanged(true)
        setLinkField({...linkField, url: undefined})
    }

    return (
        <div>
            <ImgFields>
                <TextFieldLinkTitleContainer>
                    <TextFields onChange={(e) => {
                        setLinkField({...linkField, title: e.target.value})
                        setIsChanged(true)
                    }} value={linkField.title} placeholder={fieldName} type={'text'} height={'46px'}/>
                </TextFieldLinkTitleContainer>
                <ButtonContainer>
                    <StyledButton height={"49px"} onClick={handleClickUploadButton}>
                        <img src={UploadImage} alt={'uploadIcon'}/>
                        Upload file
                    </StyledButton>
                </ButtonContainer>
                <CustomInput accept={".jpg,.png,.jpeg"} ref={hiddenInput} disabled={loading} name="file" type="file"
                             multiple={false} onChange={handleFileSelect}/>
                {
                    userRole === 'super-admin' && <DeleteICON onClick={() => {
                        onDeleteHandler(fieldName)
                    }}>
                        <DelIcon width={26} height={26} src={deleteICON} alt="deleteIcon"/>
                    </DeleteICON>
                }

                {
                    isChanged && <StyledButton
                        onClick={() => {
                            setIsChanged(false)
                            onSaveHandler(fieldName, linkField.title ? linkField.title : '',
                                linkField.url?.length === 0 ? null : linkField.url)
                        }}
                        height={'46px'}
                        width={'100px'}>
                        Save
                    </StyledButton>
                }
            </ImgFields>
            {
                Number(linkField.url?.length) > 0 &&
                <div>
                    <img
                        style={{
                            position: "absolute",
                            marginLeft: 180,
                            cursor: "pointer",
                        }}
                        draggable={false}
                        src={DeleteItemIMG}
                        alt={"delete"}
                        onClick={() => deleteImg()}
                    />
                    <img
                        width={200}
                        height={200}
                        src={linkField.url}
                        alt={'currentPicture'}
                        style={{border: "1px solid rgb(160,160,160)"}}
                    />
                </div>

            }
        </div>
    )
};

const ButtonContainer = styled.div`
  width: 180px;
`

const CustomInput = styled.input`
  display: none;
`

const ImgFields = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`

const DelIcon = styled.img`
  padding: 5px;
  cursor: pointer;

  &:hover {
    border-radius: 30px;
    background-color: rgba(0.2, 0.2, 0.2, 0.1);
    transition: background 0.3s;
  }

  &:active {
    width: 34px;
    height: 34px;
  }
`

const DeleteICON = styled.div`
  display: flex;
  align-items: center;
`

const TextFieldLinkContainer = styled.div`
  width: 200px;
`
const TextFieldLinkTitleContainer = styled.div`
  width: 400px;
`

export default Index;