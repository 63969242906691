import api from '../index';
import { ICreateFieldData, ICreatePage, IResponseMessage, IResponsePage, IContent, IUpdateTextField } from '@api/admin/adminType';

export const adminAPI = {
    async getPages() {
        return (await api.get<IResponsePage[]>('/page')).data;
    },
    async createPage(data: ICreatePage) {
        return (await api.post<IResponsePage>('/page', data)).data;
    },
    async deletePage(name: string) {
        return (await api.delete<IResponseMessage>(`/page/${name}`)).data;
    },
    async addTextField(data: ICreateFieldData) {
        return (await api.post<IResponsePage>(`/page/${data.name}/field`, { title: data.title, type: data.type })).data;
    },
    async deleteTextField(data: ICreateFieldData) {
        return (await api.delete<IResponsePage>(`/page/${data.name}/field/${data.title || ' '}`)).data;
    },
    async updateTextField(data: IUpdateTextField) {
        return (
            await api.patch<IResponsePage>(`/page/${data.name}/field/${data.title}`, {
                data: data.data,
                url: data.url,
                is_disabled: data.is_disabled,
            })
        ).data;
    },
    async createQbItemForAllProducts() {
        return (await api.post('/admin/create-qb-item-for-all-products')).data;
    },
};
