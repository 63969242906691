import React, { useState } from 'react';
import styled from 'styled-components';

interface TooltipProps {
    text: string;
    children: React.ReactNode;
}

const Tooltip = (props: TooltipProps) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <TooltipWrapper onMouseMove={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            {props.children}
            <TooltipText isHover={isHover}>{props.text}</TooltipText>
        </TooltipWrapper>
    );
};

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
`;

const TooltipText = styled.div<{ isHover: boolean }>`
    visibility: ${(props) => (props.isHover ? 'visible' : 'hidden')};
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
`;

export default Tooltip;
