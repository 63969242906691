import {productActions} from "@store/types/productTypes";
import {ProductsActionTypes} from "@store/constants/productConstants";
import {ICategory, IErrors, IProduct} from "@/types";
import {ICategoryData, IMetal, IMetalPrices, IResponseCategory, IResponseProduct} from "@api/product/productType";

interface IProductState {
    currentProduct: IResponseProduct | undefined
    metals: IMetal[]
    metalPrices: IMetalPrices[]
    categoriesData: IResponseCategory[]
    productsData: IResponseProduct[]
    totalItems: number
    pageLimit: number
    errors: IErrors[]
    loading: boolean
}

const initialState: IProductState = {
    currentProduct: undefined,
    metals: [],
    metalPrices: [],
    categoriesData: [],
    productsData: [],
    totalItems: -1,
    pageLimit: -1,
    errors: [],
    loading: false,
}

const startLoading = (state: IProductState) => {
    return {...state, loading: true, error: undefined}
}

const stopErrorLoading = (state: IProductState, error: IErrors[]) => {
    return {...state, loading: false, error}
}

const productReducer = (state = initialState, action: productActions): IProductState => {
    switch (action.type) {
        case ProductsActionTypes.START_PRODUCT_ACTION:
            return startLoading(state)
        case ProductsActionTypes.ERROR_PRODUCT_ACTION:
            return stopErrorLoading(state, action.payload)

        case ProductsActionTypes.GET_METALS:
            return {
                ...state, metals: action.payload, loading: false
            }
        case ProductsActionTypes.GET_METAL_PRICES:
            return {
                ...state, metalPrices: action.payload, loading: false
            }

        case ProductsActionTypes.GET_PRODUCT_CATEGORIES:
            return {
                ...state,
                categoriesData: action.payload.data,
                totalItems: action.payload.total_items,
                pageLimit: action.payload.page_limit,
                loading: false
            }

        case ProductsActionTypes.GET_PRODUCT_CATEGORIES_WITH_OUT_PAGINATION:
            return {
                ...state,
                categoriesData: action.payload,
                loading: false
            }


        case ProductsActionTypes.UPDATE_PRODUCT_CATEGORY:
            let currentCategory = state.categoriesData.find(item => item.id === action.payload.id)
            if (!currentCategory) return {...state, loading: false}
            currentCategory.name = action.payload.name
            currentCategory.metal_id = action.payload.metal_id
            return {
                ...state,
                categoriesData: [...state.categoriesData]
                , loading: false
            }
        case ProductsActionTypes.CREATE_PRODUCT_CATEGORY:
            return {
                ...state, categoriesData: [...state.categoriesData, action.payload], loading: false
            }
        case ProductsActionTypes.DELETE_PRODUCT_CATEGORY:
            return {
                ...state,
                categoriesData: state.categoriesData.filter(item => item.id !== action.payload.id),
                loading: false
            }
        case ProductsActionTypes.GET_ALL_PRODUCTS:
            return {
                ...state,
                productsData: action.payload.data,
                totalItems: action.payload.total_items,
                pageLimit: action.payload.page_limit,
                loading: false
            }
        case ProductsActionTypes.GET_PRODUCT:
            return {
                ...state,
                currentProduct: action.payload,
                loading: false
            }
        case ProductsActionTypes.DELETE_PRODUCT:
            return {
                ...state,
                productsData: [...state.productsData.filter(item => item.id !== action.payload.id)],
                loading: false
            }
        case ProductsActionTypes.UPDATE_PRODUCT:
            let currentProduct = state.productsData.find(item => item.id === action.payload.id)
            if (!currentProduct) {
                return {
                    ...state,
                    currentProduct: action.payload,
                    loading: false
                }
            }
            Object.keys(currentProduct).forEach((item) => {
                if (!!currentProduct) {
                    // @ts-ignore
                    currentProduct[item as keyof IResponseProduct] = action.payload[item as keyof IResponseProduct]
                }
            })
            return {
                ...state,
                productsData: [...state.productsData],
                currentProduct: action.payload,
                loading: false
            }
        case ProductsActionTypes.CRETE_PRODUCT:
            return {
                ...state,
                productsData: [...state.productsData, action.payload],
                currentProduct: action.payload,
                loading: false
            }

        case ProductsActionTypes.CLEAR_CURRENT_PRODUCT:
            return {
                ...state,
                currentProduct: undefined
            }
        default:
            return state
    }
}

export default productReducer