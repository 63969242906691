import { snackbarAction, IShowSnackbar, ISnackbarData } from '@store/types/snackbarTypes';
import { SnackbarActionTypes } from '@store/constants/snackbarConstants';
import {Dispatch} from "redux";


const setSnackbar = (payload: ISnackbarData): IShowSnackbar => ({
    type: SnackbarActionTypes.SET_SNACKBAR,
    payload
})


export const showSnackbar = (data: ISnackbarData) =>
    async (dispatch: Dispatch<snackbarAction>) => {
        dispatch(setSnackbar(data))
    }

export const hideSnackbar = () =>
    async (dispatch: Dispatch<snackbarAction>) => {
        dispatch(setSnackbar({
            type: undefined,
            content: undefined
        }))
    }