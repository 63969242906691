import { IErrors } from '@/types';
import { userAction } from '../types/userTypes';
import { IUserData } from '@api/user/userTypes';
import { UserActionTypes } from '@store/constants/userConstants';
import { MultiFactorError } from 'firebase/auth';

interface IUserState {
    isInitialize: boolean;
    user: IUserData | undefined;
    error: IErrors[] | string | undefined;
    loading: boolean;
    message: string | undefined;
    isSecondStepLogIn: boolean;
    multiFactorError: MultiFactorError | undefined;
}

const initialState: IUserState = {
    isInitialize: false,
    error: undefined,
    loading: false,
    user: undefined,
    message: undefined,
    isSecondStepLogIn: false,
    multiFactorError: undefined,
};

const startLoading = (state: IUserState) => {
    return { ...state, loading: true, error: undefined };
};

const stopErrorLoading = (state: IUserState, error: string) => {
    return { ...state, loading: false, error: error };
};

const userReducer = (state = initialState, action: userAction): IUserState => {
    switch (action.type) {
        case UserActionTypes.INITIALIZED:
            return { ...state, isInitialize: true };

        case UserActionTypes.CLEAR_STATE_USER:
            return {
                ...state,
                user: undefined,
                loading: false,
                message: undefined,
                error: undefined,
                isSecondStepLogIn: false,
            };

        case UserActionTypes.CLEAR_USER_ERROR: {
            return { ...state, error: undefined };
        }
        case UserActionTypes.CLEAR_USER_MESSAGE: {
            return {
                ...state,
                message: undefined,
            };
        }

        case UserActionTypes.START_USER_ACTION:
            return startLoading(state);

        case UserActionTypes.ERROR_USER_ACTION:
            return stopErrorLoading(state, action.payload);

        case UserActionTypes.REGISTRATION_SUCCESS:
            return { ...state, loading: false, message: action.payload.message };

        case UserActionTypes.LOGIN_FIRST_STEP_SUCCESS:
            return { ...state, loading: false, isSecondStepLogIn: true, multiFactorError: action.payload.MFE };

        case UserActionTypes.LOGIN_SECOND_STEP_SUCCESS:
            return { ...state, loading: false, user: action.payload, isSecondStepLogIn: false };

        default:
            return state;
    }
};

export default userReducer;
