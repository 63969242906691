import api from "@/api";
import {
    IMetal,
    ICategoryData,
    IResponseCategory,
    IProductData,
    IResponseProduct,
    IMetalPrices,
    IUpdateMainData,
    IUpdateProduct,
    IResponseDataProduct,
    IResponseDataCategory,
    IPaginationData,
    IPaginationCategoryData
} from "@api/product/productType";
import {IResponseMessage} from "@api/admin/adminType";

export const productApi = {
    async getMetals() {
        return (await api.get<IMetal[]>(`/metal`)).data
    },
    async getMetalPrices() {
        return (await api.get<IMetalPrices[]>(`/metal/prices?currency=AUD`)).data
    },
    async getAllCategories(data?: IPaginationCategoryData) {
        return (await api.get<IResponseDataCategory>(`/category/pagination`, {
            params: {
                page: data?.page,
                page_limit: data?.pageLimit,
                metal_id: data?.metalId,
            }
        })).data
    },
    async getAllCategoriesWithOutPagination(metalId?: string){
        return (await api.get<IResponseCategory[]>(`/category/admin`, {
            params: {
                metal_id: metalId,
            }
        })).data
    },
    async createCategory(data: ICategoryData) {
        return (await api.post<IResponseCategory>(`/category`, data)).data
    },
    async updateCategory(id: string, data: ICategoryData) {
        return (await api.patch<IResponseCategory>(`/category/${id}`, data)).data
    },
    async deleteCategory(id: string) {
        return (await api.delete<IResponseMessage>(`/category/${id}`)).data
    },
    async getAllProducts(data?:IPaginationData) {
        return (await api.get<IResponseDataProduct>(`/product/admin`,{
            params:{
                page: data?.page,
                page_limit: data?.pageLimit
            }
        })).data
    },
    async createProduct(data: IProductData) {
        return (await api.post<IResponseProduct>(`/product/admin/full`, data)).data
    },
    async getProduct(id:string){
      return (await api.get<IResponseProduct>(`/product/admin/${id}`)).data
    },
    async updateFullProductData(id: string, data: IUpdateProduct) {
        return (await api.patch<IResponseProduct>(`/product/admin/full/${id}`, data)).data
    },
    async updateMainPartProduct(id: string, data: IUpdateMainData){
        return (await api.patch<IResponseProduct>(`/product/admin/${id}`, data)).data
    },
    async deleteProduct(id: string){
        return (await api.delete<IResponseMessage>(`/product/admin/${id}`)).data
    }

}