import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import StyledCloseButton from '@ui/StyledCloseButton';

const modalRootElement = document.getElementById('modal');

export interface IModalElement {
    closeModal?: () => void;
}

interface IModalProps {
    show: boolean;
    setShow: (param: boolean) => void;
    ModalElement: React.FC<IModalElement>;
    marginTop?: string;
    width?: string;
    height?: string;
}

const Modal: React.FC<IModalProps> = ({ ModalElement, show, setShow, marginTop, width, height }) => {
    const element = useMemo(() => document.createElement('div'), []);
    const closeModal = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            modalRootElement?.appendChild(element);

            return () => {
                modalRootElement?.removeChild(element);
            };
        }
    });

    if (show) {
        return createPortal(
            <ModalWindow>
                <ModalShadow onClick={closeModal} />
                <ModalContent marginTop={marginTop} width={width} height={height}>
                    <CloseButtonContainer>
                        <StyledCloseButton onClick={closeModal} />
                    </CloseButtonContainer>
                    <ModalElement closeModal={closeModal} />
                </ModalContent>
            </ModalWindow>,
            element
        );
    } else return null;
};

const ModalWindow = styled.div`
    font-family: 'Source Sans Pro';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    max-height: 100%;
    overflow-y: auto;
    z-index: 2;
`;

const ModalShadow = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.35);
    padding: 60px 15px;
`;

interface IModalContentProps {
    marginTop?: string;
    width?: string;
    height?: string;
}

const ModalContent = styled.div<IModalContentProps>`
    margin: ${(props) => (props.marginTop ? props.marginTop : '0')} auto auto;
    max-width: ${(props) => (props.width ? props.width : '520px')};
    padding: 0 25px 25px;
    max-height: 1300px;
    // height: ${(props) => (props.width ? props.width : '')};
    border-radius: 6px;
    background-color: #ffffff;
    z-index: 1001;
`;

const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: right;
    position: relative;
    right: 0;
    top: 30px;
    /*  height: 18px;
    width: 18px;
  
  
  
    border: none;
  
  */
    /*  background: none;
    cursor: pointer;
    transition: .3s;
    outline: none;*/

    /*  &:hover {
      transition: .3s;
      transform: rotate(90deg);
    }*/
`;

export default Modal;
