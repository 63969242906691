import React, {useEffect, useState} from 'react';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import styled from "styled-components";

interface IEditorData {
    data: string
    fieldName: string
    onChangeValue: (a: string) => void
}

const EditorField: React.FC<IEditorData> = ({data, fieldName, onChangeValue}) => {
    const {contentBlocks, entityMap} = htmlToDraft(data)
    const [contentState, setContentState] = useState<EditorState>(() =>
        EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
    useEffect(()=>{
        const {contentBlocks, entityMap} = htmlToDraft(data)
        if (contentState.getCurrentContent().getPlainText() === ''){
            setContentState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)))
        }

    }, [data])


    return (
        <div>
            <SmallTitle>
                {
                    fieldName
                }
            </SmallTitle>
            <Editor
                editorState={contentState}
                toolbarStyle={{
                    border: '1px solid #B6B6B6'
                }}
                editorStyle={{
                    height: 350,
                    border: '1px solid #B6B6B6',
                }}
                onEditorStateChange={(value) => {
                    setContentState(value)

                    const newText = draftToHtml(convertToRaw(value.getCurrentContent()))
                    onChangeValue(newText)
                    //setCurrentText(newText)
                }}
            />
        </div>
    );
};

const SmallTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

export default EditorField;