import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from '@hooks/useClickAway';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '@hooks/useDebounce';
import { useTypedSelector } from '@hooks/useSelector';
import { ReactComponent as SearchIcon } from '@assets/svgs/header_search_icon.svg';
import { ReactComponent as ClearIcon } from '@assets/svgs/search_close_icon.svg';

interface ISearchInput {
    isModalElement?: boolean;
    closeFunc?: () => void;
}

const SearchInput: React.FC<ISearchInput> = ({ isModalElement, closeFunc }) => {
    const navigate = useNavigate();

    const { productsData } = useTypedSelector((state) => state.productReducer);

    const [searchData, setSearchData] = useState('');
    const [showSuggestedList, setShowSuggestedList] = useState(false);
    const [isTyping, setIsTyping] = useState(true);
    const [products, setProducts] = useState(productsData);

    const ref = useRef(null);
    const listRef = useRef(null);

    useClickAway(
        ref,
        () => {
            closeSearch();
        },
        listRef
    );

    const debouncedSearchTerm = useDebounce(searchData, 500);

    useEffect(() => {
        setProducts(productsData);
    }, [productsData]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            //Поисковик
            setProducts(
                productsData.filter((a) => {
                    if (a.name) {
                        return a.name.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) !== -1;
                    }
                    return false;
                })
            );
            setSearchData(debouncedSearchTerm);
            setShowSuggestedList(true);
        }
    }, [debouncedSearchTerm]);

    const goToProduct = (id: string) => {
        navigate(`/products/update/${id}`);
        closeSearch();
    };

    const closeSearch = () => {
        setShowSuggestedList(false);
        clearSearch();
        setSearchData('');
        setIsTyping(false);
        setProducts(productsData);
        if (!closeFunc) return;
        closeFunc();
    };

    const clearSearchField = () => {
        setSearchData('');
        setIsTyping(false);
        clearSearch();
    };

    const clearSearch = () => {
        setProducts(productsData);
    };

    return (
        <SearchBlock isModalElement={isModalElement}>
            <SearchContainer ref={ref}>
                <SearchIconBlock isSelected={showSuggestedList} />
                <Search
                    isSelected={showSuggestedList}
                    value={searchData}
                    onClick={() => {
                        setShowSuggestedList(true);
                    }}
                    onChange={(e) => {
                        setIsTyping(true);
                        setSearchData(e.target.value);
                    }}
                    placeholder={'Search'}
                />
                <ClearButton onClick={clearSearchField} />
            </SearchContainer>
            {
                <SuggestList isShow={showSuggestedList && isTyping} ref={listRef}>
                    {products.length === 0 ? (
                        <NoData>no Data</NoData>
                    ) : (
                        products.map((item, index) => {
                            return (
                                <ListElem
                                    key={`Listelementa${index}`}
                                    onMouseDown={(e) => {
                                        setShowSuggestedList(true);
                                        e.preventDefault();
                                        goToProduct(item.id);
                                        closeSearch();
                                    }}
                                    /*                                        onClick={
                                                                                    (e) => {
                                                                                        setShowSuggestedList(true)
                                                                                        e.preventDefault()
                                                                                        goToProduct(item.id)
                                                                                        closeSearch()
                                                                                    }}*/
                                >
                                    {item.name}
                                </ListElem>
                            );
                        })
                    )}
                </SuggestList>
            }
        </SearchBlock>
    );
};

const ClearButton = styled(ClearIcon)`
    //display: none;
    cursor: pointer;
    position: absolute;
    right: 0.8vw;
    display: block;
    /*  @media (max-width: 1100px) {
    cursor: pointer;
    position: absolute;
    right: 2vw;
    display: block;
  }*/
`;

const NoData = styled.div`
    cursor: pointer;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 1100px) {
        //width: 90vw;
    }
`;

const ListElem = styled.div`
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 14px;
    cursor: pointer;

    &:hover {
        background-color: #f8dcb3;
    }
`;

interface IShowList {
    isShow?: boolean;
}

const SuggestList = styled.div<IShowList>`
    position: absolute;
    display: ${(props) => (props.isShow ? `block` : `none`)};
    flex-direction: column;
    background-color: white;
    width: 325px;
    top: 72px;
    //margin-top: 240px;
    overflow: auto;
    max-height: 180px;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px;
    min-height: 40px;
    @media (max-width: 1290px) {
        //width: calc(24vw - 30px);
    }

    @media (max-width: 1100px) {
        top: 56px;
        //width: 90vw;
    }
`;

const SearchIconBlock = styled(SearchIcon)<{ isSelected?: boolean }>`
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;

    * {
        fill: #200e32; //${(props) => (props.isSelected ? '#200E32' : 'white')};
    }

    @media (max-width: 1100px) {
        * {
            fill: #200e32;
        }
    }
`;

interface ISearchBlock {
    isModalElement?: boolean;
}

const SearchBlock = styled.div<ISearchBlock>`
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1100px) {
        //display: ${(props) => (props.isModalElement ? 'flex' : 'none')};
        background-color: white;
        border: 0.5px solid #0b1126;
        border-radius: 6px;
        //width: 90vw;
    }
`;

const Search = styled.input<{ isSelected?: boolean }>`
    width: 274px;
    height: 40px;
    border-radius: 6px;
    border: lightgray 1px solid;
    padding-left: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    transition: color 0.2s, border-color 0.2s;

    color: black; //${(props) => (props.isSelected ? 'black' : '#9692A6')};
    background-color: white; //${(props) => (props.isSelected ? 'white' : '#1B295A')};

    @media (max-width: 1290px) {
        //width: calc(20vw - 30px);
    }
    @media (max-width: 1100px) {
        background-color: white;
        border: 0.5px solid #0b1126;
        border-radius: 6px;
        //width: 100%;
    }
`;

export default SearchInput;
