import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import logo from '@assets/svgs/logo.svg';
import '../../index.css';
import UserImage from '@assets/user_image.png';
import Hamburger from '@assets/svgs/Hamburger.svg';
import LogoutLogo from '@assets/svgs/Logout.svg';
import { Link } from 'react-router-dom';
import Down from '@assets/svgs/Down.svg';
import { useUserActions } from '@hooks/useActions';
import { useClickAway } from '@hooks/useClickAway';
import { MAIN_DOMAIN } from '@/api';
import FirebaseService from '@/services/firebase-service';

interface IShow {
    height: string;
    width: string;
    display: string;
}

interface IHeaderProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    show: IShow;
    setShow: (show: IShow) => void;
}

const Header: React.FC<IHeaderProps> = ({ open, setOpen, show, setShow }) => {
    const { logout } = useUserActions();
    const [auth, setAuth] = useState(true);
    const authLinks = [
        {
            title: 'Products',
            url: '/',
        },
        {
            title: 'Categories',
            url: '/categories',
        },
        {
            title: 'Information pages',
            url: '/static-pages',
        },
        {
            title: 'Users',
            url: '/users',
        },
        {
            title: 'Orders',
            url: '/orders',
        },
    ];
    const headerLinks = [
        {
            title: 'Products',
            url: '/',
        },
        {
            title: 'Categories',
            url: '/categories',
        },
        {
            title: 'Information pages',
            url: '/static-pages',
        },
        {
            title: 'Users',
            url: '/users',
        },
        {
            title: 'Orders',
            url: '/orders',
        },
    ];
    const userInfo = {
        items: 1,
        userIcon: UserImage,
        userName: 'Paul',
    };
    /*    const [openModalSignIn, setOpenModalSignIn] = useState(false);
        const [openModalLogIn, setOpenModalLogIn] = useState(false);*/
    const [isShown, setIsShown] = useState(false);
    const [rotate, setRotate] = useState('180deg');

    const refMenuElem = useRef(null);
    const refAvoidElem = useRef(null);
    useClickAway(
        refMenuElem,
        () => {
            setRotate(rotate === '180deg' ? '360deg' : '180deg');
            setIsShown(false);
        },
        refAvoidElem
    );

    const firebaseLogout = async () => {
        return await FirebaseService.auth.signOut();
    };

    return (
        <>
            <Wrapper>
                <Container>
                    <LogoSearch>
                        <LogoBlock
                            to={`/`}
                            onClick={() => {
                                window.location.href = MAIN_DOMAIN;
                            }}
                        >
                            <Logo src={logo} alt="logo" onClick={() => {}} />
                        </LogoBlock>
                        <LinksBlock>
                            {headerLinks.map((item, index) => {
                                if (item.url.includes('/')) {
                                    return (
                                        <Links key={`links-index-${index}`} to={`${item.url}`}>
                                            {item.title}{' '}
                                        </Links>
                                    );
                                } else {
                                    return (
                                        <Links key={`links-index-${index}`} to={`/${item.url}`}>
                                            {item.title}{' '}
                                        </Links>
                                    );
                                }
                            })}
                        </LinksBlock>
                    </LogoSearch>

                    <AuthBlock
                        ref={refAvoidElem}
                        onClick={() => {
                            setRotate(rotate === '180deg' ? '360deg' : '180deg');
                            setIsShown(!isShown);
                        }}
                    >
                        {
                            /*                            !auth ? <>
                                                                <LogInBox onClick={() => {
                                                                }}> Log In &nbsp;</LogInBox> <LogInBox
                                                                onClick={() => {
                                                                }}>| Sign In</LogInBox>
                                                            </>
                                                            : <AuthorizedBlock>
                                                                <UsernameBlock onClick={() => {
                                                                    setIsShown(!isShown)
                                                                }}>
                                                                    <ImgUser src={userInfo.userIcon}/>
                                                                    <UserName>{userInfo.userName}</UserName>
                                                                </UsernameBlock>
                                                            </AuthorizedBlock>*/
                            'Admin'
                        }
                        <ImgDown ref={refAvoidElem} rotate={rotate}>
                            <img src={Down} alt={'downIcon'} />
                        </ImgDown>
                        <LinksBlockTablet
                            src={Hamburger}
                            onClick={() => {
                                setOpen(!open);
                            }}
                        />
                        {isShown && auth && (
                            <AccountMenuBlock ref={refMenuElem}>
                                {authLinks.map((item, index) => {
                                    return (
                                        <AccountMenuLinks key={`links-index-${index}`} to={`${item.url}`}>
                                            {item.title}{' '}
                                        </AccountMenuLinks>
                                    );
                                })}
                                <Logout
                                    onClick={() => {
                                        setAuth(false);
                                        // logout();
                                        firebaseLogout();
                                        window.location.reload();
                                    }}
                                >
                                    {' '}
                                    <LogoutIcon bg={LogoutLogo} />
                                    Log out
                                </Logout>
                            </AccountMenuBlock>
                        )}
                    </AuthBlock>
                </Container>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: #5b73cf;
    justify-content: center;
    align-items: center;
    font-family: 'Source Sans Pro';
`;
const LogoutIcon = styled.div<{ bg: string }>`
    margin-right: 11px;
    width: 24px;
    height: 24px;
    background: url(${window.location.origin}${(props) => props.bg});
`;
const Logout = styled.button`
    border: 0;
    background: white;
    display: flex;
    align-items: center;
    padding: 13px 0 13px 16px;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    color: #202020;
`;
const LogInBox = styled.a`
    @media (max-width: 600px) {
        display: none;
        margin: 0;
    }
`;
const AccountMenuBlock = styled.div`
    background: white;
    position: fixed;
    overflow: visible;
    top: -23px;
    left: 0;
    z-index: 1000;
    transform: translate(calc(1000px + 0.5 * (100vw - 1290px)), 95px);
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.22);
    @media (max-width: 1290px) {
        transform: translate(calc(100vw - 285px), 80px);
    }
    @media (max-width: 600px) {
        display: none;
    }
`;
const AccountMenuLinks = styled(Link)`
    text-decoration: none;
    width: 255px;
    padding: 13px 0 13px 16px;
    color: #202020;
`;
const LogoSearch = styled.div`
    display: flex;
`;
const LinksBlockTablet = styled.img`
    transition: transform 1s;
    width: 64px;
    height: 64px;
    display: none;
    z-index: 2;

    @media (max-width: 600px) {
        display: block;
        width: 24px;
        height: 24px;
    }
`;
const ImgUser = styled.img`
    width: 32px;

    @media (max-width: 900px) {
        width: 24px;
    }
    @media (max-width: 600px) {
        display: none;
        margin: 0;
    }
`;
const CartIconBox = styled.div`
    position: relative;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const UserName = styled.div`
    display: flex;
    margin-left: 14px;
    @media (max-width: 900px) {
        display: none;
        margin: 0;
    }
`;
const UsernameBlock = styled.div`
    display: flex;
    margin: 0 14px;
    align-items: center;
    position: relative;
    justify-content: center;
    @media (max-width: 900px) {
        margin: 0 5px;
    }
`;
const Container = styled.div`
    margin: 0;
    padding: 10px 0;
    max-width: 1290px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1290px) {
        padding: 6px 16px;
    }
    @media (max-width: 600px) {
        padding: 6px 11px;
    }
`;
const Logo = styled.img`
    max-width: 106px;
    width: 100%;
    @media (max-width: 900px) {
        width: 90px;
    }
    @media (max-width: 600px) {
        width: 70px;
    }
`;
const AuthorizedBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SearchIconBlock = styled.div<{ bg: string }>`
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
    background: no-repeat url(${window.location.origin}${(props) => props.bg});
`;
const MobileSearchIconBlock = styled.div<{ bg: string }>`
    width: 24px;
    height: 24px;
    left: 10px;
    display: none;
    background: no-repeat url(${window.location.origin}${(props) => props.bg});
    @media (max-width: 1100px) {
        display: block;
        margin-right: 20px;
    }
`;
const Cart = styled.div<{ bg: string }>`
    background: no-repeat url(${window.location.origin}${(props) => props.bg});
    width: 25px;
    height: 25px;
`;
const Circle = styled.div`
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #eb5757;
    top: -7px;
    left: 12px;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LogoBlock = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 2;
    margin-right: 18px;
`;
const SearchBlock = styled.div`
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
    @media (max-width: 1100px) {
        display: none;
    }
`;
const Search = styled.input`
    background-color: #1b295a;

    width: 274px;
    height: 40px;
    color: #9692a6;
    border-radius: 6px;
    border: lightgray 1px solid;
    padding-left: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    transition: color 0.2s, border-color 0.2s;

    &:focus-visible {
        color: lightgray;
    }

    @media (max-width: 1290px) {
        width: calc(20vw - 30px);
    }
`;
const LinksBlock = styled.div`
    display: flex;
    width: 400px;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
    @media (max-width: 900px) {
        max-width: 360;
    }
    @media (max-width: 600px) {
        display: none;
    }
`;
const AuthBlock = styled.div`
    display: flex;
    z-index: 2;

    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
    @media (max-width: 1300px) {
    }
`;
const Links = styled(Link)`
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    transition: color 0.2s;
    @media (max-width: 1000px) {
        font-size: 14px;
    }

    &:hover {
        color: #f8c882;
    }
`;

const ImgDown = styled.div<{ rotate: string }>`
    display: flex;
    justify-content: right;
    transform: scale(0.8) rotate(${(props) => props.rotate});
    margin: 20px;
`;

export default Header;
