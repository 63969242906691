import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextFields from '@/ui/TextFields';
import StyledButton from '@/ui/StyledButton';
import axios from 'axios';
import { BASE_API_URL } from '@/api';
import { FIELD_TYPE, validateField } from '@/helpers/registerValidation';
import { logInDev } from '@/store/actions/userActions';
import FirebaseService from '@/services/firebase-service';
import { userAPI } from '@/api/user/userApi';
import { useRecaptcha } from '@/hooks/useRecaptcha';
import { MultiFactorResolver, getMultiFactorResolver } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '@/store/actions/snackbarActions';
import { useTypedSelector } from '@/hooks/useSelector';
import { SnakeTypes } from '@/ui/components/modals/SnakeBar';
import { useUserActions } from '@/hooks/useActions';
import Timer from '@/ui/Timer';

const SignIn = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [stage, setStage] = useState<string | 'sign-in' | 'enter-code'>('sign-in');

    // TFA
    const recaptcha = useRecaptcha('sign-in');
    const [verificationId, setVerificationId] = useState<string>();
    const [resolver, setResolver] = useState<MultiFactorResolver>();
    const [currentValue, setCurrentValue] = useState<string>('');
    const [timerTime, setTimerTime] = useState(120);
    const [isShowTimer, setIsShowTimer] = useState(true);
    const [isShowResendButton, setIsShowResendButton] = useState(false);

    const { logInFirstStep } = useUserActions();

    const { loading, multiFactorError } = useTypedSelector((state) => state.userReducer);

    const dispatch = useDispatch();

    async function verify() {
        if (multiFactorError && recaptcha) {
            setStage('enter-code');
            const data = await FirebaseService.auth.email.handleMFA(multiFactorError, recaptcha, 0);
            if (!data) {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Something went wrong', ms: 4000 }));
                // return;
            } else if (data === 'too-many-attempts') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Too many attempts, try again later', ms: 4000 }));
                // dispatch(closeSecondStepLogIn());
                setStage('sign-in');
            } else {
                const { verificationId, resolver } = data;
                setVerificationId(verificationId);
                setResolver(resolver);
            }
            setIsShowResendButton(false);
            setIsShowTimer(true);
        }
    }

    const confirmHandler = async () => {
        if (resolver && verificationId && currentValue.length > 0) {
            const response = await FirebaseService.auth.email.handleMFASubmit({ verificationId, resolver }, currentValue);
            if (response === 'invalidCode') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Invalid code', ms: 4000 }));
                return;
            }
            if (response === 'missingCode') {
                dispatch(showSnackbar({ type: SnakeTypes.warning, content: 'Code is missing', ms: 4000 }));
                return;
            } else if (response === 'sessionExpired') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Code expired', ms: 4000 }));
                return;
            } else if (response === 'too-many-attempts') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Too many attempts', ms: 4000 }));
                return;
            }
            if (response === false) {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Something went wrong', ms: 4000 }));
                return;
            }
        }
    };

    const logIn = async () => {
        try {
            setError(null);
            const userData = {
                email: login,
                password: password,
            };
            const isAdmin = await userAPI.isAdmin({ email: userData.email });
            if (isAdmin.status === true) {
                logInFirstStep({ ...userData });
            } else {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'You are not admin', ms: 4000 }));
            }
        } catch (e: any) {
            let error;
            if (Array.isArray(e.response?.data?.message)) error = e.response?.data?.message?.map((m: any) => m.code).join(', ') || e.message;
            else error = e.response?.data?.message;
            setError(error);
        }
    };

    const sendCode = () => {
        logInFirstStep({ email: login, password });
        setTimerTime(120);
        setIsShowTimer(false);
        setTimeout(() => setIsShowTimer(true), 1);
    };

    useEffect(() => {
        verify();
    }, [multiFactorError, recaptcha]);

    useEffect(() => setStage('sign-in'), []);

    return stage === 'sign-in' ? (
        <Container>
            <Content>
                <Mod>
                    <LoginHeader>Log in</LoginHeader>

                    <TextFields value={login} onChange={(e) => setLogin(e.target.value)} height={'56px'} type={'email'} placeholder={'Email'} />

                    <div style={{ marginTop: 10 }}></div>

                    <TextFields
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        height={'56px'}
                        type={'password'}
                        placeholder={'Password'}
                    />

                    {error && <div style={{ color: 'red' }}>{error}</div>}

                    <ButtonContainer>
                        <StyledButton disabled={!(login.length > 5 && password.length > 5)} onClick={logIn} height={'50px'}>
                            Log In
                        </StyledButton>
                    </ButtonContainer>
                </Mod>
            </Content>
        </Container>
    ) : (
        <Container>
            <Content>
                <Mod>
                    <LoginHeader>Enter code</LoginHeader>

                    <TextFields
                        value={currentValue}
                        onChange={(e) => setCurrentValue(e.target.value)}
                        height={'56px'}
                        type={'text'}
                        placeholder={'Code'}
                    />

                    {error && <div style={{ color: 'red' }}>{error}</div>}

                    {isShowTimer && (
                        <SendCodeButton>
                            Send the code in{' '}
                            {isShowTimer && (
                                <Timer
                                    initSeconds={timerTime}
                                    handleFinish={() => {
                                        setIsShowTimer(false);
                                        setIsShowResendButton(true);
                                    }}
                                />
                            )}
                        </SendCodeButton>
                    )}

                    {isShowResendButton && (
                        <ButtonContainer>
                            <StyledButton height={'50px'} onClick={sendCode} disabled={loading}>
                                Resend
                            </StyledButton>
                        </ButtonContainer>
                    )}

                    <ButtonContainer>
                        <StyledButton disabled={currentValue.length !== 6} onClick={confirmHandler} height={'50px'}>
                            Confirm
                        </StyledButton>
                    </ButtonContainer>
                </Mod>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    margin: 0 auto;
    position: relative;
    max-width: 1530px;
    box-sizing: border-box;
    font-family: 'Source Sans Pro';
`;
const ButtonContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
`;

const LoginHeader = styled.div`
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 20px;
`;

const Content = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
`;

const Mod = styled.div`
    width: 400px;
    padding: 20px 40px 10px;
    border: 1px solid grey;
    border-radius: 7px;
`;

const SendCodeButton = styled.div`
    text-align: center;
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5b73cf;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
`;

export default SignIn;
