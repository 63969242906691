import * as UserAction from '../store/actions/userActions';
import * as AdminAction from '../store/actions/adminAction';
import * as MediaAction from '../store/actions/mediaAction';
import * as ProductAction from '../store/actions/productActions';
import * as SnakeBarAction from '../store/actions/snackbarActions';
import * as UsersActions from '../store/actions/usersActions';
import * as OrdersActions from '../store/actions/ordersActions';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

export const useUserActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(UserAction, dispatch);
};

export const useAdminActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(AdminAction, dispatch);
};

export const useMediaActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(MediaAction, dispatch);
};

export const useProductActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(ProductAction, dispatch);
};
export const useSnakeBarAction = () => {
    const dispatch = useDispatch();
    return bindActionCreators(SnakeBarAction, dispatch);
};
export const useUsersActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(UsersActions, dispatch);
};

export const useOrdersActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(OrdersActions, dispatch);
};
