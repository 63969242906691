export const numberCheck = (value: string, maxLen: number, lenAfterPoint: number = 2): boolean => {
    const len = value.length - 1;
    const curSymbol = value[len];

    return (
        (!Number(value) && value.length !== 0 && curSymbol !== '.' && curSymbol !== '0') ||
        value.length >= maxLen ||
        value.split('.').length > 2 ||
        value.split('.')[1]?.length > lenAfterPoint
    );
};

export const priceNumberCheck = (value: string, maxLen: number, lenAfterPoint: number = 2): boolean => {
    const len = value.length - 1;
    const curSymbol = value[len];

    if (curSymbol === '.') {
        if (value.includes('.')) {
            return true;
        } else {
            return value.length >= maxLen || value.split('.').length > 2 || value.split('.')[1]?.length > lenAfterPoint;
        }
    }

    return (
        (!Number(value) && value.length !== 0 && curSymbol !== '.' && curSymbol !== '0') ||
        value.length >= maxLen ||
        value.split('.').length > 2 /*||
        value.split('.')[1]?.length > lenAfterPoint */
    );
};

//true если не число false если число

export const numberCheckAmount = (value: string, maxLen: number): boolean => {
    const len = value.length - 1;
    const curSymbol = value[len];
    return (!Number(curSymbol) && value.length !== 0 && curSymbol !== '0') || value.length >= maxLen;
};

export const fixedCheck = (value: string): boolean => {
    const maxLen = 17;
    return numberCheck(value, maxLen, 12);
};

export const percentCheck = (value: string): boolean => {
    const maxLen = 7;
    return numberCheck(value, maxLen);
};

export const purityCheck = (value: string): boolean => {
    const maxLen = 6;
    return numberCheck(value, maxLen, 3);
};

export const priceCheck = (value: string): boolean => {
    const maxLen = Number.MAX_SAFE_INTEGER;
    return priceNumberCheck(value, maxLen, 1);
};

export const amountCheck = (value: string): boolean => {
    const maxLen = Number.MAX_SAFE_INTEGER;
    return numberCheckAmount(value, maxLen);
};
