import React, {useState} from 'react';
import styled from "styled-components";
import UserImage from "@assets/user_image.png"
import LogoutLogo from "@assets/svgs/Logout.svg"
interface IMobileMenuProps {
    display: string,
    height: string,
    width: string,
    anchorLink: (url: string) => void
}

const MobileMenu: React.FC<IMobileMenuProps> = ({display, height, width, anchorLink}) => {
    const [auth, setAuth] = useState(false);
    const headerLinks = [
        {
            title: "Home",
            url: "/"
        },
        {
            title: "About Us",
            url: "/"
        },
        {
            title: "Shop",
            url: "/"
        },
        {
            title: "FAQ",
            url: "/"
        },
        {
            title: "Contacts",
            url: "/"
        },

    ]
    const authLinks = [
        {
            title: "Favorites",
            url: "/"
        },
        {
            title: "Orders",
            url: "/"
        },
        {
            title: "Addresses",
            url: "/"
        },
        {
            title: "Settings",
            url: "/"
        },

    ]
    const userInfo = {
        userIcon: UserImage,
        userName: "Paul",
        userSurname: "Smith"
    }
    return (
        <HeaderMenuTablet display={display} height={height} width={width}>
            <WrapperMobileMenu>

                <MobileMenuContainer>
                    <NavigationWrapper>
                        Navigation
                        <BorderLine/>
                    </NavigationWrapper>
                    {
                        headerLinks.map((item, index) => {
                                if (item.url.includes("/")) {
                                    return <Links key={`links-index-${index}`}
                                                  href={`${item.url}`} onClick={() => {
                                        anchorLink(item.url)
                                    }}>{item.title} </Links>
                                } else {
                                    return <Links
                                        key={`links-index-${index}`} href={`/${item.url}`} onClick={() => {
                                        anchorLink(item.url)
                                    }}>
                                        {item.title} </Links>
                                }
                            }
                        )
                    }

                </MobileMenuContainer>
                {
                    auth ? <div>
                            <LoginLinks>Log In </LoginLinks> |
                            <LoginLinks>Sign In </LoginLinks>
                        </div>
                        :
                        <AuthBlock>
                            <UserInfoLine>
                                <UserInfoBlock>
                                    <ImgUser src={userInfo.userIcon}/>
                                    <UserName>{userInfo.userName} {userInfo.userSurname}</UserName>
                                </UserInfoBlock>
                                <Log bg={LogoutLogo} onClick={()=>{console.log("Logged out")}}/>
                            </UserInfoLine>
                            <AuthLinksBlock>
                            {
                               authLinks.map((item, index) => {
                                        if (item.url.includes("/")) {
                                            return <Links key={`links-index-${index}`}
                                                          href={`${item.url}`} onClick={() => {
                                                anchorLink(item.url)
                                            }}>{item.title} </Links>
                                        } else {
                                            return <Links
                                                key={`links-index-${index}`} href={`/${item.url}`} onClick={() => {
                                                anchorLink(item.url)
                                            }}>
                                                {item.title} </Links>
                                        }
                                    }
                                )
                            }
                            </AuthLinksBlock>
                        </AuthBlock>
                }
            </WrapperMobileMenu>
        </HeaderMenuTablet>
    );
};
const ImgUser = styled.img`
  width: 50px;

`
const UserInfoBlock = styled.div`
  display: flex;
  align-items: center;

`
const Log = styled.div<{ bg: string }>`
  width: 24px;
  height: 24px;
  background: url(${window.location.origin}${props => props.bg});
  cursor: pointer;
`
const UserName = styled.div`
  font-family: Inter, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 13px;
`
const AuthLinksBlock = styled.div`

`
const Links = styled.a`
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  color: black;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  padding: 19px 0;
  display: flex;
  align-items: center;
  line-height: 32px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 16px;
    padding: 19px 0;
  }
`
const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  border-bottom: #9FABD9 1px solid;

`
const NavigationWrapper = styled.div`
  font-family: Inter, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

`
const AuthBlock = styled.div`


`
const UserInfoLine = styled.div`
  display: flex;
  padding: 24px 0;
  align-items: center;
  justify-content: space-between;
`
const LoginLinks = styled(NavigationWrapper)`
  padding-top: 30px;
  cursor: pointer;
`
const BorderLine = styled.div`
  border-bottom: black 2px solid;
  width: 77px;
  padding-top: 8px;
`
const WrapperMobileMenu = styled.div`
  max-width: 1360px;
  position: relative;
  padding: 0 19px;
  box-sizing: border-box;
`
const HeaderMenuTablet = styled.div<{ height: string, width: string, display: string }>`
  top: 0;
  left: 0;
  height: ${props => props.height};
  width: ${props => props.width};
  background-color: #FFFFFF;
  display: ${props => props.display};
  z-index: 2;
  
`
export default MobileMenu;
