import React from 'react';
import styled from "styled-components";

type StyledButtonProps = {
    onClick?: () => void,
    height?: string,
    width?: string,
    backgroundColor?: string
    border?: string
    color?: string
    borderRadius?: string
    children: React.ReactNode,
    disabled?: boolean,
    blueBackground?: boolean,
}

const StyledButton = (props: StyledButtonProps) => {
    return (
        <Button onClick={props.onClick} disabled={props.disabled}
                style={{
                    height: props.height,
                    width: props.width,
                    backgroundColor: props.backgroundColor,
                    borderRadius: props.borderRadius,
                    border: props.border,
                    color: props.color
                }}>
            {props.children}
        </Button>
    );
};

export default StyledButton;

export const Button = styled.button`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  background-color: #F8C882;
  width: 100%;
  border-radius: 6px;
  border-width: 0;
  cursor: pointer;
  transition: background .15s, color 0.15s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &:hover {
    background: linear-gradient(0deg, rgba(166, 166, 166, 0.2), rgba(166, 166, 166, 0.2)), #F8C882;
  }

  &:active {
    background: linear-gradient(0deg, rgba(101, 101, 101, 0.2), rgba(101, 101, 101, 0.2)), #F8C882;
    color: #FFFFFF;
  }


  &:disabled {
    background: #D3D3D3;
    color: #FFFFFF;
    cursor: default;
  }

`
