import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Down from '@assets/svgs/Down.svg';
import TextFields from '@ui/TextFields';
import StyledButton from '@ui/StyledButton';
import { IContent, IPageContent } from '@api/admin/adminType';
import { useAdminActions } from '@hooks/useActions';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import HeaderField from '@components/staticPages/headerField';
import TextFieldEditor from '@components/staticPages/textFieldEditor';
import LinkField from '@components/staticPages/linkField';
import ImageField from '@components/staticPages/imageField';
import deleteICON from '@assets/svgs/textfield_delete_icon.svg';
import CheckboxComponent from '@ui/CheckboxComponent';

interface IHiddenCardProps {
    isOpen: boolean;
    namePage: string;
    content: IPageContent;
    onClick: () => void;
    onDelete: (name: string) => void;
    userRole: string | undefined;
}

const AccordionElement: React.FC<IHiddenCardProps> = ({ onDelete, onClick, isOpen, namePage, content, userRole }) => {
    const { createTextField, updateTextField, deleteTextField } = useAdminActions();
    const [show, setShow] = useState({
        display: 'hidden',
    });
    const [color, setColor] = useState('#FFFFFF');
    const [rotate, setRotate] = useState('360deg');
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentContent, setCurrentContent] = useState({ ...content });

    useEffect(() => {
        if (isOpen && !isDeleting) {
            setRotate('360deg');
            setShow({ display: 'visible' });
        } else {
            setColor('#FFFFFF');
            setRotate('180deg');
            setShow({ display: 'hidden' });
        }
    }, [isOpen]);

    useEffect(() => {
        if (content && Object.keys(content).length > 0) {
            const key: string | undefined = Object.keys(currentContent ? currentContent : {}).find((key) => {
                if (content[key]?.data === currentContent[key]?.data) {
                    if (content[key]?.url === currentContent[key]?.url) {
                        if (content[key]?.type === currentContent[key]?.type) {
                            return false;
                        }
                    }
                }
                return true;
            });

            if (!key) {
                setCurrentContent({ ...content });
            } else {
                let newData: IPageContent = {};
                Object.keys(content ? content : {}).forEach((item) => {
                    newData[item] = content[item];
                    /*                if (Object.keys(currentContent ? currentContent : {}).includes(item)){
    
                                    }*/
                });
                setCurrentContent({ ...newData });
            }
        } else {
            setCurrentContent({});
        }
    }, [content]);

    const [fieldTittle, setFieldTittle] = useState('');
    const [fieldType, setFieldType] = useState('text');

    const onAddTextField = () => {
        const newSendTitle = fieldTittle.replace(/\?/g, ';quest;');
        createTextField({ name: namePage, title: newSendTitle, type: fieldType });
    };

    const onSaveHandler = (fieldName: string, value: string, url: string | null = null) => {
        const newSendTitle = fieldName.replace(/\?/g, ';quest;');
        //@ts-ignore
        updateTextField({ name: namePage, title: newSendTitle, url: url, data: value });
    };

    const onSaveDisabledHandler = (fieldName: string, is_disabled: boolean) => {
        const newSendTitle = fieldName.replace(/\?/g, ';quest;');
        //@ts-ignore
        updateTextField({ name: namePage, title: newSendTitle, is_disabled });
    };

    const onDeleteHandler = (fieldName: string) => {
        const newSendTitle = fieldName.replace(/\?/g, ';quest;');
        deleteTextField({ title: newSendTitle, name: namePage, type: '' });
    };

    return (
        <div>
            <Wrapper>
                <ManageHeader>
                    <HeaderFAQBlock
                        color={color}
                        onClick={() => {
                            onClick();
                        }}
                    >
                        <Title>{namePage}</Title>
                        <DeleteICON
                            onClick={() => {
                                onDelete(namePage);
                                setIsDeleting(true);
                            }}
                        >
                            <DelIcon width={30} height={30} src={deleteICON} alt="deleteIcon" />
                        </DeleteICON>
                        <ImgDown rotate={rotate}>
                            <img src={Down} alt={'downIcon'} />
                        </ImgDown>
                    </HeaderFAQBlock>
                </ManageHeader>
                <TextBlock display={show.display}>
                    {show.display === 'visible' && (
                        <React.Fragment>
                            <EditFieldContainer>
                                {!Object.keys(currentContent ? currentContent : {}).length && <em style={{ color: 'grey' }}>-No Fields yet-</em>}
                                {Object.keys(currentContent ? currentContent : {}).map((item, index) => {
                                    const currentField: IContent = currentContent[item];
                                    const currentType = currentField?.type;
                                    switch (currentType) {
                                        case 'header': {
                                            return (
                                                <div key={'Header' + index}>
                                                    <HeaderField
                                                        fieldName={item}
                                                        data={currentField.data}
                                                        onDeleteHandler={onDeleteHandler}
                                                        onSaveHandler={onSaveHandler}
                                                        userRole={userRole}
                                                    />
                                                    <CheckContainer>
                                                        <CheckboxComponent
                                                            value={currentField?.is_disabled || false}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                            width={'18px'}
                                                            height={'18px'}
                                                        />
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                        >
                                                            Disabled
                                                        </span>
                                                    </CheckContainer>
                                                </div>
                                            );
                                        }
                                        case 'img': {
                                            return (
                                                <div key={'Img' + index}>
                                                    <ImageField
                                                        fieldName={item}
                                                        data={currentField.data}
                                                        userRole={userRole}
                                                        url={currentField.url}
                                                        onDeleteHandler={onDeleteHandler}
                                                        onSaveHandler={onSaveHandler}
                                                    />
                                                    <CheckContainer>
                                                        <CheckboxComponent
                                                            value={currentField?.is_disabled || false}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                            width={'18px'}
                                                            height={'18px'}
                                                        />
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                        >
                                                            Disabled
                                                        </span>
                                                    </CheckContainer>
                                                </div>
                                            );
                                        }
                                        case 'link': {
                                            return (
                                                <div key={'link' + index}>
                                                    <LinkField
                                                        userRole={userRole}
                                                        fieldName={item}
                                                        data={currentField.data}
                                                        url={currentField.url}
                                                        onDeleteHandler={onDeleteHandler}
                                                        onSaveHandler={onSaveHandler}
                                                    />
                                                    <CheckContainer>
                                                        <CheckboxComponent
                                                            value={currentField?.is_disabled || false}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                            width={'18px'}
                                                            height={'18px'}
                                                        />
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                        >
                                                            Disabled
                                                        </span>
                                                    </CheckContainer>
                                                </div>
                                            );
                                        }
                                        case 'text': {
                                            return (
                                                <div key={'Text' + index}>
                                                    <TextFieldEditor
                                                        userRole={userRole}
                                                        namePage={namePage}
                                                        fieldName={item}
                                                        data={currentField.data}
                                                        onDeleteHandler={onDeleteHandler}
                                                        onSaveHandler={onSaveHandler}
                                                    />
                                                    <CheckContainer>
                                                        <CheckboxComponent
                                                            value={currentField?.is_disabled || false}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                            width={'18px'}
                                                            height={'18px'}
                                                        />
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                onSaveDisabledHandler(item, !currentField?.is_disabled);
                                                            }}
                                                        >
                                                            Disabled
                                                        </span>
                                                    </CheckContainer>
                                                </div>
                                            );
                                        }
                                        default: {
                                            return <></>;
                                        }
                                    }
                                })}
                            </EditFieldContainer>
                            {namePage.toLowerCase() === 'faq' && (
                                <CreateFieldContainer>
                                    <TextFieldContainer>
                                        <TextFields
                                            placeholder={'Field Tittle'}
                                            value={fieldTittle}
                                            textError={''}
                                            height={'46px'}
                                            type={'text'}
                                            onChange={(e) => {
                                                setFieldTittle(e.target.value);
                                            }}
                                        />
                                    </TextFieldContainer>
                                    <SelectField value={fieldType} onChange={(event) => setFieldType(event.target.value)}>
                                        <OptionField value={'header'}>Header</OptionField>
                                        <OptionField value={'text'}>Text</OptionField>
                                        <OptionField value={'img'}>Image</OptionField>
                                        <OptionField value={'link'}>Link</OptionField>
                                    </SelectField>
                                    <FieldAddContainer>
                                        <StyledButton onClick={onAddTextField} height={'46px'}>
                                            add
                                        </StyledButton>
                                    </FieldAddContainer>
                                </CreateFieldContainer>
                            )}
                        </React.Fragment>
                    )}
                </TextBlock>
            </Wrapper>
        </div>
    );
};

const Wrapper = styled.div`
    margin: 10px;
`;

const CheckContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const ManageHeader = styled.div`
    border: 1.5px solid #d8d8d8;
    border-radius: 4px;
    display: flex;
    box-shadow: 2px 2px 2px 2px rgba(91, 115, 207, 0.12);
    /*  width: fit-content;*/
    /*  @media (max-width: 1290px) {
      width: 40vw;
    }
    @media (max-width: 900px) {
      width: 92vw;
    }*/
`;

const ImgDown = styled.div<{ rotate: string }>`
    display: flex;
    justify-content: right;
    transform: rotate(${(props) => props.rotate});
    margin: 20px;
`;

const TextBlock = styled.div<{ display: string }>`
    border: 1.5px solid #d8d8d8;
    border-radius: 4px;
    background-color: #ffffff;
    margin-top: 20px;
    box-shadow: 0 10px 10px rgba(91, 115, 207, 0.12);
    width: 100%;
    transition: visibility 0s, opacity 0.1s linear;
    visibility: ${(props) => props.display};
    opacity: ${(props) => (props.display === 'visible' ? 1 : 0)};
    padding: ${(props) => (props.display === 'visible' ? '30px 0' : '0')};

    /*  @media (max-width: 1290px) {
      width: 40vw;
    }
    @media (max-width: 900px) {
      width: 92vw;
    }*/
`;

const Title = styled.div`
    color: #0b1126;
    padding-left: 10px;
    font-style: normal;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    margin-right: auto;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const HeaderFAQBlock = styled.button`
    background-color: ${(props) => props.color};
    cursor: pointer;
    width: 100%;
    /*  &:hover {
      background-color: #FFF0DB;
    }*/
    border-width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*  @media (max-width: 1290px) {
      width: 40vw;
    }
    @media (max-width: 900px) {
      width: 92vw;
    }*/
`;

const TextFieldContainer = styled.div`
    width: 200px;
`;

const CreateFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-left: 30px;
    margin-top: 30px;
`;

const SelectField = styled.select`
    margin-left: 15px;
    /*  height: 56px;*/
    border-color: #b6b6b6;
    border-radius: 4px;
    width: 150px;
    text-align: center;
    height: 46px;
`;

const OptionField = styled.option`
    height: 46px;
`;

const FieldAddContainer = styled.div`
    width: 150px;
`;

const EditFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    gap: 40px;
`;

const DeleteICON = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
`;

const DelIcon = styled.img`
    padding: 5px;

    &:hover {
        border-radius: 30px;
        background-color: rgba(0.2, 0.2, 0.2, 0.1);
        transition: background 0.3s;
    }

    &:active {
        width: 34px;
        height: 34px;
    }
`;

export default AccordionElement;
