import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { store } from '@/store';

// export const socket = openSocket(process.env.REACT_APP_SOCKET || '', {
//     transports: ['websocket'],
// });

// socket.on('price_update', (data) => {
//     //@ts-ignore
//     if (window.lastProductCB) window.lastProductCB();
// });

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
