import React, { useEffect, useRef, useState } from 'react';
import Layout from '@components/layout';
import { MainContainer } from '@ui/mainContainer';
import styled from 'styled-components';
import BackIMG from '@assets/svgs/back_arrow.svg';
import LivePriceListBlock from '@components/products/LivePriceListBlock';
import ShopBlock from '@components/products/ShopBlock';
import StyledButton from '@ui/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProductActions, useSnakeBarAction, useUserActions } from '@hooks/useActions';
import { FIELD_CHECK_TYPE, validateProduct } from '@/helpers/validateProduct';
import { IErrorLive, IErrorMain, IErrorShop } from '@/types';
import MainBlock from '@components/products/MainBlock';
import { ILivePriceData, IMainData, IProductData, IShopData, IUpdateProduct } from '@api/product/productType';
import { useTypedSelector } from '@hooks/useSelector';
import { calcBuyPrice, calcSellPrice, calcSellPriceDisplay } from '@/helpers/priceCalculator';
import { formChangedLiveObject, formChangedProductObject, formChangedShopObject } from '@/helpers/formUpdateFields';
import { SnakeTypes } from '@ui/components/modals/SnakeBar';
import Selector, { IList } from '@ui/Selector';
import loadingImg from '@assets/loading.svg';
import { roundToTwoDecimals } from '@/helpers/round';

const WEIGHT_CONST: IList[] = [{ title: 'Gr' }, { title: 'Oz' }];

const CreateProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { metals, currentProduct, metalPrices, categoriesData, loading } = useTypedSelector((state) => state.productReducer);

    const { createProduct, getMetals, getProduct, updateProduct, clearCurrentProduct, getMetalPrices } = useProductActions();
    const { showSnackbar } = useSnakeBarAction();
    const { getUser } = useUserActions();

    const [mainError, setMainError] = useState<IErrorMain>({
        name: '',
        spot_margin_fixed: '',
        spot_margin_percent: '',
        weight: '',
        purity: '',
        aw: '',
        margin_fixed: '',
        margin_percent: '',
    });
    const [liveError, setLiveError] = useState<IErrorLive>({
        category_id: '',
        buyPrice: '',
        sellPrice: '',
    });
    const [shopError, setShopError] = useState<IErrorShop>({
        category_id: '',
        amount_all: '',
        amount_limit: '',
        price: '',
    });
    const [product, setProduct] = useState<IProductData>({
        product: {
            metal_id: '',
            name: '',
            margin_percent: 0,
            margin_fixed: 0,
            spot_margin_percent: 100,
            spot_margin_fixed: 0,
            isCollectable: false,
        },
        live: undefined,
        shop: undefined,
    });
    const [price, setPrice] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isFixedPrice, setIsFixedPrice] = useState(false);
    const [isShopFixedPrice, setIsShopFixedPrice] = useState(false);
    const [isCollectable, setIsCollectable] = useState(false);

    const [selectedWeightType, setSelectedWeightType] = useState(WEIGHT_CONST[0]);

    const setProductData = (data: IMainData) => {
        setProduct({ ...product, product: data });
    };

    const setLiveData = (data: ILivePriceData) => {
        setProduct({ ...product, live: data });
    };

    const setShopData = (data: IShopData) => {
        setProduct({ ...product, shop: data });
    };

    const refMain = useRef<HTMLDivElement>(null);
    const refLive = useRef<HTMLDivElement>(null);
    const refShop = useRef<HTMLDivElement>(null);

    const addProductToTable = () => {
        const main: IErrorMain = {
            name: validateProduct(product.product.name, 'Product Name', FIELD_CHECK_TYPE.textField),
            spot_margin_fixed: validateProduct(
                product.product.spot_margin_fixed?.toString() ? product.product.spot_margin_fixed?.toString() : '',
                'Spot margin +',
                FIELD_CHECK_TYPE.fixedField
            ),
            spot_margin_percent: validateProduct(
                product.product.spot_margin_percent?.toString() ? product.product.spot_margin_percent?.toString() : '',
                'Spot Margin, %',
                FIELD_CHECK_TYPE.percentField
            ),
            weight: validateProduct(
                product.product?.weight?.toString() ? product.product?.weight?.toString() : '',
                'Weight',
                FIELD_CHECK_TYPE.fixedField
            ),
            purity: validateProduct(
                product.product?.purity?.toString() ? product.product?.purity?.toString() : '',
                'Purity',
                FIELD_CHECK_TYPE.purity
            ),
            aw: validateProduct(product.product?.aw?.toString() ? product.product?.aw?.toString() : '', 'AW', FIELD_CHECK_TYPE.fixedField),
            margin_fixed: validateProduct(
                product.product?.margin_fixed?.toString() ? product.product?.margin_fixed?.toString() : '',
                'Margin, +',
                FIELD_CHECK_TYPE.fixedField
            ),
            margin_percent: validateProduct(
                product.product?.margin_percent?.toString() ? product.product?.margin_percent?.toString() : '',
                'Margin, %',
                FIELD_CHECK_TYPE.percentField
            ),
        };

        let hasLiveError = false;

        if (product.live?.is_visible) {
            const live: IErrorLive = {
                category_id: validateProduct(product.live.category_id, 'Category', FIELD_CHECK_TYPE.textField),
                buyPrice: validateProduct(String(product.live.buy_price), 'buy_price', FIELD_CHECK_TYPE.priceField),
                sellPrice: validateProduct(String(product.live.sell_price), 'sell_price', FIELD_CHECK_TYPE.priceField),
            };
            hasLiveError = Object.values(live).some((x) => x !== '');
            if (hasLiveError) {
                setLiveError(live);
                refLive.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }

        let hasShopError = false;
        if (product.shop?.is_visible) {
            const shop: IErrorShop = {
                category_id: validateProduct(product.shop.category_id, 'Category', FIELD_CHECK_TYPE.textField),
                amount_all: validateProduct(
                    product.shop?.amount_all?.toString() ? product.shop?.amount_all?.toString() : '',
                    'Amount (all)',
                    FIELD_CHECK_TYPE.amount
                ),
                amount_limit: validateProduct(
                    product.shop?.amount_limit?.toString() ? product.shop?.amount_limit?.toString() : '',
                    'Critical Amount',
                    FIELD_CHECK_TYPE.amountLimit
                ),
                price: validateProduct(String(product.shop.price), 'price', FIELD_CHECK_TYPE.priceField),
            };
            hasShopError = Object.values(shop).some((x) => x !== '');
            if (hasShopError) {
                setShopError(shop);
                refShop.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }

        const hasMainError = Object.values(main).some((x) => x !== '');
        if (hasMainError) {
            setMainError(main);
            refMain.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        if (!hasMainError && !hasShopError && !hasLiveError) {
            if (location.pathname.includes('update')) {
                const productObj = formChangedProductObject(currentProduct, product.product);
                const liveObj = formChangedLiveObject(currentProduct?.live, product.live);
                const shopObj = formChangedShopObject(currentProduct?.shop, product.shop);
                if (!productObj && !liveObj && !shopObj) {
                    showSnackbar({ content: 'No data for update', type: SnakeTypes.warning });
                    return;
                }
                setIsUpdated(true);

                if (selectedWeightType.title === 'Oz') {
                    if (productObj?.weight) productObj.weight *= 31.12;
                    if (productObj?.aw) productObj.aw *= 31.12;
                }

                // let buy_price = 0;
                // let sell_price = 0;
                // let shop_price = 0;

                // if (isFixedPrice && typeof liveObj?.buy_price === 'number') {
                //     buy_price = liveObj?.buy_price;
                // } else if (isFixedPrice && typeof product.live?.buy_price === 'number') {
                //     buy_price = product.live.buy_price;
                // } else {
                //     buy_price = calcBuyPriceFunc();
                // }

                // if (isFixedPrice && typeof liveObj?.sell_price === 'number') {
                //     if (productObj?.gst === true) {
                //         sell_price = liveObj?.sell_price / 1.1;
                //     } else if (product.product.gst === true) {
                //         sell_price = liveObj?.sell_price / 1.1;
                //     } else {
                //         sell_price = liveObj?.sell_price;
                //     }
                // } else if (isFixedPrice && typeof product.live?.sell_price === 'number') {
                //     if (productObj?.gst === true) {
                //         sell_price = product.live.sell_price / 1.1;
                //     } else if (product.product.gst === true) {
                //         sell_price = product.live.sell_price / 1.1;
                //     } else {
                //         sell_price = product.live.sell_price;
                //     }
                // } else {
                //     sell_price = calcSellPriceFunc();
                // }

                // if (isShopFixedPrice && typeof shopObj?.price === 'number') {
                //     if (productObj?.gst === true) {
                //         shop_price = shopObj.price / 1.1;
                //     } else if (product.product.gst === true) {
                //         shop_price = shopObj.price / 1.1;
                //     } else {
                //         shop_price = shopObj.price;
                //     }
                // } else if (isShopFixedPrice && typeof product.shop?.price === 'number') {
                //     if (productObj?.gst === true) {
                //         shop_price = product.shop?.price / 1.1;
                //     } else if (product.product.gst === true) {
                //         shop_price = product.shop?.price / 1.1;
                //     } else {
                //         shop_price = product.shop?.price;
                //     }
                // } else if (isShopFixedPrice === false && typeof product.shop?.price === 'number') {
                //     if (productObj?.gst === true) {
                //         shop_price = product.shop?.price / 1.1;
                //     } else if (product.product.gst === true) {
                //         shop_price = product.shop?.price / 1.1;
                //     } else {
                //         shop_price = product.shop?.price;
                //     }
                // } else if (typeof liveObj?.sell_price === 'number') {
                //     if (productObj?.gst === true) {
                //         shop_price = liveObj?.sell_price / 1.1;
                //     } else if (product.product.gst === true) {
                //         shop_price = liveObj?.sell_price / 1.1;
                //     } else {
                //         shop_price = liveObj?.sell_price;
                //     }
                // } else {
                //     shop_price = calcSellPriceFunc();
                // }

                let buy_price = 0;
                let sell_price = 0;
                let shop_price = 0;
                if (product.live?.is_fixed_price && typeof product.live?.buy_price === 'number') {
                    buy_price = product.live.buy_price;
                } else {
                    buy_price = calcBuyPriceFunc();
                }

                if (product.live?.is_fixed_price && typeof product.live?.sell_price === 'number') {
                    sell_price = product.live.sell_price / (product.product.gst === true ? 1.1 : 1);
                } else {
                    sell_price = calcSellPriceFunc();
                }

                if (product.shop?.is_fixed_price && typeof product.shop?.price === 'number') {
                    shop_price = product.shop.price / (product.product.gst === true ? 1.1 : 1);
                } else if (
                    product.shop?.is_fixed_price === false &&
                    product.live?.is_fixed_price === true &&
                    typeof product.live.sell_price === 'number'
                ) {
                    shop_price = product.live.sell_price / (product.product.gst === true ? 1.1 : 1);
                } else {
                    shop_price = calcSellPriceFunc();
                }

                updateProduct(location.pathname.split('/')[3], {
                    product: { ...productObj, isCollectable: isCollectable },
                    live: {
                        ...liveObj,
                        buy_price: buy_price,
                        sell_price: sell_price,
                    },
                    shop: {
                        ...shopObj,
                        price: shop_price,
                    },
                } as IUpdateProduct);
                //showSnackbar({content: 'Product successfully updated', type: SnakeTypes.success})
            } else {
                let buy_price = 0;
                let sell_price = 0;
                let shop_price = 0;

                if (product.live?.is_fixed_price && typeof product.live?.buy_price === 'number') {
                    buy_price = product.live.buy_price;
                } else {
                    buy_price = calcBuyPriceFunc();
                }

                if (product.live?.is_fixed_price && typeof product.live?.sell_price === 'number') {
                    sell_price = product.live.sell_price / (product.product.gst === true ? 1.1 : 1);
                } else {
                    sell_price = calcSellPriceFunc();
                }

                if (product.shop?.is_fixed_price && typeof product.shop?.price === 'number') {
                    shop_price = product.shop.price / (product.product.gst === true ? 1.1 : 1);
                } else if (
                    product.shop?.is_fixed_price === false &&
                    product.live?.is_fixed_price === true &&
                    typeof product.live.sell_price === 'number'
                ) {
                    shop_price = product.live.sell_price / (product.product.gst === true ? 1.1 : 1);
                } else {
                    shop_price = calcSellPriceFunc();
                }
                // if (typeof product.live?.buy_price === 'number') {
                //     buy_price = product.live.buy_price;
                // }

                // if (typeof product.live?.sell_price === 'number') {
                //     sell_price = product.live.sell_price / (product.product.gst === true ? 1.1 : 1);
                // }

                // if (typeof product.shop?.price === 'number') {
                //     if (product.product.isCollectable === true) {
                //         shop_price = product.shop.price / (product.product.gst === true ? 1.1 : 1);
                //     } else if (product.live?.is_fixed_price && typeof product.live?.sell_price === 'number') {
                //         shop_price = product.live.sell_price / (product.product.gst === true ? 1.1 : 1);
                //     } else {
                //         shop_price = product.shop.price / (product.product.gst === true ? 1.1 : 1);
                //     }
                // }

                let newProd = {
                    product: {
                        ...product.product,
                        spot_margin_fixed: Number(product.product.spot_margin_fixed),
                        spot_margin_percent: Number(product.product.spot_margin_percent),
                        weight:
                            selectedWeightType.title.toLocaleLowerCase() === 'gr'
                                ? Number(product.product.weight)
                                : Number(product.product.weight) * 31.12,
                        purity: Number(product.product.purity),
                        aw: Number(product.product.aw),
                        margin_fixed: Number(product.product.margin_fixed),
                        margin_percent: Number(product.product.margin_percent),
                        isCollectable: isCollectable,
                        gst: product.product.gst,
                    },
                    live: product.live?.is_visible
                        ? {
                              ...product.live,
                              buy_price: buy_price,
                              sell_price: sell_price,
                          }
                        : undefined,
                    shop: product.shop?.is_visible
                        ? {
                              ...product.shop,
                              price: shop_price,
                              description: product.shop.description?.length === 0 ? undefined : product.shop.description,
                              additional_info: product.shop.additional_info?.length === 0 ? undefined : product.shop.additional_info,
                              exp_date: new Date().toISOString() + '',
                          }
                        : undefined,
                } as IProductData;
                delete newProd.product['metal'];
                createProduct(newProd);
            }
        }
    };

    useEffect(() => {
        if (currentProduct && location.pathname.includes('update')) {
            setProduct({
                product: {
                    name: currentProduct.name,
                    metal_id: currentProduct.metal.id,
                    spot_margin_fixed: currentProduct.spot_margin_fixed,
                    spot_margin_percent: currentProduct.spot_margin_percent,
                    weight: currentProduct.weight,
                    purity: currentProduct.purity,
                    aw: currentProduct.aw,
                    margin_fixed: currentProduct.margin_fixed,
                    margin_percent: currentProduct.margin_percent,
                    gst: currentProduct.gst,
                    isCollectable: currentProduct.isCollectable,
                    metal: currentProduct.metal,
                },
                live: currentProduct.live
                    ? {
                          ...currentProduct.live,
                          buy_price: currentProduct.live.buy_price ? currentProduct.live.buy_price : calcBuyPriceFunc(),
                          sell_price: currentProduct.live.sell_price ? currentProduct.live.sell_price : calcSellPriceFunc(),
                          category_id: currentProduct.live.category_id,
                      }
                    : undefined,
                shop: currentProduct.shop
                    ? {
                          ...currentProduct.shop,
                          price: currentProduct.shop.price ? currentProduct.shop.price : calcBuyPriceFunc(),
                          category_id: currentProduct.shop.category_id,
                      }
                    : undefined,
            });

            if (currentProduct.live?.is_fixed_price) {
                setIsFixedPrice(currentProduct.live.is_fixed_price);
            }

            if (currentProduct.shop?.is_fixed_price) {
                setIsShopFixedPrice(currentProduct.shop.is_fixed_price);
            }

            if (currentProduct.isCollectable) {
                setIsCollectable(currentProduct.isCollectable);
            }

            if (isUpdated) {
                navigate('/');
            }
            setIsUpdated(false);
        }
        if (currentProduct && !location.pathname.includes('update')) {
            navigate('/');
        }
    }, [currentProduct]);

    const calcBuyPriceFunc = () =>
        calcBuyPrice(
            price,
            product.product.spot_margin_percent,
            product.product.spot_margin_fixed,
            selectedWeightType.title.toLocaleLowerCase() === 'gr' ? Number(product.product.aw) : Number(product.product.aw) * 31.12
        );

    const calcSellPriceFunc = () =>
        calcSellPrice(
            price,
            product.product.spot_margin_percent,
            product.product.spot_margin_fixed,
            selectedWeightType.title.toLocaleLowerCase() === 'gr' ? Number(product.product.aw) : Number(product.product.aw) * 31.12,
            product.product.margin_percent,
            product.product.margin_fixed,
            product.product.gst
        );

    const calcDisplayPriceFunc = () =>
        calcSellPriceDisplay(
            price,
            product.product.spot_margin_percent,
            product.product.spot_margin_fixed,
            selectedWeightType.title.toLocaleLowerCase() === 'gr' ? Number(product.product.aw) : Number(product.product.aw) * 31.12,
            product.product.margin_percent,
            product.product.margin_fixed,
            product.product.gst
        );

    const OnChangeWeightType = (value: IList) => {
        if (value.title === selectedWeightType.title) return;
        if (selectedWeightType.title.toLowerCase() === 'gr') {
            setProduct({
                ...product,
                product: {
                    ...product.product,
                    weight: product.product.weight ? product.product.weight / 31.12 : undefined,
                    aw: product.product.aw ? product.product.aw / 31.12 : undefined,
                },
            });
        }
        if (selectedWeightType.title.toLowerCase() === 'oz') {
            setProduct({
                ...product,
                product: {
                    ...product.product,
                    weight: product.product.weight ? product.product.weight * 31.12 : undefined,
                    aw: product.product.aw ? product.product.aw * 31.12 : undefined,
                },
            });
        }
        setSelectedWeightType(value);
    };

    const changeFixedPrice = () => {
        setIsFixedPrice(!isFixedPrice);
        if (isFixedPrice) {
            if (product.live) {
                product.live['is_fixed_price'] = false;
            }
            if (product.shop) {
                product.shop['is_fixed_price'] = false;
                product.product['isCollectable'] = false;
            }
            setIsShopFixedPrice(false);
        } else {
            setProduct({
                ...product,
                live: {
                    category_id: product.live?.category_id ? product.live.category_id : '',
                    ...product.live,
                    buy_price: calcBuyPriceFunc(),
                    sell_price: calcDisplayPriceFunc(),
                    is_fixed_price: true,
                    is_visible: true,
                },
                shop: {
                    ...product.shop,
                    category_id: product.shop?.category_id ? product.shop.category_id : '',
                    exp_date: product.shop?.exp_date ? product.shop.exp_date : new Date().toISOString(),
                    // price: calcDisplayPriceFunc()
                },
            });
        }
    };

    const changeIsCollectable = () => {
        setIsCollectable(!isCollectable);
        if (isCollectable) {
            setProduct({
                ...product,
                product: {
                    ...product.product,
                    isCollectable: false,
                },
                live: {
                    ...product.live,
                    category_id: product.live?.category_id ? product.live.category_id : '',
                    buy_price: calcBuyPriceFunc(),
                    sell_price: calcDisplayPriceFunc(),

                    // is_visible: false,
                },
                shop: {
                    ...product.shop,
                    price: calcDisplayPriceFunc(),
                    category_id: product.shop?.category_id ? product.shop.category_id : categoriesData[0].id,
                    exp_date: product.shop?.exp_date ? product.shop.exp_date : new Date().toISOString(),
                    is_fixed_price: false,
                },
            });
        } else {
            setProduct({
                ...product,
                product: {
                    ...product.product,
                    isCollectable: true,
                },
                live: {
                    ...product.live,
                    category_id: product.live?.category_id ? product.live.category_id : '',
                    is_visible: false,
                    is_buy_display: false,
                    is_sell_display: false,
                    is_fixed_price: false,

                    // is_fixed_price: true,
                },
                shop: {
                    ...product.shop,
                    is_visible: true,
                    category_id: product.shop?.category_id ? product.shop.category_id : categoriesData[0].id,
                    exp_date: product.shop?.exp_date ? product.shop.exp_date : new Date().toISOString(),
                    is_fixed_price: true,
                },
            });
            setIsFixedPrice(false);
        }

        // changeFixedPrice();
        setIsShopFixedPrice((prev) => !prev);
    };

    useEffect(() => {
        if (location.pathname.includes('update')) {
            getProduct(location.pathname.split('/')[3]);
        }
        getMetalPrices();
        getMetals();
        getUser();

        return () => {
            clearCurrentProduct();
        };
    }, []);

    useEffect(() => {
        if (product.product.metal_id.length === 0) {
            const metalId = metals?.find((item) => item.name.toLocaleLowerCase() === 'gold')?.id;
            setProductData({ ...product.product, metal_id: metalId ? metalId : '' });
        }
    }, [metals]);

    useEffect(() => {
        const curPrice = metalPrices?.find((item) => item.id === product.product.metal_id);
        if (curPrice) {
            setPrice(curPrice.price);
        }
    }, [product.product.metal_id, product.product.metal, metalPrices, product]);

    return (
        <Layout>
            <MainContainer>
                <Content>
                    <BackBlock>
                        <img
                            onClick={() => {
                                navigate('/');
                            }}
                            draggable={false}
                            style={{ cursor: 'pointer' }}
                            src={BackIMG}
                            alt={'Back'}
                        />
                    </BackBlock>
                    <TittleLevel>
                        <Title>{location.pathname.includes('update') ? 'Edit product' : 'Add New Product'}</Title>
                        <Selector placeholder={'Weight'} selected={selectedWeightType.title} list={WEIGHT_CONST} setValue={OnChangeWeightType} />
                    </TittleLevel>

                    <Blocks>
                        <div ref={refMain}>
                            <MainBlock
                                weightType={selectedWeightType.title}
                                errorState={mainError}
                                currentData={product.product}
                                setCurrentData={setProductData}
                                isCollectable={isCollectable}
                                isFixed={isFixedPrice}
                            />
                        </div>
                        <div ref={refLive}>
                            <LivePriceListBlock
                                prices={{
                                    buyPrice: calcBuyPriceFunc,
                                    sellPrice: calcSellPriceFunc,
                                    displayPrice: calcDisplayPriceFunc,
                                }}
                                errorState={liveError}
                                product={product.product}
                                mainError={mainError}
                                setMainError={setMainError}
                                lpl={product.live as ILivePriceData}
                                setLPLAndShop={(value, price) => {
                                    if (price) {
                                        setProduct({
                                            ...product,
                                            live: { ...value },
                                            shop: {
                                                ...product.shop,
                                                price: price,
                                                category_id: product.shop?.category_id ? product.shop.category_id : categoriesData[0].id,
                                                exp_date: product.shop?.exp_date ? product.shop.exp_date : new Date().toISOString(),
                                            },
                                        });
                                    } else {
                                        setLiveData(value);
                                    }
                                }}
                                clearMainError={() => {
                                    setMainError({
                                        ...mainError,
                                        weight: '',
                                        purity: '',
                                    });
                                }}
                                clearLiveError={() =>
                                    setLiveError({
                                        category_id: '',
                                        sellPrice: '',
                                        buyPrice: '',
                                    })
                                }
                                isFixed={isFixedPrice}
                                onChangeFixPriceCheckbox={changeFixedPrice}
                                isCollectable={isCollectable}
                            />
                        </div>
                        <div ref={refShop}>
                            <ShopBlock
                                sellPrice={product.live?.sell_price ? product.live.sell_price : calcSellPriceFunc()}
                                calcSellPrice={calcSellPriceFunc}
                                calcDisplayPrice={calcDisplayPriceFunc}
                                errorState={shopError}
                                mainError={mainError}
                                setMainError={setMainError}
                                shop={product.shop as IShopData}
                                setShop={setShopData}
                                product={product.product}
                                clearMainError={() => {
                                    setMainError({
                                        ...mainError,
                                        weight: '',
                                        purity: '',
                                    });
                                }}
                                clearShopError={() =>
                                    setShopError({
                                        category_id: '',
                                        amount_all: '',
                                        amount_limit: '',
                                        price: '',
                                    })
                                }
                                isLiveFixed={isFixedPrice}
                                isFixedPrice={isShopFixedPrice}
                                isCollectable={isCollectable}
                                onChangeIsCollectable={changeIsCollectable}
                            />
                        </div>
                    </Blocks>
                    <ButtonsBlock>
                        <CancelButton onClick={() => navigate('/')}>Cancel</CancelButton>
                        <ButtonContainer>
                            <StyledButton
                                backgroundColor={loading ? 'rgb(91, 115, 207)' : ''}
                                disabled={loading}
                                onClick={addProductToTable}
                                height={'50px'}
                            >
                                {loading ? <img src={loadingImg} width="36" height="36" /> : 'Save'}
                            </StyledButton>
                        </ButtonContainer>
                    </ButtonsBlock>
                </Content>
            </MainContainer>
        </Layout>
    );
};

const TittleLevel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    width: 100%;
`;

const CancelButton = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: #f8c882;
    border: 1px solid #f8c882;
    box-sizing: border-box;
    border-radius: 6px;
`;

const ButtonsBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 100px;
    width: 500px;
    margin-left: auto;
`;

const Blocks = styled.div`
    display: flex;
    gap: 60px;
    flex-direction: column;
`;

const Content = styled.div`
    margin-top: 50px;
    padding-bottom: 50px;
    font-family: 'Source Sans Pro';
`;

const BackBlock = styled.div`
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 136%;
    margin-bottom: 50px;
`;

export default CreateProduct;
