import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import StaticPages from '@/pages/staticPages';
import { useUserActions } from '@hooks/useActions';
import { useTypedSelector } from '@hooks/useSelector';
import Products from '@/pages/products';
import CreateProduct from '@/pages/createProduct';
import Categories from '@/pages/categories';
import SignIn from '@/pages/SignIn';
import Loading from '@/components/loading';
import OrdersPage from '@/pages/orders';
import UsersPage from '@/pages/users';
import SuperAdminPage from '@/pages/superAdmin'

const Router = () => {
    const token = localStorage.getItem('firebaseToken');
    const { getUser, initialize, refreshToken } = useUserActions();
    const { isInitialize, user } = useTypedSelector((state) => state.userReducer);

    useEffect(() => {
        if (token) {
            getUser();
        } else {
            initialize();
        }
    }, []);

    /*    useEffect(() => {
            setIsInitialized(false);
            //get user data
        }, [dispatch])*/

    /*
        useEffect(() => {
            setIsInitialized(true)
        }, [user])
    */

    if (!isInitialize) {
        return <Loading />;
    }
    return (
        <Routes>
            {!user ? (
                <Route path={'*'} element={<SignIn />} />
            ) : (
                <>
                    <Route path={'*'} element={<Navigate to="/" />} />
                    <Route path={'/'} element={<Products />} />
                    <Route path={'/products/create-new'} element={<CreateProduct />} />
                    <Route path={'/products/update/:id'} element={<CreateProduct />} />
                    <Route path={'/categories'} element={<Categories />} />
                    <Route path={'/static-pages'} element={<StaticPages />} />
                    <Route path={'/orders'} element={<OrdersPage />} />
                    <Route path={'/users'} element={<UsersPage />} />
                    <Route path={'/super-admin'} element={<SuperAdminPage />} />

                </>
            )}
        </Routes>
    );
};

export default Router;
