import React, { useEffect, useState } from 'react';
import AccordionElement from '@ui/AccordionElement';
import styled from 'styled-components';
import StyledButton, { Button } from '@ui/StyledButton';
import { useTypedSelector } from '@hooks/useSelector';
import TextFields from '@ui/TextFields';
import { useOrdersActions } from '@hooks/useActions';
import { MainContainer } from '@ui/mainContainer';
import Layout from '@components/layout';
import OrderList from '@/components/orders/OrderList';
import { adminAPI } from '@/api/admin/adminApi';

const SuperAdminPage = () => {
    const [fetching, setFetching] = useState(false);

    async function createQbItemsForAllProducts() {
        setFetching(true);
        const res = await adminAPI.createQbItemForAllProducts();
        setFetching(false);
    }

    return (
        <Layout>
            <MainContainer>
                <Content>
                    <Title>Super admin page</Title>
                    <ButtonContainer>
                        <Button disabled={fetching} onClick={createQbItemsForAllProducts}>
                            Create Qb Item For All Products
                        </Button>
                    </ButtonContainer>
                </Content>
            </MainContainer>
        </Layout>
    );
};

const FieldContainer = styled.div`
    width: 100%;
`;

const ButtonContainer = styled.div`
    margin-right: 10px;
    width: 200px;
`;
const PageNameContainer = styled.div`
    width: 200px;
    padding-left: 10px;
`;

const AddPageContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
`;

const Title = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 136%;
    margin-bottom: 50px;
`;

const Content = styled.div`
    margin-top: 20px;
`;

const ItemTr = styled.tr`
    display: flex;
    align-items: center;
    gap: 24px;
    border-right: 1px solid #e7e7ed;
    border-left: 1px solid #e7e7ed;
    box-sizing: border-box;
    padding: 20px 0 0 16px;
`;

const FirstColumn = styled.tr`
    display: flex;
    gap: 24px;
    background: #ffffff;
    border: 1px solid #e7e7ed;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    padding: 12px 60px 12px 16px;
    margin-bottom: -1.9px;
`;

const Table = styled.table`
    margin-top: 30px;
    width: 497px;
    border-bottom: 1px solid #e7e7ed;
`;

const FieldWithCheckBox = styled.div`
    display: flex;
    align-items: center;
    gap: 28px;
    width: 230px;
`;

export default SuperAdminPage;
