import React, {useState} from 'react';
import styled from "styled-components";

type TextFieldsProps = {
    onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    padding?: string,
    placeholder?: string,
    type: string,
    textError?: string
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    height: string
    required?: boolean
}

const TextFields = (props: TextFieldsProps) => {
    const [req, isReq] = useState(false)
    return (
        <Wrapper>
            {
                props.textError === "" || props.textError === undefined ?
                    <Input
                          onKeyDown={(e) => {
                            if (props.onKeyDown) {
                                props.onKeyDown(e)
                            }
                        }
                        }
                        id={props.placeholder} disabled={props.disabled} height={props.height}
                        style={{padding: props.padding}} placeholder={""} type={props.type} value={props.value}
                        onClick={(e) => {
                            if (props.onClick) {
                                props.onClick(e as any)
                            }
                        }}
                        required={(req) === props.required}
                        aria-invalid={true} onBlur={(e) => {
                        isReq(e.target.value.length === 0)
                    }}
                        onChange={(e) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                    /> : <Input id={props.placeholder} disabled={props.disabled} height={props.height}
                                style={{color: "#D92E23", borderColor: "#D92E23", padding: props.padding}}
                                placeholder={""} type={props.type} value={props.value}
                                onClick={(e) => {
                                    if (props.onClick) {
                                        props.onClick(e as any)
                                    }
                                }}
                                required={(props.value?.length === 0) === props.required}
                                aria-invalid={true} onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    />
            }

            {props.textError === "" || !props.textError ?
                !(props.value?.length === 0) ?
                    <Input_active height={props.height} htmlFor={props.placeholder}> {props.placeholder} </Input_active>
                    : <Label height={props.height} htmlFor={props.placeholder}>{props.placeholder}</Label> :
                !(props.value?.length === 0) ?
                    <Input_active style={{color: "#D92E23"}} height={props.height}
                                  htmlFor={props.placeholder}> {props.placeholder} </Input_active>
                    : <Label style={{color: "#D92E23"}} height={props.height}
                             htmlFor={props.placeholder}>{props.placeholder}</Label>
            }

            <div
                style={{color: "#D92E23", paddingLeft: 10, fontSize: 14, position: "absolute"}}> {props.textError}</div>


        </Wrapper>

    );
};
const Input = styled.input<{ height: string }>`

  font-family: 'Source Sans Pro';
  height: ${props => props.height};
  width: 100%;
  font-weight: 400;
  border-radius: 4px;
  cursor: text;
  transition: border 0.15s ease-in-out 0s, color 0.15s ease-in-out 0s;
  border: #B6B6B6 solid 1px;
  position: relative;
  line-height: 16px;
  font-size: 16px;
  padding-left: 10px;
  box-sizing: border-box;

  &:focus-visible {
    outline: none;
    border: #394985 solid 1px;
    color: #394985;
  }

  &:focus-within + label {
    transform: scale(0.75) translateY(calc(${props => props.height} / -1 + 0.3 * ${props => props.height}));
    color: #394985;
  }

  &:focus + label {
    transform: scale(0.75) translateY(calc(${props => props.height} / -1 + 0.3 * ${props => props.height}));
    color: #394985;
  }

  &:invalid {
    border-color: #D92E23;
    color: #D92E23;
  }

  &:invalid + label {
    color: #D92E23;
  }

`
const Label = styled.label <{ height: string }>`
  font-family: Inter, serif;
  position: absolute;
  transition: transform 150ms cubic-bezier(0, 0, 0.2, 1) 150ms, background-color 150ms cubic-bezier(0, 0, 0.2, 1) 150ms;
  color: #B6B6B6;
  background-color: #FFF;
  font-size: 16px;
  pointer-events: none;
  left: 10px;
  top: calc((${props => props.height} / 2) - 7px);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 5px;
  
`
const Input_active = styled.label<{ height: string }>`
  transform: scale(0.75) translateY(calc(${props => props.height} / -1 + 0.3 * ${props => props.height}));
  transition: transform 0.15s;
  position: absolute;
  color: #000;
  background-color: #FFF;
  top: calc((${props => props.height} / 2) - 7px);
  left: 10px;
  font-style: normal;
  line-height: 16px;
  font-weight: 400;

`
export default TextFields;
