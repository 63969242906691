import { User } from '@/api/users/usersTypes';
import React, { useRef } from 'react';
import styled from 'styled-components';
import OptionsIMG from '@assets/svgs/admin_options_img.svg';
import BanIMG from '@assets/svgs/ban.svg';
import EditIMG from '@assets/svgs/product_edit_img.svg';
import UserIMG from '@assets/svgs/user-icon.svg';
import config from '../../config';
import Tooltip from '@/ui/Tooltip';
import { useClickAway } from '@/hooks/useClickAway';
import { useSingleClickAway } from '@/hooks/useSingleClickAway';

interface IUserCard {
    user: User;
    setShowActions: (userId: string) => void;
    showActions: boolean;
    setShowEditWarning: (value: boolean) => void;
    setShowBanWarning: (value: boolean) => void;
}

const UserCard = (props: IUserCard) => {
    const { user, setShowActions, showActions, setShowBanWarning, setShowEditWarning } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);

    useClickAway(
        wrapperRef,
        () => {
            setShowActions(user.firebaseId);
        },
        triggerRef
    );

    const onBanHandler = (id: string) => {
        setShowBanWarning(true);
    };

    const onEditHandler = (id: string) => {
        setShowEditWarning(true);
    };

    const onUserSignInHandler = (id: string) => {};

    return (
        <CardBlock>
            <HeaderWrapper>
                <UserNameWrapper>
                    <UserCardFieldInfoWrapper>
                        <UserCardFieldInfoTitle>First name:</UserCardFieldInfoTitle>
                        <UserCardFieldInfo>{user.first_name}</UserCardFieldInfo>
                    </UserCardFieldInfoWrapper>
                    <UserCardFieldInfoWrapper>
                        <UserCardFieldInfoTitle>Last name:</UserCardFieldInfoTitle>
                        <UserCardFieldInfo>{user.surname}</UserCardFieldInfo>
                    </UserCardFieldInfoWrapper>
                </UserNameWrapper>
                <Options>
                    <img
                        style={{ cursor: 'pointer' }}
                        width="30"
                        height="30"
                        src={OptionsIMG}
                        alt="Options"
                        onClick={(e) => {
                            setShowActions(user.firebaseId);

                            // const triggerRef = useRef<HTMLDivElement>(null);
                            // @ts-ignore
                            triggerRef.current = e.target;
                        }}
                    />
                    {showActions && (
                        <ActionsBlock ref={wrapperRef}>
                            <Tooltip text="Ban/unban user">
                                <img
                                    onClick={() => onBanHandler(user.firebaseId)}
                                    style={{ cursor: 'pointer' }}
                                    draggable={false}
                                    src={BanIMG}
                                    height="24"
                                    width="24"
                                    alt={'ban'}
                                />
                            </Tooltip>
                            <Tooltip text="Change role">
                                <img
                                    onClick={() => onEditHandler(user.firebaseId)}
                                    style={{ cursor: 'pointer' }}
                                    draggable={false}
                                    src={EditIMG}
                                    height="24"
                                    width="24"
                                    alt={'edit'}
                                />
                            </Tooltip>

                            <Tooltip text="Sign in as user">
                                <a href={`${config.REACT_APP_DASHBOARD_URL}/behalf/${user.firebaseId}`} target="_blank">
                                    <img
                                        onClick={() => onUserSignInHandler(user.firebaseId)}
                                        style={{ cursor: 'pointer' }}
                                        draggable={false}
                                        src={UserIMG}
                                        height="24"
                                        width="24"
                                        alt={'edit'}
                                    />
                                </a>
                            </Tooltip>
                        </ActionsBlock>
                    )}
                </Options>
            </HeaderWrapper>
            <UserCardFields>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Role:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.role}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Email:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.email}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Phone:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.phone}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Delivery address:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.delivery_address}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Res. address:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.residential_address}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Firebase ID:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.firebaseId}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
                <UserCardFieldInfoWrapper>
                    <UserCardFieldInfoTitle>Banned:</UserCardFieldInfoTitle>
                    <UserCardFieldInfo>{user.isBanned === true ? 'Yes' : 'No'}</UserCardFieldInfo>
                </UserCardFieldInfoWrapper>
            </UserCardFields>
        </CardBlock>
    );
};

const UserInfoCardsWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
`;

const CardBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    min-height: 246px;
    width: 100%;
    max-width: 360px;
    padding: 17px 20px;
    height: auto;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 0 0 6px 6px;
    @media (max-width: 600px) {
        padding: 8px;
        min-height: auto;
    }
`;

const UserCardFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const UserCardFieldInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
`;

const UserCardFieldInfoTitle = styled.div`
    font-weight: 700;
    width: 30%;
`;
const UserCardFieldInfo = styled.div`
    width: 70%;
    text-align: left;
`;

const HeaderWrapper = styled.div`
    background: #9fabd9;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px 6px 0 0;
    color: white;
    height: 50px;
    padding: 17px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: 600px) {
        height: auto;
        padding: 8px;
        align-items: center;
    }
`;

const UserNameWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const Options = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const ActionsBlock = styled.div`
    position: absolute;
    margin-top: 5px;
    left: -60px;
    bottom: -60px;
    width: 160px;
    height: 52px;
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
`;

export default UserCard;
