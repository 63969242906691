const MAX_FIXED_FIELD = 10000.0;
const MAX_PERCENT_FIELD = 100.0;
const MAX_PURITY = 1.0;
const MIN_FIELD = 0.0;
const MIN_PERCENT = 1.0;
const MAX_NUMBER = Number.MAX_SAFE_INTEGER;

const MIN_LEN_TEXT_FIELD = 6;
const MAX_LEN_TEXT_FIELD = 63;

export enum FIELD_CHECK_TYPE {
    percentField = 'percentField',
    fixedField = 'fixedField',
    textField = 'textField',
    purity = 'purity',
    amount = 'amount',
    amountLimit = 'amountLimit',
    priceField = 'priceField',
}

export const validateProduct = (value: string, fieldName: string, type: FIELD_CHECK_TYPE) => {
    switch (type) {
        case FIELD_CHECK_TYPE.fixedField: {
            const normalValue = Number(value);
            if (value.length > 0) {
                if (normalValue >= MIN_FIELD && normalValue <= MAX_FIXED_FIELD) {
                    return '';
                } else {
                    return `“The field ${fieldName}” value mast be more then ${MIN_FIELD} and less then ${MAX_FIXED_FIELD}`;
                }
            } else {
                return `Please fill in the field “${fieldName}”`;
            }
        }
        case FIELD_CHECK_TYPE.priceField: {
            const normalValue = Number(value);
            if (value.length > 0) {
                if (normalValue >= MIN_FIELD && normalValue <= MAX_NUMBER) {
                    return '';
                } else {
                    return `“The field ${fieldName}” value mast be more then ${MIN_FIELD} and less then ${MAX_NUMBER}`;
                }
            } else {
                return `Please fill in the field “${fieldName}”`;
            }
        }
        case FIELD_CHECK_TYPE.percentField: {
            const normalValue = Number(value);
            if (value.length > 0) {
                if (normalValue >= MIN_FIELD) {
                    return '';
                } else {
                    return `“The field ${fieldName}” value mast be more then ${MIN_FIELD}`;
                }
            } else {
                return `Please fill in the field “${fieldName}”`;
            }
        }
        case FIELD_CHECK_TYPE.purity: {
            const normalValue = Number(value);
            if (value.length > 0) {
                if (normalValue >= MIN_FIELD && normalValue <= MAX_PURITY) {
                    return '';
                } else {
                    return `The field “${fieldName}” value mast be more then ${MIN_FIELD} and less then ${MAX_PURITY}`;
                }
            } else {
                return `Please fill in the field “${fieldName}”`;
            }
        }
        case FIELD_CHECK_TYPE.textField: {
            if (value !== '' && value !== undefined) {
                let productNameValidation = value.match(new RegExp(/^[a-z0-9“! .#$%&'*+—/=?\-^_`{|}~”]{6,63}$/i));
                if (value.length < MIN_LEN_TEXT_FIELD || value.length > MAX_LEN_TEXT_FIELD) {
                    return `The field “${fieldName}” length mast be more then ${MIN_LEN_TEXT_FIELD} and less then ${MAX_LEN_TEXT_FIELD}`;
                }
                return productNameValidation === null ? `The field “${fieldName}” is filled in incorrectly` : '';
            }
            return `Please fill in the field “${fieldName}”`;
        }
        case FIELD_CHECK_TYPE.amount: {
            const normalValue = Number(value);
            if (value.length > 0) {
                if (normalValue >= 0) {
                    return '';
                } else {
                    return `“The field ${fieldName}” is filled in incorrectly`;
                }
            } else {
                return `Please fill in the field “${fieldName}”`;
            }
        }
        case FIELD_CHECK_TYPE.amountLimit: {
            const normalValue = Number(value);
            if (value.length > 0) {
                if (normalValue >= 0) {
                    return '';
                } else {
                    return 'Stock in Shop must be less or equal than Stock in Hand';
                }
            } else {
                return `Please fill in the field “${fieldName}”`;
            }
        }
        default: {
            return '';
        }
    }
};
