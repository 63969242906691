import React, { useState } from 'react';
import TextFields from '@ui/TextFields';
import { IMetalParams } from '@/types';
import styled from 'styled-components';
import { IBaseFieldMetalParam } from '@components/products/MetalParameters';
import { IUpdateMainPartProduct } from '@store/types/productTypes';
import { IUpdateMainData, IUpdateProduct } from '@api/product/productType';

interface IMetalParamField {
    params: IMetalParams;
    item: IBaseFieldMetalParam;
    setEmptyError: () => void;
    setData: (value: IUpdateMainData) => void;
    value: string | undefined;
    error: string;
    weightType: string;
    isDisabled: boolean;
}

const Index: React.FC<IMetalParamField> = ({ value, error, item, setData, setEmptyError, params, weightType, isDisabled }) => {
    return (
        <TextFieldContainer>
            <TextFields
                /*            onClick={(e) => {
                if (isFirstInput) {
                    setData({[item.id]: undefined})
                }
                /!*                if (item.id === 'spot_margin_fixed' && (Number(e.target.value) === 0)) {
                                    setData({spot_margin_fixed: undefined})
                                }
                                if (item.id === 'margin_fixed' && (Number(e.target.value) === 0)) {
                                    setData({margin_fixed: undefined})
                                }
                                if (item.id === 'margin_percent' && (Number(e.target.value) === 0)) {
                                    setData({margin_percent: undefined})
                                }*!/
            }}*/
                disabled={item.id !== "Product Name" && isDisabled}
                onChange={(e) => {
                    setEmptyError();
                    //setIsFirstInput(false)
                    const currentValue = e.target.value;
                    if (item.inputMask(currentValue)) return;
                    let obj: IUpdateMainData = {
                        isCollectable: false,
                    };
                    if (item.id === 'aw') {
                        /*                    if (params.purity) {
                                            obj.weight = Number(currentValue) / params.purity
                                        }*/
                        if (params.weight) {
                            obj.purity = Number(currentValue) / params.weight;
                        }
                    }
                    if (item.id === 'purity') {
                        /*                    if (params.aw) {
                                            obj.weight = Number(currentValue) * params.aw
                                        }*/
                        if (params.weight) {
                            let aw;
                            if (Number(currentValue) > 0.95) {
                                aw = params.weight * Math.ceil(Number(currentValue));
                            } else {
                                aw = params.weight * Number(currentValue);
                            }
                            obj.aw = aw;
                        }
                    }
                    if (item.id === 'weight') {
                        /*if (params.aw) {
                                            obj.purity = Number(currentValue) / params.aw
                                        }*/
                        if (params.purity) {
                            obj.aw = Number(currentValue) * params.purity;
                        }
                    }
                    // @ts-ignore
                    obj[item.id] = currentValue;
                    setData(obj);
                }}
                placeholder={item.placeholder === 'Weight' ? `${item.placeholder}(${weightType})` : item.placeholder}
                value={value ? value : ''}
                type={'text'}
                height={'56px'}
                textError={error}
            />
        </TextFieldContainer>
    );
};

const TextFieldContainer = styled.div`
    max-width: 330px;
    width: 100%;
`;

export default Index;
