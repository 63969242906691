import {adminAction} from "@store/types/adminTypes";
import {AdminActionTypes} from "@store/constants/adminConstants";
import {IContent, IResponsePage} from "@api/admin/adminType";

interface IAdminState {
    error: string | undefined
    loading: boolean
    message: string | undefined
    pages: IResponsePage[]
    currentPage: IResponsePage | undefined
}

const initialState: IAdminState = {
    error: undefined,
    loading: false,
    message: undefined,
    pages: [],
    currentPage: undefined
}

const startLoading = (state: IAdminState) => {
    return {...state, loading: true, error: undefined}
}

const stopErrorLoading = (state: IAdminState, error: string) => {
    return {...state, loading: false, error: error}
}

const adminReducer = (state = initialState, action: adminAction): IAdminState => {
    switch (action.type) {
        case AdminActionTypes.START_ADMIN_ACTION:
            return startLoading(state)
        case AdminActionTypes.ERROR_ADMIN_ACTION:
            return stopErrorLoading(state, action.payload)

        case AdminActionTypes.CLEAR_STATE_ADMIN:
            return {
                error: undefined,
                loading: false,
                message: undefined,
                pages: [],
                currentPage: undefined,
            }

        case AdminActionTypes.GET_ALL_PAGES:
            return {
                ...state,
                pages: action.payload,
                loading: false,
            }

        case AdminActionTypes.CREATE_PAGE:
            return {
                ...state,
                pages: [...state.pages, action.payload],
                loading: false
            }

        case AdminActionTypes.DELETE_PAGE:
            return {
                ...state,
                pages: [...state.pages.filter(item => item.page !== action.payload.name)]
            }
        case AdminActionTypes.CREATE_TEXT_FIELD:
        case AdminActionTypes.DELETE_TEXT_FIELD:
        case AdminActionTypes.UPDATE_TEXT_FIELD:
            let changedField = state.pages.find(item => item.page === action.payload.page)
            if (changedField) {
                changedField.content = action.payload.content
            }
            const newMass = [...state.pages]
            return {
                ...state,
                pages: newMass,
                currentPage: action.payload,
                loading: false
            }

        default:
            return state;
    }
}


export default adminReducer;