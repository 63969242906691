export const calcBuyPrice = (metalPrice: number, spotPercent: number | undefined, spotFixed: number | undefined, aw: number | undefined) => {
    if (!spotPercent) spotPercent = 100;
    if (!spotFixed) spotFixed = 0;
    if (!aw) aw = 0;
    aw = aw / 31.12;
    spotPercent = spotPercent / 100;
    return (Number(metalPrice) * Number(spotPercent) + Number(spotFixed)) * Number(aw);
};

export const calcSellPrice = (
    metalPrice: number,
    spotPercent: number | undefined,
    spotFixed: number | undefined,
    aw: number | undefined,
    marginPercent: number | undefined,
    marginFixed: number | undefined,
    useGST: boolean | undefined
) => {
    if (!spotPercent) spotPercent = 0;
    if (!spotFixed) spotFixed = 0;
    if (!aw) aw = 0;
    if (!marginPercent) marginPercent = 0;
    if (!marginFixed) marginFixed = 0;
    aw = aw / 31.12;
    spotPercent = spotPercent / 100;
    marginPercent = marginPercent / 100 + 1;
    return (
        ((Number(metalPrice) * Number(spotPercent) + Number(spotFixed)) * Number(aw) * Number(marginPercent) + Number(marginFixed)) /
        (useGST ? 1.1 : 1)
    );
    // return (Number(metalPrice) * Number(spotPercent) + Number(spotFixed)) * Number(aw) * Number(marginPercent) + Number(marginFixed);
};

export const calcSellPriceDisplay = (
    metalPrice: number,
    spotPercent: number | undefined,
    spotFixed: number | undefined,
    aw: number | undefined,
    marginPercent: number | undefined,
    marginFixed: number | undefined,
    useGST: boolean | undefined
) => {
    if (!spotPercent) spotPercent = 0;
    if (!spotFixed) spotFixed = 0;
    if (!aw) aw = 0;
    if (!marginPercent) marginPercent = 0;
    if (!marginFixed) marginFixed = 0;
    aw = aw / 31.12;
    spotPercent = spotPercent / 100;
    marginPercent = marginPercent / 100 + 1;
    return (
        ((Number(metalPrice) * Number(spotPercent) + Number(spotFixed)) * Number(aw) * Number(marginPercent) + Number(marginFixed)) /* *
        (useGST ? 1.1 : 1) */
    );
};
