import React, { useEffect, useState } from 'react';
import Router from './routes';
import SnakeBar from '@ui/components/modals/SnakeBar';
import { useTypedSelector } from '@hooks/useSelector';
import { useDispatch } from 'react-redux';
import { AuthProvider } from './services/firebase-service';
import { getUser, logout } from './store/actions/userActions';

const ReduxAuthProvider = ({ children }: { children: any }) => {
    const dispatch = useDispatch();
    return (
        <AuthProvider onLogin={() => dispatch(getUser())} onLogout={() => dispatch(logout())}>
            {children}
        </AuthProvider>
    );
};

const App = () => {
    const { type, content, ms } = useTypedSelector((state) => state.snackbarReducer);

    return (
        <ReduxAuthProvider>
            <React.Fragment>
                <SnakeBar type={type} content={content} timeMS={ms} />
                <Router />
                <div id="sign-in"></div>
            </React.Fragment>
        </ReduxAuthProvider>
    );
};

export default App;
