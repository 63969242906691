import { ILivePriceData, IMainData, IResponseShop, IShopData, IUpdateMainData } from '@api/product/productType';

export const formChangedLiveObject = (oldData: ILivePriceData | undefined, newData: ILivePriceData | undefined): ILivePriceData | undefined => {
    let isChanged = false;
    if (!oldData && !newData) {
        return undefined;
    }
    if (!oldData) {
        if (newData) {
            if (!newData.category_id) return undefined;
            return {
                ...newData,
                buy_price: Number(newData.buy_price),
                sell_price: Number(newData.sell_price),
            };
        }
        return undefined;
    }
    if (!newData) {
        return undefined;
    }

    let obj: ILivePriceData = {
        category_id: oldData.category_id,
    };
    if (oldData.category_id !== newData.category_id) {
        obj.category_id = newData.category_id;
        isChanged = true;
    }
    if (oldData.is_visible !== newData.is_visible) {
        obj.is_visible = newData.is_visible;
        isChanged = true;
    }
    if (oldData.is_buy_display !== newData.is_buy_display) {
        obj.is_buy_display = newData.is_buy_display;
        isChanged = true;
    }
    if (oldData.is_sell_display !== newData.is_sell_display) {
        obj.is_sell_display = newData.is_sell_display;
        isChanged = true;
    }

    if (oldData.is_fixed_price !== newData.is_fixed_price) {
        isChanged = true;
        if (newData.is_fixed_price) {
            obj.buy_price = Number(newData.buy_price);
            obj.sell_price = Number(newData.sell_price);
            obj.is_fixed_price = true;
        } else {
            obj.is_fixed_price = false;
        }
    }

    if (oldData.sell_price !== newData.sell_price) {
        isChanged = true;
        obj.sell_price = Number(newData.sell_price);
    }

    if (oldData.buy_price !== newData.buy_price) {
        isChanged = true;
        obj.buy_price = Number(newData.buy_price);
    }

    return isChanged ? obj : undefined;
};

export const formChangedShopObject = (oldData: IResponseShop | undefined, newData: IShopData | undefined): IShopData | undefined => {
    let isChanged = false;
    if (!oldData && !newData) {
        return undefined;
    }
    if (!oldData) {
        return {
            ...newData,
            exp_date: new Date().toISOString(),
            category_id: newData?.category_id ? newData?.category_id : '',
            price: newData?.price ? Number(newData.price) : undefined,
        };
    }
    if (!newData) {
        return undefined;
    }
    let obj: IShopData = {
        category_id: oldData.category_id,
        exp_date: oldData.exp_date,
    };
    if (oldData.category_id !== newData.category_id) {
        obj.category_id = newData.category_id;
        isChanged = true;
    }
    if (oldData.is_visible !== newData.is_visible) {
        obj.is_visible = newData.is_visible;
        isChanged = true;
    }
    if (oldData.medias !== newData.medias) {
        obj.medias = newData.medias;
        isChanged = true;
    }
    if (oldData.description !== newData.description) {
        obj.description = newData.description;
        isChanged = true;
    }
    if (oldData.additional_info !== newData.additional_info) {
        obj.additional_info = newData.additional_info;
        isChanged = true;
    }
    if (oldData.amount_limit !== newData.amount_limit) {
        obj.amount_limit = newData.amount_limit;
        isChanged = true;
    }
    if (oldData.amount_all !== newData.amount_all) {
        obj.amount_all = newData.amount_all;
        obj.amount_now = newData.amount_all;
        isChanged = true;
    }

    if (oldData.is_fixed_price !== newData.is_fixed_price) {
        isChanged = true;
        if (newData.is_fixed_price) {
            obj.price = Number(newData.price);
            obj.is_fixed_price = true;
        } else {
            obj.is_fixed_price = false;
        }
    }

    if (oldData.price !== newData.price) {
        isChanged = true;
        obj.price = Number(newData.price);
    }

    return isChanged ? obj : undefined;
};

export const formChangedProductObject = (oldData: IMainData | undefined, newData: IUpdateMainData | undefined): IUpdateMainData | undefined => {
    let isChanged = false;
    if (!oldData && !newData) {
        return undefined;
    }
    if (!oldData) {
        return newData;
    }
    if (!newData) {
        return undefined;
    }
    let obj: IUpdateMainData = {
        isCollectable: oldData.isCollectable,
    };

    if (oldData.isCollectable !== newData.isCollectable) {
        obj.isCollectable = newData.isCollectable;
        isChanged = true;
    }

    if (oldData.metal_id !== newData.metal_id) {
        obj.metal_id = newData.metal_id;
        isChanged = true;
    }
    if (oldData.name !== newData.name) {
        obj.name = newData.name;
        isChanged = true;
    }
    if (oldData.spot_margin_fixed !== newData.spot_margin_fixed) {
        obj.spot_margin_fixed = Number(newData.spot_margin_fixed);
        isChanged = true;
    }
    if (oldData.spot_margin_percent !== newData.spot_margin_percent) {
        obj.spot_margin_percent = Number(newData.spot_margin_percent);
        isChanged = true;
    }
    if (oldData.weight !== newData.weight) {
        obj.weight = Number(newData.weight);
        isChanged = true;
    }
    if (oldData.purity !== newData.purity) {
        obj.purity = Number(newData.purity);
        isChanged = true;
    }
    if (oldData.aw !== newData.aw) {
        obj.aw = Number(newData.aw);
        isChanged = true;
    }
    if (oldData.margin_fixed !== newData.margin_fixed) {
        obj.margin_fixed = Number(newData.margin_fixed);
        isChanged = true;
    }
    if (oldData.margin_percent !== newData.margin_percent) {
        obj.margin_percent = Number(newData.margin_percent);
        isChanged = true;
    }
    if (oldData.gst !== newData.gst) {
        obj.gst = newData.gst;
        isChanged = true;
    }

    return isChanged ? obj : undefined;
};
