import React, {useEffect, useState} from 'react'
import Header from "../components/header/index";
import {ContainerFluid} from "@ui/mainContainer"
import MobileMenu from "@components/mobileMenu/index"
import styled from "styled-components";

type LayoutProps = {
    children: React.ReactNode,
}
const Layout = (props: LayoutProps) => {
    const [open, setOpen] = useState(false)

    const [show, setShow] = useState({
        height: "0",
        display: "none",
        width: "0"
    })
    const anchorLink = async (id: string) => {
        await setOpen(false);
    }
    useEffect(() => {
        if (open) {
            setShow({height: "calc(88vh)", display: "block", width: "100%"})
        } else {
            setShow({display: "none", height: "0", width: "0"})
        }
    }, [open])

    return (
        <>
            <ContainerFluid>
                <Header open={open} setOpen={setOpen} show={show} setShow={setShow}/>
            </ContainerFluid>
            {
                open && (window.innerWidth <= 600) ?
                    <main>
                        <MobileMenuWrapper>
                            <MobileMenu anchorLink={anchorLink}
                                        display={show.display}
                                        height={show.height}
                                        width={show.width}/>
                        </MobileMenuWrapper>
                    </main>
                    : <main>
                        {props.children}
                    </main>
            }
        </>
    );

};
const MobileMenuWrapper = styled.div`

`
export default Layout;
