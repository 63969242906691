import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CheckboxComponent from '@ui/CheckboxComponent';
import { fixedCheck, percentCheck, purityCheck } from '@/helpers/checkersInput';
import { IErrorMain, IMetalParams } from '@/types';
import MetalParamField from '@components/products/metalParamField';
import { getMetalPrices } from '@store/actions/productActions';
import useSocketDispatchUpdatePrice from '@hooks/useSocketDispatch';

export interface IMetalParamsComponent {
    weightType: string;
    params: IMetalParams;
    errorState: IErrorMain;
    setData: (value: IMetalParams) => void;
    isDisabled: boolean;
}

interface localParams extends Omit<IMetalParams, 'gst'> {}

export interface IBaseFieldMetalParam {
    id: string;
    placeholder: string;
    inputMask: (value: string) => boolean;
}

const FIELDS_ROW_ONE: IBaseFieldMetalParam[] = [
    {
        id: 'spot_margin_fixed',
        placeholder: 'Spot Margin, +',
        inputMask: fixedCheck,
    },
    {
        id: 'spot_margin_percent',
        placeholder: 'Spot Margin, %',
        inputMask: percentCheck,
    },
    {
        id: 'weight',
        placeholder: 'Weight',
        inputMask: fixedCheck,
    },
    {
        id: 'purity',
        placeholder: 'Purity',
        inputMask: purityCheck,
    },
];

const FIELDS_ROW_TWO: IBaseFieldMetalParam[] = [
    {
        id: 'aw',
        placeholder: 'AW',
        inputMask: fixedCheck,
    },
    {
        id: 'margin_fixed',
        placeholder: 'Margin, +',
        inputMask: fixedCheck,
    },
    {
        id: 'margin_percent',
        placeholder: 'Margin, %',
        inputMask: percentCheck,
    },
];

const MetalParameters: React.FC<IMetalParamsComponent> = ({ weightType, params, errorState, setData, isDisabled }) => {
    const dispatch = useSocketDispatchUpdatePrice();
    const [error, setError] = useState(errorState);

    useEffect(() => {
        dispatch({ getPrice: () => getMetalPrices() });
    }, []);

    useEffect(() => {
        setError(errorState);
    }, [errorState]);

    const renderFirstFields = useMemo(
        () =>
            FIELDS_ROW_ONE.map((item, index) => {
                let value = params[item.id as keyof localParams]?.toString();

                return (
                    <MetalParamField
                        isDisabled={isDisabled && item.id !== 'weight' && item.id !== 'purity'}
                        key={`MetalParamField${index}`}
                        params={params}
                        value={value}
                        error={error[item.id as keyof IErrorMain]}
                        item={item}
                        setEmptyError={() => {
                            if (item.id === 'weight') {
                                setError({
                                    ...error,
                                    [item.id]: '',
                                    aw: '',
                                });
                                return;
                            }
                            if (item.id === 'purity') {
                                setError({
                                    ...error,
                                    [item.id]: '',
                                    aw: '',
                                });
                            }
                            setError({ ...error, [item.id as keyof IErrorMain]: '' });
                        }}
                        setData={(value) => setData({ ...params, ...value })}
                        weightType={weightType}
                    />
                );
            }),
        [params, error, weightType]
    );

    const renderSecondFields = useMemo(
        () =>
            FIELDS_ROW_TWO.map((item, index) => {
                let value = params[item.id as keyof localParams]?.toString();

                return (
                    <MetalParamField
                        isDisabled={isDisabled && item.id !== 'weight' && item.id !== 'purity'}
                        key={`MetalParamField${index}`}
                        params={params}
                        value={value}
                        error={error[item.id as keyof IErrorMain]}
                        item={item}
                        setEmptyError={() => {
                            if (item.id === 'weight') {
                                setError({
                                    ...error,
                                    [item.id]: '',
                                    aw: '',
                                });
                                return;
                            }
                            if (item.id === 'purity') {
                                setError({
                                    ...error,
                                    [item.id]: '',
                                    aw: '',
                                });
                            }
                            setError({ ...error, [item.id as keyof IErrorMain]: '' });
                        }}
                        setData={(value) => setData({ ...params, ...value })}
                        weightType={weightType}
                    />
                );
            }),
        [params, error, weightType]
    );

    return (
        <MainContainer>
            <div>
                <TittleLabel>Buy price</TittleLabel>
                <Container>{renderFirstFields}</Container>
            </div>
            <div>
                <TittleLabel>Sell price</TittleLabel>
                <Container>
                    {renderSecondFields}
                    <CheckContainer>
                        <CheckboxComponent
                            value={params.gst}
                            onClick={() => setData({ ...params, gst: !params.gst })}
                            width={'18px'}
                            height={'18px'}
                        />
                        <span onClick={() => setData({ ...params, gst: !params.gst })} style={{ marginBottom: 1, cursor: 'pointer' }}>
                            GST
                        </span>
                    </CheckContainer>
                </Container>
            </div>
        </MainContainer>
    );
};

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 28px;
`;

const TittleLabel = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 15px;
`;

const CheckContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const TextFieldContainer = styled.div`
    max-width: 330px;
    width: 100%;
`;

const Container = styled.div`
    display: grid;
    gap: 40px 40px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export default MetalParameters;
