import React, {useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from "react-dom";
import styled from "styled-components";
import StyledCloseButton from "@ui/StyledCloseButton";
import ChangeDataSuccess from './ChangeDataSuccess';
import {useDispatch} from 'react-redux';
import {hideSnackbar} from '@/store/actions/snackbarActions';
import {Transition, TransitionStatus} from 'react-transition-group';

const modalRootElement = document.getElementById("modal");

export enum SnakeTypes {
    success = "success",
    warning = "warning",
    error = "error"
}

interface ISnakeBarProps {
    type: SnakeTypes | undefined
    content: string | undefined
    timeMS: number
}

const TRANSITION_MS = 200;

const SnakeBar: React.FC<ISnakeBarProps> = ({content, type = SnakeTypes.success, timeMS = 2000}) => {
    const element = useMemo(() => document.createElement("div"), []);
    const timeoutRef = useRef<any>(null);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()

    const closeModal = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = null
            setShow(false)
            setTimeout(() => dispatch(hideSnackbar()), TRANSITION_MS)
        } else {
            dispatch(hideSnackbar())
        }
    }

    useEffect(() => {
        if (content) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
                timeoutRef.current = null
            }
            setShow(true)
            modalRootElement?.appendChild(element);

            return () => {
                modalRootElement?.removeChild(element);
            }
        } else {
            setShow(false)
        }
    }, [content])

    if (content) {
        if (timeoutRef.current === null)
            timeoutRef.current = setTimeout(() => {
                setShow(false)
            }, timeMS)

        return (
            <Transition
                in={show}
                timeout={TRANSITION_MS}
                mountOnEnter
                appear
                unmountOnExit
            >
                {
                    (state: TransitionStatus) => {
                        if (!show && state === "exited") setTimeout(closeModal, 0)

                        return (
                            <React.Fragment>
                                {
                                    createPortal(
                                        <SnakeContent state={state}>
                                            <CloseButtonContainer>
                                                <StyledCloseButton onClick={closeModal}/>
                                            </CloseButtonContainer>
                                            <ChangeDataSuccess content={content} type={type}/>
                                        </SnakeContent>, element)
                                }

                            </React.Fragment>
                        )
                    }
                }
            </Transition>
        )

    } else return null;
};

const SnakeContent = styled.div<{ state: string }>`
  transition: transform 0.5s;
  transform: scale(${({state}) => (state === "entered" ? 1 : 0)});
  position: fixed;
  display: flex;
  height: 88px;
  bottom: 20px;
  left: 20px;
  margin-top: 30px;
  max-width: 520px;
  min-width: 320px;
  max-height: 1300px;
  border-radius: 4px;
  background-color: #FFFFFF;
  z-index: 1001;

`


const CloseButtonContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
`

export default SnakeBar;
