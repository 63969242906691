import React, { useState } from 'react';
import deleteICON from '@assets/svgs/textfield_delete_icon.svg';
import StyledButton from '@ui/StyledButton';
import styled from 'styled-components';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

interface IFieldProps {
    fieldName: string;
    data: string;
    userRole?: string | undefined;
    namePage: string;
    onDeleteHandler: (value: string) => void;
    onSaveHandler: (fieldName: string, text: string, url?: string | null) => void;
}

const Index: React.FC<IFieldProps> = ({ fieldName, data, onDeleteHandler, onSaveHandler, userRole, namePage }) => {
    const currentData = data ? data : '';
    const { contentBlocks, entityMap } = htmlToDraft(currentData);
    const [contentState, setContentState] = useState<EditorState>(() =>
        EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap))
    );
    const [currentText, setCurrentText] = useState(currentData);
    const [isChanged, setIsChanged] = useState(false);
    return (
        <TextFieldEditorController>
            <div style={{ display: 'flex', gap: 20 }}>
                <h3>{fieldName.replace(';quest;', '?')}</h3>
                {namePage.toLowerCase() === 'faq' && (
                    <DeleteICON
                        onClick={() => {
                            onDeleteHandler(fieldName);
                        }}
                    >
                        <DelIcon width={26} height={26} src={deleteICON} alt="deleteIcon" />
                    </DeleteICON>
                )}
            </div>

            <div style={{ display: 'flex', alignItems: 'start', gap: 20, marginBottom: 20 }}>
                <Editor
                    editorState={contentState}
                    toolbarStyle={{
                        border: '1px solid #B6B6B6',
                    }}
                    editorStyle={{
                        height: 200,
                        border: '1px solid #B6B6B6',
                    }}
                    onEditorStateChange={(value) => {
                        setContentState(value);
                        const newText = draftToHtml(convertToRaw(value.getCurrentContent()));
                        setCurrentText(newText);

                        if (value.getCurrentContent().getPlainText().length > 0 && currentText.length !== newText.length) {
                            setIsChanged(true);
                        }
                    }}
                />
            </div>
            {isChanged && (
                <StyledButton
                    onClick={() => {
                        setIsChanged(false);
                        onSaveHandler(fieldName, currentText);
                    }}
                    height={'46px'}
                    width={'100px'}
                >
                    Save
                </StyledButton>
            )}
        </TextFieldEditorController>
    );
};

const TextFieldEditorController = styled.div`
    max-width: 90%;
`;

const DelIcon = styled.img`
    padding: 5px;
    cursor: pointer;

    &:hover {
        border-radius: 30px;
        background-color: rgba(0.2, 0.2, 0.2, 0.1);
        transition: background 0.3s;
    }

    &:active {
        width: 34px;
        height: 34px;
    }
`;

const DeleteICON = styled.div`
    display: flex;
    align-items: center;
`;

export default Index;
