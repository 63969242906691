import React from 'react';
import WarningIMG from '@assets/svgs/Warning.svg';
import StyledButton from '@ui/StyledButton';
import styled from 'styled-components';
import { User } from '@/api/users/usersTypes';

interface IWarningProps {
    deleteAction: () => void;
    closeModal: () => void;
    subject?: string;
    user: User | undefined;
}

const BanUserModal: React.FC<IWarningProps> = ({ deleteAction, closeModal, subject, user }) => {
    return user ? (
        <ContainerWarning>
            <ImageBlock>
                <img alt={'warning'} src={WarningIMG} />
            </ImageBlock>
            <Description>
                Are you sure you want to {user.isBanned === true ? 'unban' : 'ban'} the user{subject || ''}? You will be able to reverse the change
            </Description>
            <ButtonContainers>
                <CancelButton onClick={closeModal}>Cancel</CancelButton>
                <ButtonContainer onClick={deleteAction}>
                    <StyledButton height={'50px'}>Yes</StyledButton>
                </ButtonContainer>
            </ButtonContainers>
        </ContainerWarning>
    ) : (
        <ContainerWarning>No user found</ContainerWarning>
    );
};

const ContainerWarning = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ButtonContainer = styled.div`
    width: 150px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
`;

const CancelButton = styled.div`
    display: flex;
    text-decoration-line: underline;
    width: 150px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const ButtonContainers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
`;

const ImageBlock = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
`;

export default BanUserModal;
