import React, { useEffect, useState } from 'react';
import CheckboxComponent from '@ui/CheckboxComponent';
import styled from 'styled-components';
import { ILivePriceData, IMainData, IProductData } from '@api/product/productType';
import { useTypedSelector } from '@hooks/useSelector';
import { IErrorLive, IErrorMain } from '@/types';
import TextFields from '@ui/TextFields';
import { priceCheck } from '@/helpers/checkersInput';
import { roundToTwoDecimals, transformNumber } from '@/helpers/round';
import CurrencyTextField from '@ui/CurrencyTextField';
import { NumericFormat } from 'react-number-format';
import { calcBuyPrice } from '@/helpers/priceCalculator';

interface ILivePriceListBlock {
    errorState: IErrorLive;
    prices: {
        buyPrice: () => number;
        sellPrice: () => number;
        displayPrice: () => number;
    };
    lpl: ILivePriceData;
    setLPLAndShop: (value: ILivePriceData, price?: string | number) => void;
    clearLiveError: () => void;
    isFixed: boolean;
    onChangeFixPriceCheckbox: () => void;
    isCollectable: boolean;
    product: IMainData;
    setMainError: (data: IErrorMain) => void;
    mainError: IErrorMain;
    clearMainError: () => void;
}

const LivePriceListBlock: React.FC<ILivePriceListBlock> = ({
    lpl,
    setLPLAndShop,
    errorState,
    prices,
    clearLiveError,
    isFixed,
    onChangeFixPriceCheckbox,
    isCollectable,
    product,
    setMainError,
    mainError,
    clearMainError,
}) => {
    const { categoriesData } = useTypedSelector((state) => state.productReducer);

    const [currentMoneyType, setCurrentMoneyType] = useState('$');
    const [error, setError] = useState<IErrorLive>(errorState);

    const changeVisible = () => {
        if (isCollectable) {
            return;
        }
        clearMainError();
        if ((!product?.weight || product?.weight === 0) && (!product?.purity || product?.purity === 0)) {
            setMainError({
                ...mainError,
                weight: 'Please fill in the field “Weight”. It is required to work with the prices ',
                purity: 'Please fill in the field "Purity". It is required to work with the prices',
            });
            return;
        }
        if (!product?.weight || product?.weight === 0) {
            setMainError({ ...mainError, weight: 'Please fill in the field “Weight”. It is required to work with the prices' });
            return;
        }
        if (!product?.purity || product?.purity === 0) {
            setMainError({ ...mainError, purity: 'Please fill in the field "Purity". It is required to work with the prices' });
            return;
        }
        setLPLAndShop({
            ...lpl,
            is_visible: !lpl?.is_visible,
            buy_price: prices.buyPrice(),
            sell_price: prices.displayPrice(),
        });
        clearLiveError();
    };

    useEffect(() => {
        setError(errorState);
    }, [errorState]);

    return (
        <LPLBlock>
            <BlockTitle>Live Price List</BlockTitle>
            <CheckContainer>
                <CheckboxComponent
                    value={lpl?.is_visible}
                    // onClick={() => {
                    //     if (isCollectable) {
                    //         return;
                    //     }
                    //     setLPLAndShop(
                    //         lpl.is_fixed_price === false
                    //             ? {
                    //                   ...lpl,
                    //                   is_visible: !lpl?.is_visible,
                    //                   buy_price: prices.buyPrice(),
                    //                   sell_price: prices.displayPrice(),
                    //               }
                    //             : {
                    //                   ...lpl,
                    //                   is_visible: !lpl?.is_visible,
                    //               }
                    //     );
                    //     clearLiveError();
                    // }}
                    onClick={changeVisible}
                    width={'18px'}
                    height={'18px'}
                />
                <span style={{ cursor: 'pointer' }} onClick={changeVisible}>
                    Display the product in Live Price List
                </span>
            </CheckContainer>
            <SmallTitle>Category</SmallTitle>
            <LPLContainer>
                <select
                    onChange={(event) => {
                        setError({ ...error, category_id: '' });
                        setLPLAndShop({ ...lpl, category_id: event.target.value });
                    }}
                    disabled={!lpl?.is_visible || isCollectable === true}
                    value={lpl?.category_id}
                    style={{
                        width: '582px',
                        fontWeight: 400,
                        fontSize: '20px',
                        lineHeight: '20px',
                        height: 56,
                    }}
                >
                    <option value={''}>Select category...</option>
                    {categoriesData?.map((item, index) => {
                        return (
                            <option key={`liveCategory${index}`} value={item.id}>
                                {item.name}
                            </option>
                        );
                    })}
                </select>
                <TextFieldLPLContainer>
                    <div>
                        {isFixed === false || isCollectable ? (
                            <div>
                                <PriceBlock isDisabled={!lpl?.is_visible || isCollectable}>{`${currentMoneyType} ${
                                    // lpl?.is_fixed_price
                                    //     ? roundToTwoDecimals(typeof lpl.buy_price === 'number' ? lpl.buy_price : 0)
                                    //     : roundToTwoDecimals(prices.buyPrice())
                                    roundToTwoDecimals(prices.buyPrice())
                                }`}</PriceBlock>
                                <Input_active height={'56px'}>Buy price</Input_active>
                            </div>
                        ) : (
                            <CurrencyTextField
                                decimalScale={2}
                                prefix={currentMoneyType + ' '}
                                step="0.01"
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onChange={({ floatValue }) => {
                                    setError({ ...error, buyPrice: '' });
                                    if (!floatValue) {
                                        setLPLAndShop({ ...lpl, buy_price: prices.buyPrice() });
                                    } else {
                                        setLPLAndShop({ ...lpl, buy_price: floatValue });
                                    }
                                }}
                                value={String(lpl?.buy_price)}
                                disabled={!lpl?.is_visible || isCollectable}
                                placeholder="Buy price"
                                height={'56px'}
                                textError={error.buyPrice}
                            />
                        )}
                    </div>
                    <CheckContainer>
                        <CheckboxComponent
                            value={lpl?.is_buy_display}
                            onClick={() => {
                                if (isCollectable || !lpl?.is_visible) return;
                                setLPLAndShop({
                                    ...lpl,
                                    is_buy_display: !lpl?.is_buy_display,
                                });
                            }}
                            width={'18px'}
                            height={'18px'}
                        />
                        <span
                            onClick={() => {
                                if (isCollectable || !lpl?.is_visible) return;
                                setLPLAndShop({
                                    ...lpl,
                                    is_buy_display: !lpl?.is_buy_display,
                                });
                            }}
                            style={{ marginBottom: 1, cursor: 'pointer' }}
                        >
                            Display Buy Price
                        </span>
                    </CheckContainer>
                </TextFieldLPLContainer>
                <TextFieldLPLContainer>
                    <div>
                        {isFixed === false || isCollectable ? (
                            <div>
                                <PriceBlock isDisabled={!lpl?.is_visible || isCollectable}>{`${currentMoneyType}  ${
                                    // lpl?.is_fixed_price
                                    //     ? roundToTwoDecimals(typeof lpl.sell_price === 'number' ? lpl.sell_price : 0)
                                    //     : roundToTwoDecimals(prices.displayPrice())
                                    roundToTwoDecimals(prices.displayPrice())
                                }`}</PriceBlock>
                                <Input_active height={'56px'}>Sell price</Input_active>
                            </div>
                        ) : (
                            // <TextFields
                            //     onChange={(e) => {
                            //         let currentValue = e.target.value.replace(currentMoneyType, '').trim();

                            //         setError({ ...error, sellPrice: '' });

                            //         if (priceCheck(currentValue)) {
                            //             return;
                            //         }

                            //         if (lpl.is_visible) {
                            //             setLPLAndShop(
                            //                 {
                            //                     ...lpl,
                            //                     sell_price: +transformNumber(+currentValue),
                            //                 },
                            //                 +transformNumber(+currentValue)
                            //             );
                            //         }
                            //     }}
                            //     placeholder={'Sell price'}
                            //     value={`${currentMoneyType} ${
                            //         lpl?.is_visible
                            //             ? roundToTwoDecimals(typeof lpl.sell_price === 'number' ? lpl.sell_price : 0)
                            //             : roundToTwoDecimals(prices.displayPrice())
                            //     }`}
                            //     // value={`${currentMoneyType} ${lpl.sell_price || prices.displayPrice()}`}
                            //     disabled={!lpl?.is_visible || isCollectable}
                            //     type={'text'}
                            //     height={'56px'}
                            //     textError={error.sellPrice}
                            // />
                            <CurrencyTextField
                                decimalScale={2}
                                prefix={currentMoneyType + ' '}
                                step="0.01"
                                fixedDecimalScale={true}
                                allowNegative={false}
                                // onValueChange={({ floatValue }) => setLPLAndShop({ ...lpl, buy_price: floatValue })}
                                onChange={({ floatValue }) => {
                                    setError({ ...error, sellPrice: '' });
                                    if (!floatValue) {
                                        setLPLAndShop({ ...lpl, sell_price: prices.displayPrice() }, prices.displayPrice());
                                    } else {
                                        setLPLAndShop({ ...lpl, sell_price: floatValue }, floatValue);
                                    }
                                }}
                                value={String(lpl?.sell_price)}
                                disabled={!lpl?.is_visible || isCollectable}
                                placeholder="Sell price"
                                height={'56px'}
                                textError={error.sellPrice}
                            />
                        )}
                    </div>
                    <CheckContainer>
                        <CheckboxComponent
                            value={lpl?.is_sell_display}
                            onClick={() => {
                                if (isCollectable || !lpl?.is_visible) return;

                                setLPLAndShop({
                                    ...lpl,
                                    is_sell_display: !lpl?.is_sell_display,
                                });
                            }}
                            width={'18px'}
                            height={'18px'}
                        />
                        <span
                            onClick={() => {
                                if (isCollectable || !lpl?.is_visible) return;

                                setLPLAndShop({
                                    ...lpl,
                                    is_sell_display: !lpl?.is_sell_display,
                                });
                            }}
                            style={{ marginBottom: 1, cursor: 'pointer' }}
                        >
                            Display Sell Price
                        </span>
                    </CheckContainer>
                </TextFieldLPLContainer>
                <CheckContainer style={{ height: 60 }}>
                    <CheckboxComponent
                        value={isFixed}
                        onClick={() => {
                            if (isCollectable || !lpl?.is_visible) return;
                            onChangeFixPriceCheckbox();
                            //changeFixedPrice()
                        }}
                        disabled={isFixed}
                        width={'18px'}
                        height={'18px'}
                    />
                    <span
                        onClick={() => {
                            //changeFixedPrice()
                            if (isCollectable || !lpl?.is_visible) return;
                            onChangeFixPriceCheckbox();
                        }}
                        style={{ marginBottom: 1, cursor: 'pointer' }}
                    >
                        Fixed price
                    </span>
                </CheckContainer>
            </LPLContainer>
            {
                <ErrorWrap>
                    {error.category_id.length > 0 && (
                        <div
                            style={{
                                color: '#D92E23',
                                fontSize: 14,
                                paddingBottom: 10,
                                marginTop: -35,
                            }}
                        >
                            {error.category_id}
                        </div>
                    )}
                </ErrorWrap>
            }
        </LPLBlock>
    );
};

const Input_active = styled.div<{ height: string }>`
    transform: scale(0.75) translateY(calc(${(props) => props.height} / -0.575 + 0.1 * ${(props) => props.height}));
    //transition: transform 0.15s;
    position: absolute;
    color: #000;
    background-color: #fff;
    //left: 41px;
    margin-left: 10px;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
`;

const PriceBlock = styled.div<{ isDisabled: boolean }>`
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1px 2px 1px 10px;
    border: #b6b6b6 solid 1px;
    border-radius: 4px;
    font-weight: 400;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 5px;
    box-sizing: border-box;
    // background: ${(props) => (props.isDisabled ? '#fbfbfb' : '#fff')};
    background: #fbfbfb;
`;

const ErrorWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const TextFieldLPLContainer = styled.div`
    max-width: 230px;
    width: 100%;
    margin-bottom: -5px;
`;

const LPLContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: start;
`;

const SmallTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const CheckContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const BlockTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
`;

const LPLBlock = styled.div`
    max-height: 269px;
    padding: 20px 0 25px 30px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export default LivePriceListBlock;
