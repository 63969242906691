import React, {ChangeEvent} from 'react';
import PaginationBackIMG from "@assets/svgs/pagination_backButton_img.svg";
import {amountCheck} from "@/helpers/checkersInput";
import PaginationNextIMG from "@assets/svgs/pagination_nextButton_image.svg";
import styled from "styled-components";

interface IPaginator {
    max: number
    page: number
    setPage: (value: number) => void
    setLimit: (value: number) => void
    width?: string
}

const Index: React.FC<IPaginator> = ({max, page, width, setPage, setLimit}) => {
    return (
        <PageBlock width={width}>
            <div style={{display: "flex", alignItems: "center", gap: 10,}}>
                <img
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        if (page - 1 < 1) {
                            return
                        } else {
                            setPage(page - 1)
                        }
                    }}
                    src={PaginationBackIMG}
                    alt={'back'}
                />
                <input
                    style={{
                        width: 30,
                        height: 30,
                        fontSize: 14,
                        color: "#304FFE",
                        border: "1px solid #E7E7ED",
                        borderRadius: 2,
                        padding: 0,
                        textAlign: "center"
                    }}
                    value={page}
                    onChange={e => {
                        const value = e.target.value
                        if (amountCheck(value)) {
                            return
                        }
                        setPage(Number(value))
                    }}
                />
                /
                <MaxPage>
                    {
                        max
                    }
                </MaxPage>
                <img
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        if (page + 1 > max) {
                            return
                        }
                        setPage(page + 1)
                    }}
                    src={PaginationNextIMG}
                    alt={'next'}
                />
            </div>
            <div style={{display: "flex", gap: 12, color: "#454D59"}}>
                Rows on a page
                <select onChange={
                    (e: ChangeEvent<HTMLSelectElement>) => {
                        setLimit(Number(e.target.value))
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={300}>300</option>
                </select>
            </div>
        </PageBlock>
    );
};

interface IPageBlockProps {
    width?: string
}

const PageBlock = styled.div<IPageBlockProps>`
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #E7E7ED;
  gap: 10px;
  justify-content: space-between;
  width: ${(props => props.width ? props.width : "500px")};
`

const MaxPage = styled.div`
  color: black;
`

export default Index;