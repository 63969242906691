import React, { useCallback, useEffect, useState } from 'react';
import StyledButton from '@ui/StyledButton';
import styled from 'styled-components';
import DownloadIMG from '@assets/svgs/modal_download_icon.svg';
import DeleteICON from '@assets/svgs/image_redDelete_icon.svg';
import { IModalElement } from '@ui/components/modals/Modal';
import { useDropzone } from 'react-dropzone';
import { useMediaActions, useSnakeBarAction } from '@hooks/useActions';
import { SnakeTypes } from '@ui/components/modals/SnakeBar';

const DownloadImage: React.FC<IModalElement> = ({ closeModal }) => {
    const { uploadImages } = useMediaActions();
    const { showSnackbar } = useSnakeBarAction();

    const [images, setImages] = useState<any[]>([]);
    const [currentImages, setCurrentImages] = useState<any[]>([]);

    const onDrop = useCallback((acceptedFiles) => {
        setCurrentImages([...acceptedFiles.map((f: Blob | MediaSource) => Object.assign(f, { preview: URL.createObjectURL(f) }))]);

    }, []);

    useEffect(() => {
        setImages([...images, ...currentImages]);
    }, [currentImages]);

    const deleteImg = (name: string) => {
        setImages((prevState) => prevState.filter((item) => item.name !== name));
    };

    const thumbs = images.map((file, index) => {
        return (
            <Thumb key={`file.name ${index}`}>
                <ThumbInner>
                    <Img src={file.preview} />
                    <DeleteIcon draggable={false} onClick={() => deleteImg(file.name)} src={DeleteICON} alt={'delete'} />
                </ThumbInner>
            </Thumb>
        );
    });

    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, fileRejections } = useDropzone({
        onDrop,
        accept: '.jpg,.png,.jpeg',
        maxFiles: 5,
        maxSize: 5242880,
    });

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        // images.forEach((file) => URL.revokeObjectURL(file.preview));
        console.log(images);
    }, [images]);

    const onSaveHandler = () => {
        uploadImages(images);
        if (closeModal) {
            closeModal();
        }
    };

    useEffect(() => {
        if (fileRejections.length > 0) {
            showSnackbar({ content: `File ${fileRejections[0].file.name} ${fileRejections[0].errors[0].message}`, type: SnakeTypes.error });
        }
    }, [fileRejections]);

    return (
        <Div>
            <Title>Upload Image</Title>
            <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                <img draggable={false} width={43} height={43} src={DownloadIMG} alt={'download'} />
                <input {...getInputProps()} />
                {isDragActive ? (
                    <div>Drop the files here ...</div>
                ) : (
                    <div>
                        <span style={{ color: '#219653' }}>Select a file</span> or drag it here
                    </div>
                )}
            </Container>
            <Aside>{thumbs}</Aside>
            <ButtonContainers>
                <CancelButton onClick={closeModal}>Cancel</CancelButton>
                <ButtonContainer>
                    <StyledButton onClick={onSaveHandler} height={'50px'}>
                        Save
                    </StyledButton>
                </ButtonContainer>
            </ButtonContainers>
        </Div>
    );
};

const Div = styled.div`
    min-height: 340px;
    display: flex;
    flex-direction: column;
`;

const DeleteIcon = styled.img`
    position: absolute;
    cursor: pointer;
    margin-left: 65px;
`;

const Aside = styled.aside`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const Img = styled.img`
    display: block;
    width: auto;
    height: 100%;
`;

const ThumbInner = styled.div`
    display: flex;
    min-width: 0;
    overflow: hidden;
`;

const Thumb = styled.div`
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 96px;
    height: 96px;
    padding: 4px;
    box-sizing: border-box;
`;

const Container = styled.div`
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px dashed #636363;
    box-sizing: border-box;
    outline: none;
    transition: border 0.24s ease-in-out;
    gap: 10px;
    margin-top: 12px;
    margin-bottom: 30px;
    height: 100%;
`;

const ButtonContainer = styled.div`
    width: 100%;
`;

const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: capitalize;
`;

const CancelButton = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: #f8c882;
    border: 1px solid #f8c882;
    box-sizing: border-box;
    border-radius: 6px;
`;

const ButtonContainers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
`;

export default DownloadImage;
