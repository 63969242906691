import {
    adminAction,
    IClearAdminState,
    IDeletePage,
    IErrorAdminAction,
    IResponseCreatePage,
    IResponseCreateTextField,
    IResponseDeletePage,
    IResponseDeleteTextField,
    IResponseGetAllPages,
    IResponseUpdateTextField,
    IStartAdminAction,
} from '@store/types/adminTypes';
import { AdminActionTypes } from '@store/constants/adminConstants';
import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { adminAPI } from '@api/admin/adminApi';
import { ICreateFieldData, IResponsePage, IUpdateTextField } from '@api/admin/adminType';
import { showSnackbar } from '@store/actions/snackbarActions';
import { SnakeTypes } from '@ui/components/modals/SnakeBar';

const startAdminAction = (): IStartAdminAction => ({
    type: AdminActionTypes.START_ADMIN_ACTION,
});

const errorAdminAction = (payload: string): IErrorAdminAction => ({
    type: AdminActionTypes.ERROR_ADMIN_ACTION,
    payload,
});

export const clearState = (): IClearAdminState => ({
    type: AdminActionTypes.CLEAR_STATE_ADMIN,
});

export const setPages = (payload: IResponsePage[]): IResponseGetAllPages => ({
    type: AdminActionTypes.GET_ALL_PAGES,
    payload,
});

export const getPages = () => async (dispatch: Dispatch<adminAction>) => {
    try {
        dispatch(startAdminAction());
        const response = await adminAPI.getPages();
        dispatch(setPages(response));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(errorAdminAction(e.response?.data));
    }
};

const setCreatedPage = (payload: IResponsePage): IResponseCreatePage => ({
    type: AdminActionTypes.CREATE_PAGE,
    payload,
});

export const createPage = (name: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAdminAction());
        const response = await adminAPI.createPage({ page: name });
        dispatch(setCreatedPage(response));
        dispatch(showSnackbar({ content: `Page ${name} created successfully`, type: SnakeTypes.success }));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: 'Something went wrong', type: SnakeTypes.error }));
        dispatch(errorAdminAction(e.response?.data));
    }
};

const deletePage_ = (payload: IDeletePage): IResponseDeletePage => ({
    type: AdminActionTypes.DELETE_PAGE,
    payload,
});

export const deletePage = (name: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAdminAction());
        const response = await adminAPI.deletePage(name);
        dispatch(deletePage_({ name, message: response.message }));
        dispatch(showSnackbar({ content: `Page ${name} deleted successfully`, type: SnakeTypes.success }));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: 'Something went wrong', type: SnakeTypes.error }));
        dispatch(errorAdminAction(e.response?.data));
    }
};

const addTextField = (payload: IResponsePage): IResponseCreateTextField => ({
    type: AdminActionTypes.CREATE_TEXT_FIELD,
    payload,
});

export const createTextField = (data: ICreateFieldData) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAdminAction());
        console.log(data);
        if (data.title.length <= 1 || data.title.split('').every((item) => item === ' ')) {
            throw new Error('Field title is required');
        }
        const response = await adminAPI.addTextField(data);
        dispatch(addTextField(response));
        dispatch(showSnackbar({ content: `On page ${data.name} field ${data.title.replace(/;quest;/g, '?')} created successfully` }));
    } catch (_e: any) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: _e.message || 'Something went wrong', type: SnakeTypes.error, ms: 4000 }));
        dispatch(errorAdminAction(e.response?.data));
    }
};

const removeTextField = (payload: IResponsePage): IResponseDeleteTextField => ({
    type: AdminActionTypes.DELETE_TEXT_FIELD,
    payload,
});

export const deleteTextField = (data: ICreateFieldData) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAdminAction());
        const response = await adminAPI.deleteTextField(data);
        dispatch(removeTextField(response));
        dispatch(showSnackbar({ content: `On page ${data.name} field ${data.title.replace(/;quest;/g, '?')} removed successfully` }));
    } catch (_e) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: 'Something went wrong', type: SnakeTypes.error, ms: 4000 }));
        dispatch(errorAdminAction(e.response?.data));
    }
};

const updateTextField_ = (payload: IResponsePage): IResponseUpdateTextField => ({
    type: AdminActionTypes.UPDATE_TEXT_FIELD,
    payload,
});

export const updateTextField = (data: IUpdateTextField) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAdminAction());
        if (data.title.length <= 1 || data.title.split('').every((item) => item === ' ')) {
            throw new Error('Field title is required');
        }
        const response = await adminAPI.updateTextField(data);
        dispatch(updateTextField_(response));
        dispatch(
            showSnackbar({
                content: `On page ${data.name} field ${data.title.replace(/;quest;/g, '?')} updated successfully`,
                type: SnakeTypes.success,
            })
        );
    } catch (_e: any) {
        const e = _e as AxiosError;
        dispatch(showSnackbar({ content: _e.message || 'Something went wrong', type: SnakeTypes.error }));

        dispatch(errorAdminAction(e.response?.data));
    }
};
