import config from '@/config';
import FirebaseService from '@/services/firebase-service';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const BASE_API_URL = config.REACT_APP_BASE_API || 'https://new.bullionnetwork.com.au/';

axios.defaults.baseURL = BASE_API_URL;
/*axios.defaults.headers.common['Content-Type'] = 'application/json';*/

const api: AxiosInstance = axios.create({
    // withCredentials: true
});

export const MAIN_DOMAIN = config.REACT_APP_MAIN_DOMAIN || 'https://new.bullionnetwork.com.au/';

// api.interceptors.request.use((config: AxiosRequestConfig) => {
//     config.headers = {
//         Authorization: `Bearer ${localStorage.getItem('firebaseToken')}`,
//         'Content-Type': 'application/json',
//     };
//     return config;
// });

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = await FirebaseService.auth.getAccessToken();
    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    return config;
});

let refreshIsProcess = false;

api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error) => {
        if (refreshIsProcess) return;
        refreshIsProcess = true;
        const originalRequest = error.config;
        if (error.response?.status === 401 && originalRequest && !originalRequest._isRetry) {

        }
        refreshIsProcess = false;
        throw error;
    }
);

export default api;
