import React from 'react';
import styled from "styled-components";
import loading from "@assets/loading.svg"

const Loading = () => {
    return (
        <Wrapper>
            <img src={loading}/>
        </Wrapper>
    );
};
const Wrapper = styled.div`
  background-color: rgba(57, 73, 133, 1);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  top:0;
  left: 0;
`

export default Loading;