import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Layout from "@components/layout";
import {MainContainer} from '@/ui/mainContainer';
import styled from "styled-components";
import PlusIMG from '@assets/svgs/Plus.svg'
import OptionsIMG from '@assets/svgs/admin_options_img.svg'
import StyledButton from "@ui/StyledButton";
import CheckboxComponent from "@ui/CheckboxComponent";
import Modal from "@ui/components/modals/Modal";
import AddNewCategory from "@ui/components/modals/AddNewCategory";
import {useTypedSelector} from "@hooks/useSelector";
import EditIMG from "@assets/svgs/product_edit_img.svg";
import DeleteIMG from "@assets/svgs/product_delete_img.svg";
import {useProductActions, useUserActions} from "@hooks/useActions";
import {IResponseCategory} from "@api/product/productType";
import Paginator from "@api/../ui/components/paginator";
import WarningModal from "@ui/components/modals/WarningModal";
import {useClickAway} from "@hooks/useClickAway";


const Categories = () => {
    const {categoriesData, metals, totalItems} = useTypedSelector(state => state.productReducer)
    const {getMetals, getCategories, deleteCategory} = useProductActions()
    const {getUser} = useUserActions()

    const [showAddCategory, setShowAddCategory] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [selectedRow, setSelectedRow] = useState(-1)
    const [isEdit, setIsEdit] = useState(false)
    const [currentCategory, setCurrentCategory] = useState<IResponseCategory | undefined>(undefined)

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);

    const wrapperRef = useRef(null);
    const triggerRef = useRef<HTMLDivElement>(null);

    useClickAway(wrapperRef, () => setSelectedRow(-1), triggerRef);

    const onEditHandler = (value: IResponseCategory) => {
        setIsEdit(true)
        setCurrentCategory(value)
        setShowAddCategory(true)
    }

    const onDeleteHandler = (id: string | undefined) => {
        if (id) {
            deleteCategory(id)
        }
    }

    useEffect(() => {
        getMetals()
        getUser()
    }, [])

    useEffect(() => {
        getCategories({page, pageLimit})
    }, [page, pageLimit])

    const addCategoryComponent = useCallback(() =>
        AddNewCategory({
            closeModal: () => {
                setShowAddCategory(false)
                setIsEdit(false)
                setCurrentCategory(undefined)
                setSelectedRow(-1)
            },
            isEdit,
            currentData: currentCategory,
        }), [isEdit, currentCategory]
    );

    return (
        <Layout>
            <Modal
                width={'620px'}
                marginTop={'50px'}
                show={showAddCategory}
                setShow={setShowAddCategory}
                ModalElement={addCategoryComponent}
            />
            <Modal
                marginTop={"100px"}
                width={"360px"}
                show={showWarning}
                setShow={setShowWarning}
                ModalElement={() => WarningModal({
                    deleteAction: () => {
                        onDeleteHandler(currentCategory?.id)
                        setShowWarning(false)
                    },
                    subject: "category",
                    closeModal: () => setShowWarning(false)
                })}/>
            <MainContainer>
                <Content>
                    <Title>Categories</Title>
                    <ButtonContainer onClick={() => {
                        setShowAddCategory(true)
                        setCurrentCategory(undefined)
                    }}>
                        <StyledButton height={'43px'} color={'white'} backgroundColor={'#5B73CF'}>
                            <img src={PlusIMG} alt={'plus'}/>
                            Add new category
                        </StyledButton>
                    </ButtonContainer>
                    <Table>
                        <thead>
                        <FirstColumn>
                            <th>
                                <FieldWithCheckBox>
                                    Category Name
                                </FieldWithCheckBox>
                            </th>
                            <th>
                                Metal
                            </th>
                        </FirstColumn>
                        </thead>
                        <tbody>
                        {
                            categoriesData.map((item, index) => {
                                const isLast = index === categoriesData.length - 1
                                return (
                                    <ItemTr
                                        key={`item${index}`}
                                        isChosen={index === selectedRow} style={{
                                        borderBottom: isLast ? "1px solid #E7E7ED" : 'inherit',
                                        paddingBottom: 20
                                    }}>
                                        <th>
                                            <FieldWithCheckBox>
                                                <RowText>
                                                    {
                                                        item?.name
                                                    }
                                                </RowText>
                                            </FieldWithCheckBox>
                                        </th>
                                        <OptionsPart>
                                            <RowText>
                                                {
                                                    metals.find(metal => metal.id === item.metal_id)?.name
                                                }
                                            </RowText>
                                            <div
                                                key={`imgBlock${index}`}
                                                onClick={(e) => {
                                                    if (index === selectedRow) {
                                                        setSelectedRow(-1)
                                                    } else {
                                                        setSelectedRow(index)
                                                        setCurrentCategory(item)
                                                    }
                                                    // @ts-ignore
                                                    triggerRef.current = e.target
                                                }}>
                                                <img
                                                    style={{cursor: "pointer"}} src={OptionsIMG}
                                                    alt={'options'}/>
                                            </div>
                                        </OptionsPart>
                                        {
                                            selectedRow === index && <th
                                                ref={wrapperRef}
                                                style={{position: "absolute"}}>
                                                <ActionsBlock>
                                                    <img onClick={() => {
                                                        setSelectedRow(-1)
                                                        onEditHandler(item)
                                                    }}
                                                         style={{cursor: "pointer"}}
                                                         draggable={false} src={EditIMG} alt={"edit"}/>
                                                    <img onClick={() => {
                                                        setSelectedRow(-1)
                                                        setShowWarning(true)
                                                    }}
                                                         style={{cursor: "pointer"}}
                                                         draggable={false} src={DeleteIMG} alt={'delete'}/>
                                                </ActionsBlock>
                                            </th>
                                        }
                                    </ItemTr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                    <Paginator
                        setLimit={setPageLimit}
                        setPage={setPage}
                        max={Math.ceil(totalItems / pageLimit)}
                        page={page}/>
                </Content>
            </MainContainer>
        </Layout>
    );
};


const ActionsBlock = styled.div`
  position: absolute;
  margin-top: 5px;
  left: 350px;
  width: 122px;
  height: 52px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`

const OptionsPart = styled.th`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const RowText = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`

interface IChosenLine {
    isChosen?: boolean
}

const ItemTr = styled.tr<IChosenLine>`
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: ${props => !!props.isChosen ? "#EBEFFF" : 'white'};
  border-right: 1px solid #E7E7ED;
  border-left: 1px solid #E7E7ED;
  box-sizing: border-box;
  padding: 20px 0 0 16px;
`

const FirstColumn = styled.tr`
  display: flex;
  gap: 24px;
  background: #FFFFFF;
  border: 1px solid #E7E7ED;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
  padding: 12px 60px 12px 16px;
  margin-bottom: -1.9px;
`

const Table = styled.table`
  margin-top: 30px;
  width: 497px;
`

const FieldWithCheckBox = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  width: 230px;
`

const ButtonContainer = styled.div`
  width: 211px;
  background: #5B73CF;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /*  padding: 9px 10px;*/
`

const Title = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 136%;
  margin-bottom: 50px;
`

const Content = styled.div`
  margin-top: 20px;
`

export default Categories;