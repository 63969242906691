import {
    ICLearCurrentProduct,
    ICreateCategory,
    ICreateProduct,
    IDeleteCategory,
    IDeleteProduct,
    IErrorProductAction,
    IGetAllProducts,
    IGetCategories,
    IGetCategoriesWithOutPagination,
    IGetMetals,
    IGetPriceMetals,
    IGetProduct,
    IStartProductAction,
    IUpdateCategory,
    IUpdateFullProduct,
    productActions
} from "@store/types/productTypes";
import {ProductsActionTypes} from "@store/constants/productConstants";
import {IErrors} from "@/types";
import {Dispatch} from "redux";
import {AxiosError} from "axios";
import {productApi} from "@api/product/productApi";
import {
    ICategoryData,
    IMetal,
    IMetalPrices,
    IPaginationCategoryData,
    IPaginationData,
    IProductData,
    IResponseCategory,
    IResponseDataCategory,
    IResponseDataProduct,
    IResponseProduct,
    IUpdateProduct
} from "@api/product/productType";
import {showSnackbar} from "@store/actions/snackbarActions";
import {SnakeTypes} from "@ui/components/modals/SnakeBar";

const startAction = (): IStartProductAction => ({
    type: ProductsActionTypes.START_PRODUCT_ACTION
})

const errorAction = (payload: IErrors[]): IErrorProductAction => ({
    type: ProductsActionTypes.ERROR_PRODUCT_ACTION,
    payload
})

const setMetals = (payload: IMetal[]): IGetMetals => ({
    type: ProductsActionTypes.GET_METALS,
    payload
})

export const getMetals = () => async (dispatch: Dispatch<productActions>) => {
    try {
        dispatch(startAction())
        const response = await productApi.getMetals()
        dispatch(setMetals(response))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const getMetalPrices_ = (payload: IMetalPrices[]): IGetPriceMetals => ({
    type: ProductsActionTypes.GET_METAL_PRICES,
    payload
})

export const getMetalPrices = () => async (dispatch: Dispatch<productActions>) => {
    try {
        dispatch(startAction())
        const response = await productApi.getMetalPrices()
        dispatch(getMetalPrices_(response))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const addAllCategories = (payload: IResponseDataCategory): IGetCategories => ({
    type: ProductsActionTypes.GET_PRODUCT_CATEGORIES,
    payload
})

export const getCategories = (data?: IPaginationCategoryData) => async (dispatch: Dispatch<productActions>) => {
    try {
        dispatch(startAction())
        const response = await productApi.getAllCategories(data)
        dispatch(addAllCategories(response))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const addAllCategoriesWithOutPagination_ = (payload: IResponseCategory[]): IGetCategoriesWithOutPagination => ({
    type: ProductsActionTypes.GET_PRODUCT_CATEGORIES_WITH_OUT_PAGINATION,
    payload
})


export const getCategoriesWithOutPagination = (metalId: string) => async (dispatch: Dispatch<productActions>) => {
    try {
        dispatch(startAction())
        const response = await productApi.getAllCategoriesWithOutPagination(metalId)
        dispatch(addAllCategoriesWithOutPagination_(response))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const createCategory_ = (payload: IResponseCategory): ICreateCategory => ({
    type: ProductsActionTypes.CREATE_PRODUCT_CATEGORY,
    payload
})

export const createCategory = (data: ICategoryData) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction())
        const response = await productApi.createCategory(data);
        dispatch(createCategory_(response))
        dispatch(showSnackbar({content: "Category created successfully", type: SnakeTypes.success}))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(showSnackbar({content: e.response?.data.message[0].code, type: SnakeTypes.error}))
        dispatch(errorAction(e.response?.data.message))
    }
}

const updateCategory_ = (payload: IResponseCategory): IUpdateCategory => ({
    type: ProductsActionTypes.UPDATE_PRODUCT_CATEGORY,
    payload
})

export const updateCategory = (categoryId: string, data: ICategoryData) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction())
        const response = await productApi.updateCategory(categoryId, data)
        dispatch(updateCategory_(response))
        dispatch(showSnackbar({content: "Category updated successfully", type: SnakeTypes.success}))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const deleteCategory_ = (id: string, message: string): IDeleteCategory => ({
    type: ProductsActionTypes.DELETE_PRODUCT_CATEGORY,
    payload: {
        id,
        message
    }
})

export const deleteCategory = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction())
        const response = await productApi.deleteCategory(id)
        dispatch(deleteCategory_(id, response.message))
        dispatch(showSnackbar({content: "Category deleted successfully", type: SnakeTypes.success}))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(showSnackbar({content: e.response?.data.message, type: SnakeTypes.error}))
        dispatch(errorAction(e.response?.data.message))
    }
}

const getAllProducts_ = (payload: IResponseDataProduct): IGetAllProducts => ({
    type: ProductsActionTypes.GET_ALL_PRODUCTS,
    payload
})

export const getAllProducts = (data?: IPaginationData) => async (dispatch: Dispatch<productActions>) => {
    try {
        dispatch(startAction())
        const response = await productApi.getAllProducts(data)
        dispatch(getAllProducts_(response))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const getProduct_ = (payload: IResponseProduct): IGetProduct => ({
    type: ProductsActionTypes.GET_PRODUCT,
    payload
})

export const getProduct = (id: string) => async (dispatch: Dispatch<productActions>) => {
    try {
        dispatch(startAction())
        const response = await productApi.getProduct(id)
        dispatch(getProduct_(response))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(errorAction(e.response?.data.message))
    }
}

const updateProduct_ = (payload: IResponseProduct): IUpdateFullProduct => ({
    type: ProductsActionTypes.UPDATE_PRODUCT,
    payload
})

export const updateProduct = (id: string, data: IUpdateProduct) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction())
        const response = await productApi.updateFullProductData(id, data)
        dispatch(updateProduct_(response))
        dispatch(showSnackbar({content: "Product updated successfully", type: SnakeTypes.success}))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(showSnackbar({content: e.response?.data.message || "Something went wrong", type: SnakeTypes.error}))
        dispatch(errorAction(e.response?.data.message))
    }
}

const deleteProduct_ = (id: string, message: string): IDeleteProduct => ({
    type: ProductsActionTypes.DELETE_PRODUCT,
    payload: {
        id,
        message
    }
})

export const deleteProduct = (id: string) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction())
        const response = await productApi.deleteProduct(id)
        dispatch(deleteProduct_(id, response.message))
        dispatch(showSnackbar({content: "Product deleted successfully", type: SnakeTypes.success}))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(showSnackbar({content: e.response?.data.message || "Something went wrong", type: SnakeTypes.error}))
        dispatch(errorAction(e.response?.data.message))
    }
}

const createProduct_ = (payload: IResponseProduct): ICreateProduct => ({
    type: ProductsActionTypes.CRETE_PRODUCT,
    payload
})

export const createProduct = (data: IProductData) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startAction())
        const response = await productApi.createProduct(data)
        dispatch(createProduct_(response))
        dispatch(showSnackbar({content: "Product created successfully", type: SnakeTypes.success}))
    } catch (_e) {
        const e = _e as AxiosError

        const errorText = e.response?.data.message ? e.response?.data.message : "Something went wrong"
        dispatch(showSnackbar({content: errorText, type: SnakeTypes.error}))
        dispatch(errorAction(e.response?.data.message))
    }
}


export const updateVisible = (data: IResponseProduct, type: 'shop' | 'live') => async (dispatch: Dispatch<productActions>) => {
    try {
        //if(!(data.live && data.shop)) return
        dispatch(startAction())
        let response
        switch (type) {
            case "live":
                if (data.live) {
                    response = await productApi.updateFullProductData(data.id, {
                            live: {
                                is_visible: !data.live?.is_visible,
                                category_id: data.live?.category_id
                            }
                        })
                }
                break
            case
            "shop":
                if (data.shop) {
                    response = await productApi.updateFullProductData(data.id, {
                        shop: {
                            is_visible: !data.shop?.is_visible,
                            category_id: data.shop?.category_id,
                            exp_date: new Date().toISOString() + ''
                        }
                    })
                }
                break
        }

        if (!response) return // something go wrong
        dispatch(updateProduct_(response))
    } catch
        (_e) {
        const e = _e as AxiosError
        dispatch(e.response?.data.message)
    }
}

export const clearCurrentProduct = (): ICLearCurrentProduct => ({
    type: ProductsActionTypes.CLEAR_CURRENT_PRODUCT
})
