import { IErrors } from '@/types';
import { UsersActionTypes } from '../constants/usersConstants';
import { usersActions } from '../types/usersTypes';
import { User } from '@/api/users/usersTypes';

interface IUsersState {
    users: User[] | undefined;
    loading: boolean;
    error: IErrors[] | string | undefined;
    message: string | undefined;
}

const initialState: IUsersState = {
    users: [],
    loading: false,
    message: undefined,
    error: undefined,
};

const startLoading = (state: IUsersState) => {
    return { ...state, loading: true, error: undefined };
};

const stopErrorLoading = (state: IUsersState, error: IErrors[]) => {
    return { ...state, loading: false, error };
};

const usersReducer = (state = initialState, action: usersActions): IUsersState => {
    switch (action.type) {
        case UsersActionTypes.GET_USERS_ACTION:
            return { ...state, users: action.payload };
        case UsersActionTypes.ERROR_USERS_ACTION:
            return stopErrorLoading(state, action.payload);
        default:
            return state;
    }
};

export default usersReducer;
