import {
    IChangePasswordData,
    IResponseMessage,
    IResponseFirstStep,
    IResponseSecondStep,
    IRequestSignUpPrivateUser,
    ITwoFactor,
    IUserData,
    IUserDataUpdate,
    IUserLogin,
    IRequestSignUpJuristicWholesalerUser,
    IFirebaseSignUpResponse,
    IFirebaseLogin,
} from './userTypes';
import api from '../index';

export const userAPI = {
    async refreshToken() {
        return (await api.put<{ access_token: string }>('/auth/token/refresh')).data;
    },
    async isAdmin(data: { email: string }) {
        return (await api.post<{ status: boolean }>(`/auth/is-admin`, data)).data;
    },
    async singUpPrivateUser(data: IRequestSignUpPrivateUser) {
        return (await api.post<IResponseMessage>(`/auth/sign-up/private`, data)).data;
    },
    async signUpJuristicWholesaler(data: IRequestSignUpJuristicWholesalerUser) {
        return (await api.post<IResponseMessage>(`/auth/sign-up`, data)).data;
    },
    async signInDev(data: IUserLogin) {
        return (await api.post<IResponseSecondStep>(`/auth/sign-in/dev`, data)).data;
    },
    async activateUser(id: string) {
        return (await api.get<IResponseMessage>(`/auth/activate/${id}`)).data;
    },
    async signInFirebase(data: IFirebaseLogin) {
        return (await api.post<IResponseSecondStep>(`/auth/sign-in/firebase`, data)).data;
    },
    async registerFirebaseUser(data: IRequestSignUpPrivateUser) {
        return (await api.post<IFirebaseSignUpResponse>(`/auth/sign-up/firebase`, data)).data;
    },
    async getCurrentFirebaseUser() {
        return (await api.post<IResponseSecondStep>(`/auth/firebase-user`)).data;
    },
    async signInFirstStep(data: IUserLogin) {
        return (await api.post<IResponseFirstStep>(`/auth/sign-in`, data)).data;
    },
    async signInStepTwo(data: ITwoFactor) {
        return (await api.post<IResponseSecondStep>(`/auth/sign-in/two-factory`, data)).data;
    },
    async getCurrentUser() {
        return (await api.get<IUserData>(`/auth/user/admin`)).data;
    },
    async logoutUser() {
        return await api.delete(`auth/logout`);
    },
    async resetPasswordSessionCreate(email: string) {
        return (await api.post<IResponseMessage>(`/auth/reset-password/create`, email)).data;
    },
    async resetPasswordActivate(id: string, password: string) {
        return (await api.post<IResponseMessage>(`/auth/reset-password/${id}/activate`, password)).data;
    },
    async getCurrentStateResetSession(id: string) {
        return (await api.get<IResponseMessage>(`/auth/reset-password/${id}`)).data;
    },
    async updateUserData(data: IUserDataUpdate) {
        return (await api.patch<IUserData>(`/user`, data)).data;
    },
    async changePassword(data: IChangePasswordData) {
        return (await api.patch<IUserData>(`/user/change-password`, data)).data;
    },
    async changeEmailSessionCreate(email: string) {
        return (await api.post<IResponseMessage>(`/user/change-email/create`, email)).data;
    },
    async changeEmailActivate(id: string) {
        return (await api.post<IResponseMessage>(`/user/change-email/${id}/activate`)).data;
    },
    async getChangeEmailStatus(id: string) {
        return (await api.get<IResponseMessage>(`/user/change-email/${id}`)).data;
    },
};
