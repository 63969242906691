export enum MediaActionsType{
    START_UPLOAD_MEDIA = 'START_UPLOAD_MEDIA',
    ERROR_UPLOAD_MEDIA = 'ERROR_UPLOAD_MEDIA',

    UPLOADED_IMAGE_MEDIA = 'UPLOADED_IMAGE_MEDIA',
    UPLOADED_IMAGES_MEDIA = 'UPLOADED_IMAGES_MEDIA',

    REMOVE_IMAGE_MEDIA = 'REMOVE_IMAGE_MEDIA',

    CLEAR_MEDIA = 'CLEAR_MEDIA',
}