import styled from "styled-components"

export const MainContainer = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 1530px;
  box-sizing: border-box;
  font-family: 'Source Sans Pro';
`
export const ContainerFluid = styled.div`
  font-family: 'Source Sans Pro';
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  
  
`
