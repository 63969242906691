import React, { useEffect, useState } from 'react';
import AccordionElement from '@ui/AccordionElement';
import styled from 'styled-components';
import StyledButton from '@ui/StyledButton';
import { useTypedSelector } from '@hooks/useSelector';
import TextFields from '@ui/TextFields';
import { useUsersActions } from '@hooks/useActions';
import { MainContainer } from '@ui/mainContainer';
import Layout from '@components/layout';
import UserCard from '@/components/users/userCard';
import Modal from '@ui/components/modals/Modal';
import WarningModal from '@ui/components/modals/WarningModal';
import BanUserModal from '@/ui/components/modals/BanUserModal';
import EditUserModal from '@/ui/components/modals/EditUserModal';
import Tooltip from '@/ui/Tooltip';

const UsersPage = () => {
    const { users } = useTypedSelector((state) => state.usersReducer);

    const { getUsers, banUser, unbanUser, changeRole } = useUsersActions();

    const [showActions, setShowActions] = useState({
        userId: '',
        show: false,
    });

    const [showEditWarning, setShowEditWarning] = useState(false);

    const [showBanWarning, setShowBanWarning] = useState(false);

    const handleSetShowActions = (userId: string) => {
        setShowActions((prev) =>
            prev.userId === userId
                ? {
                      userId: userId,
                      show: !prev.show,
                  }
                : { userId: userId, show: true }
        );
    };

    const onBanHandler = async () => {
        if (users) {
            const user = users.find((user) => user.firebaseId === showActions.userId);

            if (user && user.isBanned === false) {
                banUser(showActions.userId);
            } else {
                unbanUser(showActions.userId);
            }
        }
    };

    const changeRoleHandler = async (role: string) => {
        changeRole(showActions.userId, role);
    };

    useEffect(() => {
        getUsers();
    }, []);


    return (
        <Layout>
            {users && (
                <Modal
                    marginTop={'100px'}
                    width={'360px'}
                    show={showEditWarning}
                    setShow={setShowEditWarning}
                    ModalElement={() =>
                        EditUserModal({
                            saveAction: (role: string) => {
                                changeRoleHandler(role);
                                setShowEditWarning(false);
                            },
                            user: users.find((user) => user.firebaseId === showActions.userId),
                            subject: '',
                            closeModal: () => setShowEditWarning(false),
                        })
                    }
                />
            )}

            {users && (
                <Modal
                    marginTop={'100px'}
                    width={'360px'}
                    show={showBanWarning}
                    setShow={setShowBanWarning}
                    ModalElement={() =>
                        BanUserModal({
                            deleteAction: () => {
                                onBanHandler();
                                setShowBanWarning(false);
                            },
                            user: users.find((user) => user.firebaseId === showActions.userId),
                            subject: '',
                            closeModal: () => setShowBanWarning(false),
                        })
                    }
                />
            )}

            <MainContainer>
                <Content>
                    <Title>Users</Title>

                    <UserInfoCardsWrapper>
                        {users &&
                            users.map((user, id) => (
                                <UserCard
                                    user={user}
                                    showActions={showActions.userId === user.firebaseId && showActions.show}
                                    setShowActions={handleSetShowActions}
                                    setShowBanWarning={setShowBanWarning}
                                    setShowEditWarning={setShowEditWarning}
                                    key={id}
                                />
                            ))}
                    </UserInfoCardsWrapper>
                </Content>
            </MainContainer>
        </Layout>
    );
};

const Title = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 136%;
    margin-bottom: 50px;
`;

const Content = styled.div`
    margin-top: 20px;
`;

const UserInfoCardsWrapper = styled.div`
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
`;

const CardBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    min-height: 246px;
    width: 100%;
    max-width: 360px;
    padding: 17px 20px;
    height: auto;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 0 0 6px 6px;
    @media (max-width: 600px) {
        padding: 8px;
        min-height: auto;
    }
`;

const UserCardFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const UserCardFieldInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: flex-start;
`;

const UserCardFieldInfoTitle = styled.div`
    font-weight: 700;
    width: 30%;
`;
const UserCardFieldInfo = styled.div`
    width: 70%;
    text-align: left;
`;

const HeaderWrapper = styled.div`
    background: #9fabd9;
    box-shadow: 0 3px 14px -2px rgba(91, 115, 207, 0.24);
    border-radius: 6px 6px 0 0;
    color: white;
    height: 50px;
    padding: 17px 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column ;
    @media (max-width: 600px) {
        // background: white;
        // color: #0b1126;
        // box-shadow: none;
        height: auto;
        padding: 8px;
        align-items: center;
        justify-content: start;
    }
`;

export default UsersPage;
