import React from 'react';
import styled from "styled-components";
import Close from "@assets/svgs/modal_close.svg"

type StyledCloseButtonProps = {
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void,

}
const StyledCloseButton = (props: StyledCloseButtonProps) => {
    return (
        <Wrapper>
            <Button draggable={false} src={Close} onClick={props.onClick}/>
        </Wrapper>
    );
};

export default StyledCloseButton;
export const Wrapper = styled.div`
  

`
export const Button = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;

`
