import React, { useEffect, useState } from 'react';
import CheckboxComponent from '@ui/CheckboxComponent';
import StyledButton from '@ui/StyledButton';
import PlusIMG from '@assets/svgs/Plus.svg';
import DeleteItemIMG from '@assets/svgs/createProduct_deleteImg_icon.svg';
import TextFields from '@ui/TextFields';
import styled from 'styled-components';
import Modal from '@ui/components/modals/Modal';
import DownloadImage from '@ui/components/modals/DownloadImage';
import { useTypedSelector } from '@hooks/useSelector';
import { amountCheck, priceCheck } from '@/helpers/checkersInput';
import { IMainData, IMediaProductData, IProductData, IShopData } from '@api/product/productType';
import { IErrorMain, IErrorShop } from '@/types';
import { useSnakeBarAction } from '@hooks/useActions';
import { SnakeTypes } from '@ui/components/modals/SnakeBar';
import EditorField from '@components/products/EditorField';
import { roundToTwoDecimals, transformNumber } from '@/helpers/round';
import CurrencyTextField from '@/ui/CurrencyTextField';

export interface IShopError {
    amount: string;
}

interface IShopBlock {
    calcSellPrice: () => number;
    sellPrice: number | string | undefined;
    errorState: IErrorShop;
    shop: IShopData;
    setShop: (value: IShopData) => void;
    clearShopError: () => void;
    isCollectable: boolean;
    isFixedPrice: boolean;
    onChangeIsCollectable: () => void;
    calcDisplayPrice: () => number;
    isLiveFixed: boolean;
    product: IMainData;
    setMainError: (data: IErrorMain) => void;
    mainError: IErrorMain;
    clearMainError: () => void;
}

const ShopBlock: React.FC<IShopBlock> = ({
    shop,
    sellPrice,
    setShop,
    errorState,
    calcSellPrice,
    calcDisplayPrice,
    clearShopError,
    isCollectable,
    onChangeIsCollectable,
    isFixedPrice,
    product,
    isLiveFixed,
    setMainError,
    mainError,
    clearMainError,
}) => {
    const { imagesOrigin } = useTypedSelector((state) => state.mediaReducer);
    const { categoriesData } = useTypedSelector((state) => state.productReducer);

    const { showSnackbar } = useSnakeBarAction();

    const [currentMoneyType, setCurrentMoneyType] = useState('$');
    const [showModal, setShowModal] = useState(false);
    const [stockInShop, setStockInShop] = useState('0');

    const [error, setError] = useState<IErrorShop>(errorState);

    const deleteImage = (url: string) => {
        setShop({ ...shop, medias: shop.medias?.filter((item) => item.url !== url) });
    };

    useEffect(() => {
        setError(errorState);
    }, [errorState]);

    useEffect(() => {
        if (!shop?.medias) {
            setShop({
                ...shop,
                medias: [...imagesOrigin.map((item, index): IMediaProductData => ({ order: index, url: item }))],
            });
        } else {
            if (shop.medias.length + imagesOrigin.length <= 5) {
                let newMedia = [...shop.medias.map((item) => item.url), ...imagesOrigin];
                setShop({
                    ...shop,
                    medias: [...newMedia.map((item, index): IMediaProductData => ({ order: index, url: item }))],
                });
            } else {
                showSnackbar({ content: 'You try add more than 5 images to shop', type: SnakeTypes.error });
            }
        }
    }, [imagesOrigin]);

    const changeVisible = () => {
        clearMainError();
        if ((!product?.weight || product?.weight === 0) === true && (!product?.purity || product?.purity === 0) === true) {
            setMainError({
                ...mainError,
                weight: 'Please fill in the field “Weight”. It is required to work with the prices ',
                purity: 'Please fill in the field "Purity". It is required to work with the prices',
            });
            return;
        }
        if (!product?.weight || product?.weight === 0) {

            setMainError({ ...mainError, weight: 'Please fill in the field “Weight”. It is required to work with the prices', purity: '' });
            return;
        }
        if (!product?.purity || product?.purity === 0) {

            setMainError({ ...mainError, purity: 'Please fill in the field "Purity". It is required to work with the prices', weight: '' });
            return;
        }
        setShop({
            ...shop,
            is_visible: !shop?.is_visible,
            is_fixed_price: isFixedPrice,
            price: isFixedPrice ? sellPrice : calcDisplayPrice(),
        });
        clearShopError();
    };

    useEffect(() => {
        if (stockInShop === '0') {
            setStockInShop(shop?.amount_all && shop?.amount_limit !== undefined ? shop.amount_all - shop.amount_limit + '' : '0');
        }
    }, [shop]);

    return (
        <Block>
            <Modal marginTop={'100px'} show={showModal} setShow={setShowModal} ModalElement={DownloadImage} />
            <BlockTitle>Shop</BlockTitle>
            <CheckContainer>
                <CheckboxComponent
                    value={shop?.is_visible}
                    onClick={() => {
                        changeVisible();
                    }}
                    width={'18px'}
                    height={'18px'}
                />
                <span
                    onClick={() => {
                        changeVisible();
                    }}
                    style={{ marginBottom: 1, cursor: 'pointer' }}
                >
                    Display the product in Shop
                </span>
            </CheckContainer>
            <SmallTitle>Category</SmallTitle>
            <ShopContainer>
                <SelectContainer>
                    <div>
                        <select
                            onChange={(e) => {
                                setShop({ ...shop, category_id: e.target.value });
                                setError({ ...error, category_id: '' });
                            }}
                            value={shop?.category_id}
                            style={{
                                width: '582px',
                                fontWeight: 400,
                                fontSize: '20px',
                                lineHeight: '20px',
                                height: 56,
                            }}
                        >
                            <option value={''}>Select category...</option>
                            {categoriesData?.map((item, index) => {
                                return (
                                    <option key={`shopCategory${index}`} value={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                        {
                            <ErrorWrap>
                                {error.category_id.length > 0 && (
                                    <div
                                        style={{
                                            color: '#D92E23',
                                            fontSize: 14,
                                            marginTop: 3,
                                        }}
                                    >
                                        {error.category_id}
                                    </div>
                                )}
                            </ErrorWrap>
                        }
                    </div>
                    <CheckContainer>
                        <CheckboxComponent
                            value={isCollectable}
                            onClick={() => {
                                onChangeIsCollectable();
                            }}
                            disabled={isCollectable}
                            width={'18px'}
                            height={'18px'}
                        />
                        <span
                            onClick={() => {
                                onChangeIsCollectable();
                            }}
                            style={{ marginBottom: 1, cursor: 'pointer' }}
                        >
                            Collectable
                        </span>
                    </CheckContainer>
                </SelectContainer>
                <div>
                    <SmallTitle>Images (max 5 images), Formats: [jpeg, png, jpg], Max Size: 5 MB</SmallTitle>
                    <AddButton>
                        {shop?.medias?.map((image, index) => {
                            return (
                                <div
                                    style={{
                                        marginRight: 25,
                                    }}
                                    key={`item${index}`}
                                >
                                    <img
                                        style={{
                                            position: 'absolute',
                                            marginLeft: 125,
                                            cursor: 'pointer',
                                            backgroundColor: '#d3d3d3a3',
                                        }}
                                        draggable={false}
                                        src={DeleteItemIMG}
                                        alt={'delete'}
                                        onClick={() => deleteImage(image.url)}
                                    />
                                    <img draggable={false} height={150} width={150} src={image.url} alt={`item${index}`} />
                                </div>
                            );
                        })}
                        <StyledButton onClick={() => setShowModal(true)} backgroundColor={'#5B73CF'} width={'44px'} height={'44px'}>
                            <img src={PlusIMG} alt={'Plus'} />
                        </StyledButton>
                    </AddButton>
                </div>
                <PriceBlock>
                    <PriceFields>
                        {!isFixedPrice || !isCollectable ? (
                            isLiveFixed ? (
                                <div>
                                    <Price id={'Price'}>{`${currentMoneyType} ${roundToTwoDecimals(
                                        typeof sellPrice === 'number' ? sellPrice : undefined
                                    )}`}</Price>
                                    <Input_active height={'58px'}>Price</Input_active>
                                </div>
                            ) : (
                                <div>
                                    <Price id={'Price'}>{`${currentMoneyType} ${roundToTwoDecimals(calcDisplayPrice())}`}</Price>
                                    <Input_active height={'58px'}>Price</Input_active>
                                </div>
                            )
                        ) : (
                            <CurrencyTextField
                                decimalScale={2}
                                prefix={currentMoneyType + ' '}
                                step="0.01"
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onChange={({ floatValue }) => {
                                    setError({ ...error, price: '' });

                                    if (!floatValue) {
                                        setShop({
                                            ...shop,
                                            price: 0,
                                        });
                                    } else {
                                        setShop({
                                            ...shop,
                                            price: floatValue,
                                        });
                                    }
                                }}
                                value={String(shop?.price)}
                                // disabled={!lpl?.is_visible || isCollectable}
                                placeholder="Price"
                                height={'56px'}
                                textError={error.price}
                            />
                        )}
                    </PriceFields>
                    <PriceFields>
                        <TextFields
                            onChange={(e) => {
                                const currentValue = e.target.value;
                                setError({ ...error, amount_all: '' });
                                if (amountCheck(currentValue)) return;
                                setShop({
                                    ...shop,
                                    amount_all: Number(currentValue),
                                    amount_limit: 0,
                                });
                            }}
                            placeholder={'Stock in Shop'}
                            value={shop?.amount_all?.toString() ? shop.amount_all.toString() : ''}
                            type={'text'}
                            height={'56px'}
                            textError={error.amount_all}
                        />
                    </PriceFields>
                </PriceBlock>
                <div>
                    <DescriptionContainer>
                        <EditorField
                            data={shop?.description?.toString() ? shop.description?.toString() : ''}
                            fieldName={'Description'}
                            onChangeValue={(value) => setShop({ ...shop, description: value })}
                        />
                    </DescriptionContainer>
                </div>
                <div>
                    <DescriptionContainer>
                        <EditorField
                            data={shop?.additional_info?.toString() ? shop.additional_info?.toString() : ''}
                            fieldName={'Additional Info'}
                            onChangeValue={(value) => setShop({ ...shop, additional_info: value })}
                        />
                    </DescriptionContainer>
                </div>
            </ShopContainer>
        </Block>
    );
};

const SelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
`;

const Input_active = styled.div<{ height: string }>`
    transform: scale(0.75) translateY(calc(${(props) => props.height} / -0.635 + 0.1 * ${(props) => props.height}));
    //transition: transform 0.15s;
    position: absolute;
    color: #000;
    background-color: #fff;
    margin-left: 10px;
    //left: 41px;
    font-style: normal;
    line-height: 16px;
    font-weight: 400;
`;

const Price = styled.div`
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
    border: #b6b6b6 solid 1px;
    border-radius: 4px;
    font-weight: 400;
    line-height: 16px;
    font-size: 16px;
    //margin-bottom: 10px;
`;

const ErrorWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const AddButton = styled.div`
    margin-top: 10px;
    display: flex;
`;

const DescriptionContainer = styled.div`
    max-width: 1463px;
    width: 100%;
`;

const PriceFields = styled.div`
    width: 330px;
`;

const PriceBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
`;

const ShopContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const SmallTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`;

const CheckContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const BlockTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
`;

const Block = styled.div`
    max-height: 1400px;
    padding: 20px 0 25px 30px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export default ShopBlock;
