import React, { useEffect, useState } from 'react';
import TextFields from '@ui/TextFields';
import MetalTypes from '@components/products/MetalTypes';
import MetalParameters from '@components/products/MetalParameters';
import styled, { isStyledComponent } from 'styled-components';
import { IErrorMain } from '@/types';
import { IMainData, IMetal } from '@api/product/productType';
import { useTypedSelector } from '@hooks/useSelector';
import { useProductActions } from '@hooks/useActions';
import { useLocation, useNavigate } from 'react-router-dom';

interface IMainBlock {
    weightType: string;
    currentData: IMainData;
    errorState: IErrorMain;
    setCurrentData: (value: IMainData) => void;
    isCollectable: boolean;
    isFixed: boolean;
}

const MainBlock: React.FC<IMainBlock> = ({ currentData, errorState, setCurrentData, weightType, isCollectable, isFixed }) => {
    const location = useLocation();

    const { metals } = useTypedSelector((state) => state.productReducer);
    const { getCategoriesWithOutPagination } = useProductActions();

    const [errorName, setErrorName] = useState(errorState.name);
    const [currentMetal, setCurrentMetal] = useState(currentData.metal ? currentData.metal.name.replace(/^\w/, (c) => c.toUpperCase()) : 'Gold');

    useEffect(() => {
        setErrorName(errorState.name);
    }, [errorState]);

    useEffect(() => {
        if (currentData.metal) {
            const curValue = metals.find((item) => item.id === currentData.metal_id) as IMetal;
            setCurrentMetal(currentData.metal.name.replace(/^\w/, (c) => c.toUpperCase()));
            getCategoriesWithOutPagination(currentData.metal.id);
        } else {
            if (!location.pathname.includes('update')) {
                const metalId = metals?.find((item) => item.name.toLocaleLowerCase() === 'gold')?.id;
                if (!metalId) return;
                getCategoriesWithOutPagination(metalId);
            }
            /*            if(currentData.metal_id){
                getCategoriesWithOutPagination(currentData.metal_id)
            }*/
        }
    }, [metals, currentData.metal]);

    return (
        <Block>
            <BlockTitle>Main</BlockTitle>
            <ProductContainer>
                <TextFieldContainer>
                    <TextFields
                        onChange={(e) => {
                            setErrorName('');
                            setCurrentData({ ...currentData, name: e.target.value });
                        }}
                        // disabled={isCollectable || isFixed}
                        value={currentData.name}
                        placeholder={'Product Name'}
                        type={'text'}
                        height={'56px'}
                        textError={errorName}
                    />
                </TextFieldContainer>
                <MetalTypes
                    onClick={(newMetal) => {
                        // if (isCollectable || isFixed) return;
                        const curValue = metals.find((item) => item.name.toLowerCase() === newMetal.toLowerCase()) as IMetal;
                        setCurrentMetal(newMetal);
                        setCurrentData({ ...currentData, metal_id: curValue.id, metal: curValue });
                    }}
                    currentMetal={currentMetal}
                />
            </ProductContainer>
            <MetalParameters
                weightType={weightType}
                errorState={errorState}
                params={{ ...currentData }}
                setData={(value) => setCurrentData({ ...currentData, ...value })}
                isDisabled={isCollectable || isFixed}
            />
        </Block>
    );
};

const TextFieldContainer = styled.div`
    max-width: 580px;
    width: 100%;
    margin-bottom: -5px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-top: -10px;
    gap: 50px;
    margin-bottom: 20px;
`;

const BlockTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
`;

const Block = styled.div`
    padding: 20px 0 50px 30px;
    max-height: 398px;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export default MainBlock;
