import React from 'react';
import styled from "styled-components";

interface IMetalTypes {
    currentMetal?: string | undefined
    onClick: (value: string) => void
}

const METALS = ['Gold', 'Silver', 'Platinum', 'Palladium', 'Copper']

const MetalTypes: React.FC<IMetalTypes> = ({currentMetal, onClick}) => {
    return (
        <MetalTypeContainer>
            <MetalTitle>
                Metal
            </MetalTitle>
            <TypeContainer>
                {
                    METALS.map((item, index) => {
                        return <MetalType key={`item${index}`} onClick={() => onClick(item)} isChosen={item === currentMetal}>
                            {
                                item
                            }
                        </MetalType>
                    })
                }
            </TypeContainer>
        </MetalTypeContainer>
    );
};


const MetalTitle = styled.div`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
`

interface IMetalType {
    isChosen?: boolean
}

const MetalType = styled.div<IMetalType>`
  border: 2px solid #5B73CF;
  box-sizing: border-box;
  border-radius: 35px;
  color: ${props => props.isChosen ? '#FFFFFF' : '#5B73CF'};
  padding: 12px 21px;
  background-color: ${props => props.isChosen ? '#5B73CF' : '#FFFFFF'};
  cursor: pointer;
`

const TypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`

const MetalTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default MetalTypes;