import { IErrors } from '@/types';
import { OrdersActionTypes } from '../constants/ordersContants';
import { ordersActions } from '../types/ordersTypes';
import { Order, Orders } from '@/api/orders/ordersTypes';

interface IOrdersState {
    orders: Order[] | undefined;
    loading: boolean;
    error: IErrors[] | string | undefined;
    message: string | undefined;
}

const initialState: IOrdersState = {
    orders: [],
    loading: false,
    message: undefined,
    error: undefined,
};

const startLoading = (state: IOrdersState) => {
    return { ...state, loading: true, error: undefined };
};

const stopErrorLoading = (state: IOrdersState, error: IErrors[]) => {
    return { ...state, loading: false, error };
};

const ordersReducer = (state = initialState, action: ordersActions): IOrdersState => {
    switch (action.type) {
        case OrdersActionTypes.GET_ORDERS_ACTION:
            return { ...state, orders: action.payload };
        case OrdersActionTypes.ERROR_ORDERS_ACTION:
            return stopErrorLoading(state, action.payload);
        default:
            return state;
    }
};

export default ordersReducer;
