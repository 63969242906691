export const roundToTwoDecimals = (num: number | undefined) => {
    if (num) {
        // const rounded = Math.ceil((num + Number.EPSILON) * 100) / 100;
        // return rounded.toFixed(2);
        return num.toFixed(2);
    } else {
        return '';
    }
};

export const transformNumber = (num: number) => {
    const strNum = num.toString();
    const decimalIndex = strNum.indexOf('.');
    if (decimalIndex === -1 || decimalIndex + 2 >= strNum.length - 1) {
        return num;
    }
    const newStrNum = strNum.substring(0, decimalIndex + 2) + strNum.substring(decimalIndex + 3);
    return parseFloat(newStrNum);
};
