const MIN_CATEGORY_LEN = 6
const MAX_CATEGORY_LEN = 31

export enum categoryType {
    name,
    metalType
}

export const validateCategory = (value: string, fieldType: categoryType): string => {
    switch (fieldType) {
        case categoryType.name: {
            if (value !== '') {
                let categoryNameValid = value.match(new RegExp(/^[a-z0-9“!#$%&'*+—/=?^_ `{|}~”]{6,31}$/i))
                if (value.length < MIN_CATEGORY_LEN || value.length > MAX_CATEGORY_LEN) {
                    return `The field “Category name” has too ${value.length < MIN_CATEGORY_LEN ? 'few' :
                        'many'} characters`
                }
                if (!categoryNameValid) {
                    return `The field “Category name” is filled in incorrectly`
                }
                return ''
            } else {
                return 'Please fill the field “Category name”'
            }
        }
        case categoryType.metalType: {
            if (value !== '') {
                return ''
            } else {
                return 'something went wrong, please reload the page'
            }
        }
        default: {
            return ''
        }
    }
}