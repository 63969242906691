import { AxiosError } from 'axios';
import {
    IClearUserState,
    IErrorUserAction,
    IFirstStepLoginSuccess,
    IInitialized,
    IRefreshToken,
    IRegistrationSuccess,
    ISecondStepLoginSuccess,
    IStartUserAction,
    userAction,
} from '@store/types/userTypes';
import { UserActionTypes } from '@store/constants/userConstants';
import {
    IRequestSignUpJuristicWholesalerUser,
    IRequestSignUpPrivateUser,
    IResponseMessage,
    IUserData,
    IUserDataUpdate,
    IUserLogin,
} from '@api/user/userTypes';
import { Dispatch } from 'redux';
import { userAPI } from '@api/user/userApi';
import { MAIN_DOMAIN } from '@/api';
import FirebaseService from '@/services/firebase-service';
import { MultiFactorError } from 'firebase/auth';
import { showSnackbar } from './snackbarActions';
import { SnakeTypes } from '@/ui/components/modals/SnakeBar';

enum Keys {
    accessToken = 'accessToken',
    id = 'id',
    time = 'time',
    firebaseToken = 'firebaseToken',
}

const startUserAction = (): IStartUserAction => ({
    type: UserActionTypes.START_USER_ACTION,
});

const errorUserAction = (payload: string): IErrorUserAction => ({
    type: UserActionTypes.ERROR_USER_ACTION,
    payload,
});

const setResponseMessage = (payload: IResponseMessage): IRegistrationSuccess => ({
    type: UserActionTypes.REGISTRATION_SUCCESS,
    payload,
});

export const initialize = (): IInitialized => ({
    type: UserActionTypes.INITIALIZED,
});

export const refreshToken = () => async (dispatch: Dispatch<userAction>) => {
    try {
        const response = await userAPI.refreshToken();
        localStorage.setItem(Keys.firebaseToken, response.access_token);
        return true;
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
        return false;
    }
};

export const registrationNaturalUser = (data: IRequestSignUpPrivateUser) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.singUpPrivateUser(data);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const registrationJuristicWholesalerUser = (data: IRequestSignUpJuristicWholesalerUser) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.signUpJuristicWholesaler(data);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const activateUser = (id: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.activateUser(id);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

const firstStepLogIn = (payload: { MFE: MultiFactorError }): IFirstStepLoginSuccess => ({
    type: UserActionTypes.LOGIN_FIRST_STEP_SUCCESS,
    payload,
});

const setUserData = (payload: IUserData): ISecondStepLoginSuccess => ({
    type: UserActionTypes.LOGIN_SECOND_STEP_SUCCESS,
    payload,
});

export const logInFirstStep = (data: IUserLogin) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            dispatch(startUserAction());
            const firebaseResponse = await FirebaseService.auth.email.signIn(data.email, data.password);
            if (firebaseResponse.user.emailVerified === false || !firebaseResponse.user.phoneNumber) {
                dispatch(
                    showSnackbar({ type: SnakeTypes.error, content: 'Please verify your email and phone number on the main dashboard.', ms: 4000 })
                );
                dispatch(clearState());

                return;
            }
            // dispatch(firstStepLogIn());
        } catch (_e: any) {
            const e = _e as any;
            if (e.code === 'auth/too-many-requests') {
                dispatch(showSnackbar({ type: SnakeTypes.error, content: 'Too many attempts. Try again later', ms: 4000 }));
                return;
            }


            if (e.code === 'auth/multi-factor-auth-required') {
                const MFE = _e as MultiFactorError;
                dispatch(firstStepLogIn({ MFE }));
                return;
            }

            dispatch(errorUserAction(e.message));
        }
    };
};

export const logInSecondStep = (code: number) => async (dispatch: Dispatch<userAction>) => {
    try {
        const id = localStorage.getItem(Keys.id);
        if (!id) {
            dispatch(errorUserAction('First step not done'));
            dispatch(clearState());
            return;
        }
        dispatch(startUserAction());
        const response = await userAPI.signInStepTwo({ id, code });
        dispatch(setUserData(response.user));
        localStorage.setItem(Keys.accessToken, response.access_token);
        localStorage.removeItem(Keys.id);
    } catch (_e) {
        const e = _e as Error;
        dispatch(clearState());
        dispatch(errorUserAction(e.message));
    }
};

export const getUser = () => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.getCurrentUser();
        if (response.role === 'super-admin' || response.role === 'admin') {
            dispatch(setUserData(response));
        } else {
            localStorage.removeItem(Keys.firebaseToken);
            window.location.replace(MAIN_DOMAIN);
            return;
        }
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
    dispatch(initialize());
};

export const clearState = (): IClearUserState => ({
    type: UserActionTypes.CLEAR_STATE_USER,
    payload: undefined,
});

export const logout = () => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        //await userAPI.logoutUser()
        dispatch(clearState());
        // localStorage.removeItem(Keys.firebaseToken);
        // window.location.reload();
        //window.location.replace(MAIN_DOMAIN)
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const logInDev = async (data: IUserLogin) => async (dispatch: Dispatch<userAction>) => {

    try {
        dispatch(startUserAction());
        // const response = await userAPI.signInDev(data);

        // const response = await userAPI.signInFirebase(data);
        // dispatch(setUserData(response.user));
        // localStorage.setItem(Keys.firebaseToken, response.access_token);
        const firebaseResponse = await FirebaseService.auth.email.signIn(data.email, data.password);
        if (firebaseResponse) {
            const idToken = await firebaseResponse.user.getIdToken();
            const uid = firebaseResponse.user.uid;
            localStorage.setItem(Keys.firebaseToken, idToken);

            if (firebaseResponse.user.emailVerified === false) {
                return;
            }
            const response = await userAPI.signInFirebase({ uid });

            dispatch(setUserData(response.user));
        }
    } catch (_e) {
        const e = _e as AxiosError;
        let error = e.response?.data.message;

        if (typeof error === 'string') {
            error = [
                {
                    property: '',
                    code: error,
                    trace: [''],
                },
            ];
        }

        error[0].code = 'Incorrect login or password';
        /*        let errorText: string*/
        /*        if (e.response?.status === 404) {
            errorText = e.response.data.message
        } else {
            errorText = e.response?.data.message[0].code
        }*/
        dispatch(errorUserAction(error));
    }
};

export const restorePasswordSessionCreate = (email: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.resetPasswordSessionCreate(email);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const restorePasswordActivate = (id: string, password: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        await userAPI.getCurrentStateResetSession(id);
        const response = await userAPI.resetPasswordActivate(id, password);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const clearUserError = (): any => ({
    type: UserActionTypes.CLEAR_USER_ERROR,
});

export const clearMessage = (): any => ({
    type: UserActionTypes.CLEAR_USER_MESSAGE,
});

export const updateUserInfo = (data: IUserDataUpdate) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.updateUserData(data);
        dispatch(setUserData(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const changeUserPassword = (oldPassword: string, newPassword: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.changePassword({ old_password: oldPassword, new_password: newPassword });
        dispatch(setUserData(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const updateUserEmailSessionCreate = (newEmail: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        const response = await userAPI.changeEmailSessionCreate(newEmail);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};

export const updateUserEmailSessionActivate = (id: string) => async (dispatch: Dispatch<userAction>) => {
    try {
        dispatch(startUserAction());
        await userAPI.getChangeEmailStatus(id);
        const response = await userAPI.changeEmailActivate(id);
        dispatch(setResponseMessage(response));
    } catch (_e) {
        const e = _e as Error;
        dispatch(errorUserAction(e.message));
    }
};
