import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import {
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    User,
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    reauthenticateWithCredential,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updatePassword,
    ApplicationVerifier,
    multiFactor,
    PhoneMultiFactorGenerator,
    MultiFactorError,
    getMultiFactorResolver,
    MultiFactorResolver,
    PhoneAuthProvider,
} from 'firebase/auth';
import config from '../config';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '@/store/actions/snackbarActions';
import { SnakeTypes } from '@/ui/components/modals/SnakeBar';

const firebaseConfig = config.FIREBASE_CONFIG;

const app = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const FirebaseService = {
    auth: {
        email: {
            signUp: async (email: string, password: string) => {
                try {
                    const { user } = await createUserWithEmailAndPassword(auth, email, password);
                    return user;
                } catch (error) {
                    console.error(error);
                }
            },
            signIn: async (email: string, password: string) => {
                try {
                    const userData = await signInWithEmailAndPassword(auth, email, password);
                    return userData;
                } catch (error) {
                    // return error;
                    throw error;
                }
            },
            handleMFA: async (
                error: MultiFactorError,
                recaptchaVerifier: ApplicationVerifier,
                selectedIndex: number
            ): Promise<false | { verificationId: string; resolver: MultiFactorResolver } | void | 'too-many-attempts'> => {
                const resolver = getMultiFactorResolver(auth, error);

                if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[selectedIndex],
                        session: resolver.session,
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(auth);

                    try {
                        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                        return { verificationId, resolver };
                    } catch (error: any) {
                        if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                            return 'too-many-attempts';
                        }
                        if (error.message.split(' ').includes('(auth/too-many-requests).')) {
                            return 'too-many-attempts';
                        }
                        return false;
                    }
                }
            },
            handleMFASubmit: async (verificationMFA: { verificationId: string; resolver: MultiFactorResolver }, verificationCode: string) => {
                const { verificationId, resolver } = verificationMFA;

                const credentials = PhoneAuthProvider.credential(verificationId, verificationCode);

                const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

                try {
                    const result = await resolver.resolveSignIn(multiFactorAssertion);
                    return true;
                } catch (error: any) {
                    if (error.code === 'auth/invalid-verification-code') {
                        return 'invalidCode';
                    }
                    if (error.code === 'auth/code-expired') {
                        return 'sessionExpired';
                    }
                    if (error.code === 'auth/missing-code') {
                        return 'missingCode';
                    }
                    if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                        return 'too-many-attempts';
                    }
                    return false;
                }
            },
            verifyIfUserMFA: async (user: User) => {
                const enrolledFactors = multiFactor(user).enrolledFactors;
                return enrolledFactors.length > 0;
            },
            verifyPhoneNumber: async (phoneNumber: string, recaptchaVerifier: ApplicationVerifier) => {
                const user = auth.currentUser;
                if (!user) {
                    return false;
                }

                const session = await multiFactor(user).getSession();
                const phoneInfoOptions = {
                    phoneNumber,
                    session,
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                try {
                    return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                } catch (error: any) {
                    if (error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN' || error.code === 'auth/requires-recent-login') {
                        return 'relogin';
                    }
                    if (error.code === 'auth/code-expired') {
                        return 'codeExpired';
                    }
                    if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
                        return 'too-many-attempts';
                    }
                    return false;
                }
            },
            // enrollMFA: async (verificationCode: string, verificationCodeId: string) => {
            //     const user = auth.currentUser;
            //     if (!user) {
            //         return false;
            //     }

            //     const phoneAuthCredential = PhoneAuthProvider.credential(verificationCodeId, verificationCode);
            //     const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);

            //     try {
            //         await multiFactor(user).enroll(multiFactorAssertion, 'Personal Phone Number');
            //         await userAPI.activatePhone();
            //         return true;
            //     } catch (error: any) {
            //         console.log(error);
            //         if (error.code === 'auth/invalid-verification-code') {
            //             return 'invalidCode';
            //         }
            //         if (error.code === 'auth/missing-code') {
            //             return 'missingCode';
            //         }
            //         if (error.message === 'TOO_MANY_ATTEMPTS_TRY_LATER' || error.code === 'auth/too-many-requests') {
            //             return 'too-many-attempts';
            //         }
            //         return false;
            //     }
            // },
        },
        google: {
            signIn: async () => {
                try {
                    const result = await signInWithPopup(auth, googleProvider);
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const user = result.user;
                    return { user, credential };
                } catch (error: any) {
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    console.error(error);
                }
            },
        },
        facebook: {
            signIn: async () => {
                try {
                    const result = await signInWithPopup(auth, facebookProvider);
                    const credential = FacebookAuthProvider.credentialFromResult(result);
                    const user = result.user;
                    return { user, credential };
                } catch (error: any) {
                    if (error?.customData?.email && error?.code === 'auth/account-exists-with-different-credential') {
                        return FirebaseService.auth.google.signIn();
                    }
                    console.error(error);
                }
            },
        },
        signOut: async () => {
            try {
                await signOut(auth);
                return true;
            } catch (error) {
                console.error(error);
            }
        },
        getAccessToken: () => {
            const token = auth.currentUser?.getIdToken();
            return token;
        },
        changePassword: async (oldPassword: string, newPassword: string) => {
            const user = auth.currentUser;
            try {
                await reauthenticate(oldPassword);
                if (user === null) return console.log(`User is null`);
                await updatePassword(user, newPassword);
            } catch (error: any) {
                console.error(error);
            }
        },
        sendVerificationEmail: async (user: User) => {
            try {
                const mail = await sendEmailVerification(user);
            } catch (error) {
                console.error(error);
            }
        },
    },
};

type AuthProviderProps = {
    children: any;
    onLogin?: () => void;
    onLogout?: () => void;
};

export const AuthProvider = (props: AuthProviderProps) => {
    const { children, onLogin = () => {}, onLogout = () => {} } = props;

    const [initialized, setInitialized] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                if (firebaseUser.emailVerified === false || !firebaseUser.phoneNumber) {
                    dispatch(
                        showSnackbar({
                            type: SnakeTypes.error,
                            content: 'Please verify your email address or phone number on the dashboard.',
                            ms: 4000,
                        })
                    );
                    await FirebaseService.auth.signOut();
                    // window.location.reload();
                    return;
                }
                onLogin();
            } else {
                onLogout();
            }
            setInitialized(true);
        });
    }, []);

    if (!initialized) {
        return null;
    }

    return children;
};

export const reauthenticate = async (currentPassword: string) => {
    try {
        const user = auth.currentUser;
        if (user === null || user.email === null) return console.log(`User is null`);

        const cred = EmailAuthProvider.credential(user.email, currentPassword);
        return reauthenticateWithCredential(user, cred);
    } catch (error) {
        console.error(error);
    }
};

export default FirebaseService;
