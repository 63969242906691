import React, { useEffect, useState } from 'react';
import WarningIMG from '@assets/svgs/Warning.svg';
import StyledButton from '@ui/StyledButton';
import styled from 'styled-components';
import { User } from '@/api/users/usersTypes';

interface IWarningProps {
    saveAction: (role: string) => void;
    closeModal: () => void;
    subject?: string;
    user: User | undefined;
}

enum roles {
    admin = 'admin',
    natural = 'natural',
}

const EditUserModal: React.FC<IWarningProps> = ({ saveAction, closeModal, subject, user }) => {
    const [role, setRole] = useState<string>();

    useEffect(() => {
        setRole(user?.role);
    }, [user]);

    return user ? (
        <ContainerWarning>
            <ImageBlock>
                <img alt={'warning'} src={WarningIMG} />
            </ImageBlock>

            <Description>Change user info</Description>

            <ItemsContainer>
                <ItemTitle>Role</ItemTitle>
                <Roles value={role} onChange={(e) => setRole(e.target.value)}>
                    <Role value={roles.admin}>{roles.admin.toUpperCase()}</Role>
                    <Role value={roles.natural}>{roles.natural.toUpperCase()}</Role>
                </Roles>
            </ItemsContainer>

            <ButtonContainers>
                <CancelButton onClick={closeModal}>Cancel</CancelButton>
                <ButtonContainer>
                    <StyledButton height={'50px'} onClick={() => saveAction(role as string)}>
                        Save
                    </StyledButton>
                </ButtonContainer>
            </ButtonContainers>
        </ContainerWarning>
    ) : (
        <ContainerWarning>No user found</ContainerWarning>
    );
};

const ContainerWarning = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    gap: 30px;
`;

const ButtonContainer = styled.div`
    width: 150px;
`;

const ItemsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 60px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
`;

const CancelButton = styled.div`
    display: flex;
    text-decoration-line: underline;
    width: 150px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const ButtonContainers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
`;

const ImageBlock = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
`;

const ItemTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
`;

const Roles = styled.select`
    outline: none;
    height: 30px;
    font-size: 16px;
    border: none;
    border-bottom: 3px solid gray;
`;

const Role = styled.option``;

export default EditUserModal;
