import React, { useEffect, useState } from 'react';
import AccordionElement from '@ui/AccordionElement';
import styled from 'styled-components';
import StyledButton from '@ui/StyledButton';
import { useTypedSelector } from '@hooks/useSelector';
import TextFields from '@ui/TextFields';
import { useAdminActions, useUserActions } from '@hooks/useActions';
import { MainContainer } from '@ui/mainContainer';
import Layout from '@components/layout';

const StaticPages = () => {
    const { user } = useTypedSelector((state) => state.userReducer);
    const { pages } = useTypedSelector((state) => state.adminReducer);
    const { createPage, getPages, deletePage } = useAdminActions();
    const { getUser } = useUserActions();

    const [pageName, setPageName] = useState('');
    const [chosenPage, setChosenPage] = useState('');

    useEffect(() => {
        getPages();
        getUser();
    }, []);

    const onAddStaticPage = () => {
        createPage(pageName);
    };

    const onDeletePage = (pageName: string) => {
        deletePage(pageName);
    };

    return (
        <Layout>
            <MainContainer>
                <Content>
                    <Title>Information pages</Title>
                    <FieldContainer>
                        {pages.map((item) => {
                            return (
                                <AccordionElement
                                    onDelete={onDeletePage}
                                    onClick={() => {
                                        if (chosenPage === item.page) {
                                            setChosenPage('');
                                        } else {
                                            setChosenPage(item.page);
                                        }
                                    }}
                                    isOpen={item.page === chosenPage}
                                    key={item.id}
                                    namePage={item.page}
                                    content={item.content}
                                    userRole={user?.role}
                                />
                            );
                        })}
                    </FieldContainer>
                    {user?.role === 'super-admin' && (
                        <AddPageContainer>
                            <PageNameContainer>
                                <TextFields
                                    value={pageName}
                                    onChange={(e) => {
                                        setPageName(e.target.value);
                                    }}
                                    placeholder={'Page name'}
                                    textError={''}
                                    type={'text'}
                                    height={'46px'}
                                />
                            </PageNameContainer>
                            <ButtonContainer>
                                <StyledButton onClick={onAddStaticPage} height={'46px'}>
                                    Add static page
                                </StyledButton>
                            </ButtonContainer>
                        </AddPageContainer>
                    )}
                </Content>
            </MainContainer>
        </Layout>
    );
};

const FieldContainer = styled.div`
    width: 100%;
`;

const ButtonContainer = styled.div`
    margin-right: 10px;
    width: 200px;
`;
const PageNameContainer = styled.div`
    width: 200px;
    padding-left: 10px;
`;

const AddPageContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
`;

const Title = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 136%;
    margin-bottom: 50px;
`;

const Content = styled.div`
    margin-top: 20px;
`;

export default StaticPages;
