export enum UserActionTypes {
    INITIALIZED = 'INITIALIZED',

    CLEAR_USER_ERROR = 'CLEAR_USER_ERROR',
    CLEAR_USER_MESSAGE = 'CLEAR_USER_MESSAGE',

    REFRESH_TOKEN = 'REFRESH_TOKEN',

    START_USER_ACTION = 'START_USER_ACTION',
    ERROR_USER_ACTION = 'ERROR_USER_ACTION',

    CLEAR_STATE_USER = 'CLEAR_STATE_USER',

    REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',

    LOGIN_FIRST_STEP_SUCCESS = 'LOGIN_FIRST_STEP_SUCCESS',
    LOGIN_SECOND_STEP_SUCCESS = 'LOGIN_SECOND_STEP_SUCCESS',
}