import {
    IClearMedia,
    IErrorUploadMedia,
    IRemoveImage,
    IStartUploadMedia,
    IUploadedImage,
    IUploadedImages
} from "@store/types/mediaType";
import {MediaActionsType} from "@store/constants/mediaConstants";
import {Dispatch} from "redux";
import {AxiosError} from "axios";
import {mediaAPI} from "@api/media/mediaApi";
import {IResponseMediaImage} from "@api/media/mediaType";
import {showSnackbar} from "@store/actions/snackbarActions";
import {SnakeTypes} from "@ui/components/modals/SnakeBar";

const startUpLoadMedia = (): IStartUploadMedia => ({
    type: MediaActionsType.START_UPLOAD_MEDIA
})

const errorUploadMedia = (payload: string): IErrorUploadMedia => ({
    type: MediaActionsType.ERROR_UPLOAD_MEDIA,
    payload
})

const uploadedImage = (payload: IResponseMediaImage): IUploadedImage => ({
    type: MediaActionsType.UPLOADED_IMAGE_MEDIA,
    payload
})

export const clearMedia = (): IClearMedia => ({
    type: MediaActionsType.CLEAR_MEDIA
})

export const uploadImage = (file: File) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUpLoadMedia())
        if(file.size / 1024 / 1024 > 5) {           
            dispatch(showSnackbar({content: 'Max file size 5 MB', type: SnakeTypes.error}))
            dispatch(errorUploadMedia("Max file size 5 MB"))
            return;
        }
        const response = await mediaAPI.uploadImage(file)
        dispatch(uploadedImage(response))
        dispatch(showSnackbar({content: 'Image upload successfully'}))
    } catch (_e) {
        const e = _e as AxiosError   
        dispatch(showSnackbar({content: 'Something went wrong', type: SnakeTypes.error}))
        dispatch(errorUploadMedia(e.response?.data))
    }
}

const uploadedImages = (payload: IResponseMediaImage[]): IUploadedImages => ({
    type: MediaActionsType.UPLOADED_IMAGES_MEDIA,
    payload
})

export const uploadImages = (files: File[]) => async (dispatch: Dispatch<any>) => {
    try {
        dispatch(startUpLoadMedia())
        let images: IResponseMediaImage[] = []
        for (const file of files) {
            const response = await mediaAPI.uploadImage(file)
            images.push(response)
        }
        dispatch(uploadedImages(images))
        dispatch(showSnackbar({content: 'Images upload successfully'}))
    } catch (_e) {
        const e = _e as AxiosError
        dispatch(showSnackbar({content: 'Something went wrong', type: SnakeTypes.error}))
        dispatch(errorUploadMedia(e.response?.data))
    }
}

export const removeImage = (payload: string): IRemoveImage => ({
    type: MediaActionsType.REMOVE_IMAGE_MEDIA,
    payload
})