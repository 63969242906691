import React, {useState} from 'react';
import TextFields from "@ui/TextFields";
import deleteICON from "@assets/svgs/textfield_delete_icon.svg";
import StyledButton from "@ui/StyledButton";
import styled from "styled-components";

interface IFieldProps {
    fieldName: string
    data: string
    url: string | null
    userRole: string | undefined
    onDeleteHandler: (value: string) => void
    onSaveHandler: (fieldName: string, text: string, url?: string | null) => void
}

const Index: React.FC<IFieldProps> = ({fieldName, data, url, onSaveHandler, onDeleteHandler, userRole}) => {
    const [isChanged, setIsChanged] = useState(false)
    const [linkField, setLinkField] = useState({
        title: data ? data : '',
        url: url ? url : ''
    })

    return (
        <ImgFields>
            <TextFieldLinkTitleContainer>
                <TextFields onChange={e => {
                    setLinkField({...linkField, title: e.target.value})
                    setIsChanged(true)
                }} value={linkField.title ? linkField.title : ''} placeholder={fieldName} type={'text'}
                            height={'46px'}/>
            </TextFieldLinkTitleContainer>
            <TextFieldLinkContainer>
                <TextFields onChange={e => {
                    setLinkField({...linkField, url: e.target.value})
                    setIsChanged(true)
                }} value={linkField.url ? linkField.url : ''} placeholder={'Link'} type={'text'} height={'46px'}/>
            </TextFieldLinkContainer>
            {
                userRole === 'super-admin' && <DeleteICON onClick={() => {
                    onDeleteHandler(fieldName)
                }}>
                    <DelIcon width={26} height={26} src={deleteICON} alt="deleteIcon"/>
                </DeleteICON>
            }
            {
                isChanged && <StyledButton onClick={() => {
                    setIsChanged(false)
                    onSaveHandler(fieldName, linkField.title, linkField.url)
                }
                } height={'46px'} width={'100px'}>
                    Save
                </StyledButton>
            }
        </ImgFields>
    )
};

const ImgFields = styled.div`
  display: flex;
  gap: 20px;
`

const DelIcon = styled.img`
  padding: 5px;
  cursor: pointer;

  &:hover {
    border-radius: 30px;
    background-color: rgba(0.2, 0.2, 0.2, 0.1);
    transition: background 0.3s;
  }

  &:active {
    width: 34px;
    height: 34px;
  }
`

const DeleteICON = styled.div`
  display: flex;
  align-items: center;
`

const TextFieldLinkContainer = styled.div`
  width: 200px;
`
const TextFieldLinkTitleContainer = styled.div`
  width: 200px;
`

export default Index;