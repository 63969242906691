import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import TextFields from "@ui/TextFields";
import {FIELD_HEIGHT} from "@ui/components/modals/constants";
import MetalTypes from "@components/products/MetalTypes";
import StyledButton from "@ui/StyledButton";
import {IModalElement} from "@ui/components/modals/Modal";
import {useProductActions} from "@hooks/useActions";
import {useTypedSelector} from "@hooks/useSelector";
import {categoryType, validateCategory} from "@/helpers/validateCategory";
import {IResponseCategory} from "@api/product/productType";

interface IAddNewCategory extends IModalElement {
    isEdit?: boolean
    currentData?: IResponseCategory
    setCurrentData?: (value: IResponseCategory) => void
}

interface IErrorValidate {
    name: string
    metal_id: string
}

const AddNewCategory: React.FC<IAddNewCategory> = ({closeModal, isEdit, currentData, setCurrentData}) => {

    const {createCategory, updateCategory} = useProductActions()
    const {metals} = useTypedSelector(state => state.productReducer)

    const [categoryName, setCategoryName] = useState(currentData ? currentData.name : '')
    const [currentMetal, setCurrentMetal] = useState(currentData ?
        metals.find(item => item.id === currentData.metal_id)?.name.replace(/^\w/, c => c.toUpperCase()) : 'Gold')

    const [errorValidate, setErrorValidate] = useState<IErrorValidate>({
        name: '',
        metal_id: '',
    })

    const onSaveHandler = () => {
        const metal_id = metals.find(item => item.name.toLowerCase() === currentMetal?.toLowerCase())?.id
        const obj: IErrorValidate = {
            name: validateCategory(categoryName, categoryType.name),
            metal_id: validateCategory(metal_id ? metal_id : '', categoryType.metalType)
        }
        if (Object.values(obj).some(x => x !== '')) {
            setErrorValidate(obj)
            return
        }
        if (!metal_id) return;

        if (isEdit) {
            if (!currentData) return;
            updateCategory(currentData?.id, {
                name: categoryName === currentData.name ? undefined : categoryName,
                metal_id: metal_id === currentData.metal_id ? undefined : metal_id,
            })
        } else {
            createCategory({name: categoryName, metal_id})
        }
        if (closeModal) {
            closeModal()
        }
    }


    return (
        <>
            <ModalTitle>
                {
                    isEdit ?
                        "Edit category"
                        :
                        "Add new category"

                }
            </ModalTitle>
            <FieldContainer>
                <TextFields
                    value={categoryName}
                    onChange={(e) => {
                        setCategoryName(e.target.value)
                        setErrorValidate({...errorValidate, name: ''})
                    }}
                    placeholder={'Category name'}
                    type={'text'}
                    height={FIELD_HEIGHT}
                    textError={errorValidate.name}
                />
                <MetalTypes currentMetal={currentMetal} onClick={(value) => {
                    setCurrentMetal(value)
                }}/>
            </FieldContainer>
            <ButtonContainers>
                <CancelButton onClick={closeModal}>
                    Cancel
                </CancelButton>
                <ButtonContainer>
                    <StyledButton
                        onClick={onSaveHandler}
                        height={'50px'}
                        disabled={categoryName === ''}
                    >
                        Save
                    </StyledButton>
                </ButtonContainer>
            </ButtonContainers>
        </>
    );
};

const ButtonContainer = styled.div`
  width: 100%;
`

const CancelButton = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #F8C882;
  border: 1px solid #F8C882;
  box-sizing: border-box;
  border-radius: 6px;
`

const ButtonContainers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`

const ModalTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  text-align: center;

  color: #0B1126;
`

const FieldContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  @media (max-width: 768px) {
    width: 435px;
  }
  @media (max-width: 500px) {
    width: 324px;
  }
  @media (max-width: 375px) {
    width: 260px;
  }
`

export default AddNewCategory;