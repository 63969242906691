import React, {useState} from 'react';
import TextFields from "@ui/TextFields";
import deleteICON from "@assets/svgs/textfield_delete_icon.svg";
import StyledButton from "@ui/StyledButton";
import styled from "styled-components";

interface IFieldProps {
    fieldName: string
    data: string
    userRole: string | undefined
    onDeleteHandler: (value: string) => void
    onSaveHandler: (fieldName: string, text: string, url?: string | null) => void
}

const Index: React.FC<IFieldProps> = ({fieldName, data, onDeleteHandler, onSaveHandler, userRole}) => {
    const [isChanged, setIsChanged] = useState(false)
    const [text, setText] = useState(data)
    return (
        <FieldSaveContainer>
            <TextFieldContainerHeader>
                <TextFields
                    onChange={e => {
                        setText(e.target.value)
                        setIsChanged(true)
                    }}
                    value={text ? text.replace(';quest;', '?') : ''} placeholder={fieldName} type={'text'}
                    height={'46px'}/>
            </TextFieldContainerHeader>
            {
                userRole === 'super-admin' && <DeleteICON onClick={() => {
                    onDeleteHandler(fieldName)
                }}>
                    <DelIcon width={26} height={26} src={deleteICON} alt="deleteIcon"/>
                </DeleteICON>
            }
            {
                isChanged && <StyledButton onClick={() => {
                    setIsChanged(false)
                    onSaveHandler(fieldName, text)
                }
                } height={'46px'} width={'100px'}>
                    Save
                </StyledButton>
            }
        </FieldSaveContainer>

    )
};

const FieldSaveContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
`

const TextFieldContainerHeader = styled.div`
  width: 900px;
`

const DelIcon = styled.img`
  padding: 5px;
  cursor: pointer;

  &:hover {
    border-radius: 30px;
    background-color: rgba(0.2, 0.2, 0.2, 0.1);
    transition: background 0.3s;
  }

  &:active {
    width: 34px;
    height: 34px;
  }
`

const DeleteICON = styled.div`
  display: flex;
  align-items: center;
`

export default Index;