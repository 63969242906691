import api from '../index';
import { Users } from './usersTypes';

export const usersAPI = {
    async getUsers() {
        return (await api.get<Users>('/user/all')).data;
    },
    async banUser(uid: string) {
        return (
            await api.post(`/user/ban`, {
                uid,
            })
        ).data;
    },
    async unbanUser(uid: string) {
        return (
            await api.post(`/user/unban`, {
                uid,
            })
        ).data;
    },
    async changeRole(uid: string, role: string) {
        return await api.post('/user/change-role', {
            role,
            uid,
        });
    },
};
