import React, { useEffect, useState } from 'react';
import Layout from '@components/layout';
import { MainContainer } from '@ui/mainContainer';
import styled from 'styled-components';
import MetalPriceBlock from '@components/products/MetalPriceBlock';
import ProductTable from '@components/products/ProductTable';
import Paginator from '@api/../ui/components/paginator';
import { useTypedSelector } from '@hooks/useSelector';
import useSocketDispatchUpdatePrice from '@hooks/useSocketDispatch';
import { getAllProducts, getMetalPrices } from '@store/actions/productActions';
import { useUserActions } from '@hooks/useActions';

const Products = () => {
    const { totalItems } = useTypedSelector((state) => state.productReducer);

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(200);
    const dispatch = useSocketDispatchUpdatePrice();
    let accessToken = localStorage.getItem('firebaseToken');

    const { getUser } = useUserActions();

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        dispatch({
            getMetalPrice: () => getMetalPrices(),
            geCurrentProducts: () => getAllProducts({ page, pageLimit }),
        });
    }, [page, pageLimit, accessToken]);

    return (
        <Layout>
            <MainContainer>
                <Content>
                    <Title>Products</Title>
                    <MetalPriceBlock />
                    <ProductTable page={page} pageLimit={pageLimit} />
                    {/*
                        <Paginator
                        setLimit={setPageLimit}
                        setPage={setPage}
                        max={Math.ceil(totalItems / pageLimit)}
                        page={page}
                        width={'100%'}/>
                        */}
                </Content>
            </MainContainer>
        </Layout>
    );
};

const Title = styled.div`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 136%;
    margin-bottom: 50px;
`;
const Content = styled.div`
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px;
`;

export default Products;
