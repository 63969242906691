import React, {useEffect, useState} from 'react';
import chbTrue from "@assets/svgs/checkboxTrue.svg";
import chbFalse from "@assets/svgs/checkboxFalse.svg";
import disableCheckBox from '@assets/svgs/checkbox_dissable_icon.svg'
import styled from "styled-components";

type CheckboxProps = {
    value?: boolean,
    width: string,
    height: string
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
    disabled?: boolean
}
const CheckboxHandler = (props: CheckboxProps) => {
    const [iconBox, setIconBox] = useState(props.disabled ? disableCheckBox : chbFalse)
    useEffect(() => {
        if (props.value) {
            setIconBox(chbTrue);
        } else {
            setIconBox(props.disabled ? disableCheckBox : chbFalse);
        }
    }, [props.value])

    return (

        <Wrapper width={props.width} height={props.height} onClick={(e) => {
            if (props.onClick) {
                props.onClick(e);
            }
        }}>
            <Check src={iconBox}/>
        </Wrapper>
    );
};
const Wrapper = styled.div<{ width: string, height: string }>`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};

  & > img {
    width: ${props => props.width};
    height: ${props => props.height};
    line-height: 0;
    position: absolute;
    right: 0;
  }

`
const Check = styled.img`
  cursor: pointer;
  border: 0;
`

export default CheckboxHandler;
