import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import userReducer from './reducers/userReducer';
import adminReducer from '@store/reducers/adminReducer';
import mediaReducer from '@store/reducers/mediaReducer';
import snackbarReducer from '@store/reducers/snackbarReducer';
import productReducer from '@store/reducers/productReducer';
import usersReducer from './reducers/usersReducer';
import ordersReducer from './reducers/ordersReducer';

const reducers = combineReducers({
    userReducer,
    adminReducer,
    mediaReducer,
    snackbarReducer,
    productReducer,
    usersReducer,
    ordersReducer,
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof reducers>;
