import api from "@/api";
import config from "@/config";
import { IResponseMediaImage } from "@api/media/mediaType";

export const mediaAPI = {
    async uploadImage(file: File) {
        const form = new FormData();
        form.append("file", file);
        const response = (
            await api.post<IResponseMediaImage>(`image/upload`, form, {
                baseURL: config.REACT_APP_UPLOADER_MEDIA || "",
                withCredentials: false,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        ).data;
        return response;
    },
};
