import {mediaActions} from "@store/types/mediaType";
import {MediaActionsType} from "@store/constants/mediaConstants";

interface IMediaState {
    imageOriginLink: string | undefined
    imagesOrigin: string[]
    loading: boolean
    error: string | undefined
}

const initialState: IMediaState = {
    imageOriginLink: undefined,
    loading: false,
    error: undefined,
    imagesOrigin: []
}

const startLoading = (state: IMediaState) => {
    return {...state, loading: true, error: undefined}
}

const stopErrorLoading = (state: IMediaState, error: string) => {
    return {...state, loading: false, error}
}

const mediaReducer = (state = initialState, action: mediaActions): IMediaState => {
    switch (action.type) {
        case MediaActionsType.START_UPLOAD_MEDIA:
            return startLoading(state)
        case MediaActionsType.ERROR_UPLOAD_MEDIA:
            return stopErrorLoading(state, action.payload)

        case MediaActionsType.CLEAR_MEDIA:
            return {
                imageOriginLink: undefined,
                error: undefined,
                loading: false,
                imagesOrigin: [],
            }


        case MediaActionsType.UPLOADED_IMAGE_MEDIA:
            return {
                ...state,
                imageOriginLink: action.payload.originalUrl,
                loading: false
            }

        case MediaActionsType.UPLOADED_IMAGES_MEDIA:
            return {
                ...state,
                imagesOrigin: action.payload.map(item => item.originalUrl)
            }

        case MediaActionsType.REMOVE_IMAGE_MEDIA:
            return {
                ...state,
                imagesOrigin: state.imagesOrigin.filter(image => image !== action.payload)
            }

        default: {
            return state
        }
    }
}

export default mediaReducer