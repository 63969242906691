export enum ProductsActionTypes {
    START_PRODUCT_ACTION = 'START_PRODUCT_ACTION',
    ERROR_PRODUCT_ACTION = 'ERROR_PRODUCT_ACTION',

    GET_METALS = 'GET_METALS',
    GET_METAL_PRICES = 'GET_METAL_PRICES',

    GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES',
    GET_PRODUCT_CATEGORIES_WITH_OUT_PAGINATION = 'GET_PRODUCT_CATEGORIES_WITH_OUT_PAGINATION',
    CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY',
    UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY',
    DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY',

    GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS',
    CRETE_PRODUCT = 'CRETE_PRODUCT',
    GET_PRODUCT = 'GET_PRODUCT',
    UPDATE_MAIN_PART_PRODUCT = 'UPDATE_MAIN_PART_PRODUCT',
    UPDATE_PRODUCT = 'UPDATE_PRODUCT',
    DELETE_PRODUCT = 'DELETE_PRODUCT',

    CLEAR_CURRENT_PRODUCT = 'CLEAR_CURRENT_PRODUCT',
}