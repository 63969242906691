export enum AdminActionTypes{
    START_ADMIN_ACTION = 'START_ADMIN_ACTION',
    ERROR_ADMIN_ACTION = 'ERROR_ADMIN_ACTION',

    CLEAR_STATE_ADMIN = 'CLEAR_STATE_ADMIN',

    GET_ALL_PAGES = 'GET_ALL_PAGES',
    CREATE_PAGE = 'CREATE_PAGE',
    DELETE_PAGE = 'DELETE_PAGE',
    CREATE_TEXT_FIELD = 'UPDATE_TEXT_FIELD',
    DELETE_TEXT_FIELD = 'DELETE_TEXT_FIELD',
    UPDATE_TEXT_FIELD = 'UPDATE_TEXT_FIELD',
}